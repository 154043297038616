import React from 'react'

const FuckUpModal = () => (
  <>
    <p>
      Aktualisieren Sie bitte in wenigen Sekunden Ihren Browser, oder schließen Sie das Fenster und kommen Sie in ein
      paar Minuten wieder.
    </p>
    <p>
      In dringenden Fällen, oder wenn das Problem auch dann weiterhin besteht, kontaktieren Sie uns bitte unter 030 20
      898 12 11.
    </p>
  </>
)

export default FuckUpModal
