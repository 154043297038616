const CompensationAgreementModal = () => (
  <>
    <p>
      Die Vergütungsvereinbarung wird zwischen Ihnen und der rightmart Berlin Rechtsanwaltsgesellschaft mbH, Sonnenallee
      260/262, 12057 Berlin, Rechtsanwälte Sascha Münch, Mathias Voigt, Francisca Brauns, Isabell Helms,  Helmuth Karsten
      Braun, Oliver Kessler, Mark Lutze, Johannes Rau, Andre Schey, Gina Schulz, Ivo Stefanov, Johannes Strunz, Vessela
      Nitcheva, Johannes Werisch, Stefan Zimmermann geschlossen.
    </p>
  </>
)

export default CompensationAgreementModal
