import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { utils } from '@legal-one/leadfunnel-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActiveSection,
  setInsuranceName,
  setInsuranceNumber,
  setIsInsuranceInformationPosted,
} from '../../../../../redux/user-attachment-ui/user-attachment-ui.actions'
import {
  selectInsuranceName,
  selectInsuranceNumber,
} from '../../../../../redux/user-attachment-ui/user-attachment-ui.selectors'
import {
  ACTIVE_SECTION,
  INSURANCE_CHOICES,
  INSURANCE_CHOICES_RUFF,
  INSURANCE_CHOICES_RIGHTMART,
} from '../../../../../redux/user-attachment-ui/user.attachment-ui.utils'
import { selectUiKey } from '../../../../../redux/ui/ui.selectors'
import { UI } from '../../../../../redux/ui/ui.keys'
import { postInsuranceInformation } from '../../../../../axios/requests'
import { notBlank } from '../../../../../validators'
import Select from '../../../../../components/attachment/_parts/Form/Select'
import Section from '../../../../../components/attachment/_parts/Section'
import TextInput from '../../../../../components/attachment/_parts/Form/TextInput'
import { selectAuthKey } from '../../../../../redux/auth/auth.selectors'
import { AUTH } from '../../../../../redux/auth/auth.keys'
import { getValueBasedOnBuyer } from '../../../../../utils/utils'

const SectionInsurance = (props) => {
  const { index, isActive } = props
  const select = useRef()
  const input = useRef()
  const [postingInsuranceInformation, setPostingInsuranceInformation] = useState(false)
  const insuranceName = useSelector(selectInsuranceName)
  const insuranceNumber = useSelector(selectInsuranceNumber)
  const secret = useSelector(selectUiKey(UI.SECRET))
  const buyer = useSelector(selectAuthKey(AUTH.BUYER))
  const dispatch = useDispatch()

  useEffect(() => {
    if (window.ovmEnv && window.ovmEnv !== 'production') {
      getValueBasedOnBuyer(buyer, INSURANCE_CHOICES_RUFF, INSURANCE_CHOICES_RIGHTMART, INSURANCE_CHOICES).unshift({
        value: '79580112-fc5d-11e7-ad75-eb6b08a65a12',
        label: 'Legal One Insurance',
      })
    }
  }, [])

  const handleInsuranceNameChange = (insurance) => {
    dispatch(setInsuranceName(insurance.value))
  }

  const handleInsuranceNumberChange = (insuranceNumber) => {
    dispatch(setInsuranceNumber(insuranceNumber.value))
  }

  const handleTitleClick = () => {
    dispatch(setActiveSection(ACTIVE_SECTION.INSURANCE))
  }

  const handleSectionFinish = async () => {
    setPostingInsuranceInformation(true)
    utils.pushToDataLayer({ event: 'insuranceSend' })
    const { status } = await postInsuranceInformation({
      secret,
      insuranceName,
      insuranceNumber,
    })
    setPostingInsuranceInformation(false)
    if (status === 204) {
      utils.pushToDataLayer({ event: 'insuranceSent' })
      utils.pushToDataLayer({
        event: 'GAEvent',
        eventCategory: '/attachment',
        eventAction: insuranceNumber.length > 0 ? 'Mit Nummer' : 'Ohne Nummer',
        eventLabel: 'RSV Daten',
        eventValue: '',
      })
      dispatch(setIsInsuranceInformationPosted(true))
      dispatch(setActiveSection(ACTIVE_SECTION.FILES))
    } else {
      utils.pushToDataLayer({ event: 'insuranceError' })
    }
  }

  return (
    <Section
      title="Rechtsschutzversicherung"
      index={index}
      subtitle="Haben Sie Ihre <strong>Rechtsschutzversicherungsdaten</strong> zur Hand? Teilen Sie uns diese mit, damit wir für Sie die Kostenübernahme bei Ihrer Versicherung anfragen können."
      isCollapsed={isActive === false}
      onTitleClick={handleTitleClick}
      loading={postingInsuranceInformation}
      onSectionFinish={handleSectionFinish}
      height={350}
      allowScroll={insuranceName !== ''}
    >
      <Select
        onChange={handleInsuranceNameChange}
        ref={select}
        id="insurance-company-select"
        placeholder="Wählen Sie Ihre Versicherung..."
        validator={notBlank}
        label={'Ihre Rechtsschutzversicherung'}
        options={getValueBasedOnBuyer(buyer, INSURANCE_CHOICES_RUFF, INSURANCE_CHOICES_RIGHTMART, INSURANCE_CHOICES)}
        height={175}
        waitForCallback={true}
        hideButton={notBlank(insuranceName).valid === false}
      />
      <TextInput
        onChange={handleInsuranceNumberChange}
        ref={input}
        label="Versichertennummer (falls zur Hand)"
        id="versicherungsnummer"
        placeholder="Ihre Versichertennummer"
        height={175}
      />
    </Section>
  )
}

SectionInsurance.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default SectionInsurance
