const UploadSuccessModal = () => (
  <>
    <p>
      Die Bearbeitungsdauer der übermittelten Dokumente richtet sich nach der Art des Schreibens und
      kann deshalb in einigen Fällen auch mehrere Tage in Anspruch nehmen.
      {' '}<strong>Bitte laden Sie das Dokument nicht erneut hoch.</strong> Sobald das übermittelte Dokument von uns
      bearbeitet wurde, werden wir Sie umgehend über die weitere Vorgehensweise informieren.

    </p>
    <p>
      Sollten Sie noch weitere Dokumente für uns haben, können Sie die natürlich auch noch an uns übermitteln.
    </p>
  </>
)

export default UploadSuccessModal
