import React from 'react'
import { useSelector } from 'react-redux'
import { selectForm } from '../../redux/form/form.selectors'
import { selectAuth } from '../../redux/auth/auth.selectors'
import { getPrice } from '../../utils/utils'
import { POA } from '../../constants/poa-types'

const CompensationAgreementLong = () => {
  const form = useSelector(selectForm)
  const auth = useSelector(selectAuth)

  const total = () => (auth.total ? getPrice(auth.total) : getPrice(auth.switchTotal))

  const getChangingParagraph1 = () => {
    const poaType = auth.latestSentPoaType
    switch (poaType) {
      case POA.NO_LCI_400_COURT:
      case POA.RA_400:
        return (
          'Die Vereinbarung betrifft die gerichtliche Vertretung für einen Hauptverhandlungstermin im\n' +
          '            Ordnungswidrigkeitenverfahren. Die Kanzlei erhält für die gerichtliche Vertretung in der\n' +
          '            Angelegenheit:'
        )
      case POA.ALCOHOL_1000:
      case POA.RA_1000:
        return (
          'Die Vereinbarung umfasst die Vertretung im strafrechtlichen Ermittlungsverfahren einschließlich der\n' +
          '            Vertretung im Falle der Anklageerhebung und Eröffnung des Hauptverfahrens in der 1. Instanz. Die\n' +
          '            Kanzlei erhält für die Vertretung in der Angelegenheit:'
        )
      case POA.RA_700:
        return (
          'Die Vereinbarung betrifft das Ordnungswidrigkeitsverfahren vom behördlichen Ermittlungsverfahren,\n' +
          '            des  Zwischenverfahrens  (Abgabe  an  die  Staatsanwaltschaft)  und  die  gerichtliche  Vertretung  für\n' +
          '            einen Hauptverhandlungstermin. Die Kanzlei erhält für die außergerichtliche und gerichtliche Vertretung in der Angelegenheit:'
        )
      default:
        return (
          'Die Vereinbarung betrifft nur das behördliche Ordnungswidrigkeitenverfahren einschließlich des\n' +
          '            Zwischenverfahrens vor der Staatsanwaltschaft. Die Kanzlei erhält für die außergerichtliche Vertretung in der Angelegenheit:'
        )
    }
  }

  const getChangingParagraph2 = () => {
    const poaType = auth.latestSentPoaType
    switch (poaType) {
      case POA.NO_LCI_400_COURT:
      case POA.RA_400:
      case POA.RA_700:
        return (
          'Die Vergütung gilt losgelöst vom tatsächlichen Zeitaufwand als vereinbarte Vergütung.\n' +
          '            §  628  Abs.  1  S.  1  BGB  wird  ausgeschlossen.  Diese  Gebühr  umfasst  einen  Hauptverhandlungstermin.\n' +
          '            Für jeden weiteren Termin richten sich die Gebühren sodann nach den gesetzlichen Vorschriften des\n' +
          '            Rechtsanwaltsvergütungsgesetzes (RVG).'
        )
      case POA.ALCOHOL_1000:
      case POA.RA_1000:
        return (
          'Die Vergütung gilt losgelöst vom tatsächlichen Zeitaufwand als vereinbarte Vergütung.\n' +
          '            §  628  Abs.  1  S.  1  BGB  wird  ausgeschlossen.  Diese  Gebühr  umfasst  einen  Hauptverhandlungstermin.\n' +
          '            Für jeden weiteren Hauptverhandlungstermin erhält die Kanzlei eine weitere pauschale Vergütung in\n' +
          '            Höhe von 300,00 EUR inkl. USt..'
        )
      default:
        return (
          'Die Vergütung gilt losgelöst vom tatsächlichen Zeitaufwand als vereinbarte Vergütung.\n' +
          '            § 628 Abs. 1 S. 1 BGB wird ausgeschlossen. Für die Vertretung im Falle eines gerichtlichen Verfahrens\n' +
          '            richtet sich die Vergütung sodann nach den gesetzlichen Vorschriften des\n' +
          '            Rechtsanwaltsvergütungsgesetzes (RVG) oder einer gesondert abzuschließenden\n' +
          '            Vergütungsvereinbarung.'
        )
    }
  }

  const getChangingParagraph3 = () => {
    const poaType = auth.latestSentPoaType
    switch (poaType) {
      case POA.ALCOHOL_1000:
      case POA.RA_1000:
        return (
          'Der angemessene Vorschuss für die Tätigkeit bis zum ersten Hauptverhandlungstag beträgt <strong>700,00\n' +
          '            EUR  inkl.  USt.</strong>  und  ist  sofort  mit  der  Mandatserteilung  fällig.  Der  Restbetrag  wird  nach  Abschluss\n' +
          '            des Verfahrens fällig.'
        )
      case POA.RA_700:
        return (
          'Der angemessene Vorschuss für die Tätigkeit bis zum ersten Hauptverhandlungstag beträgt <strong>400,00\n' +
          '            EUR  inkl.  USt.</strong>  und  ist  sofort  mit  der  Mandatserteilung  fällig.  Der  Restbetrag  wird  nach  Abschluss\n' +
          '            des Verfahrens fällig.'
        )
      default:
        return `Der Gesamtbetrag von <strong>${total()} EUR inkl. USt.</strong> ist sofort mit der Mandatserteilung fällig.`
    }
  }

  return (
    <React.Fragment>
      <p>Zwischen</p>
      <p>
        <strong>
          {form.firstName} {form.lastName} {form.birthName || form.birthName}
        </strong>
        <br />
        geboren am {form.birthDate}, wohnhaft in {form.street} {form.houseNumber}, {form.postCode} {form.city},
      </p>
      <cite style={{ textAlign: 'right', display: 'block' }}>
        - nachfolgend <strong>Auftraggeber</strong> genannt -
      </cite>
      <p>und</p>
      <p>
        der <strong>Mathis Ruff Rechtsanwaltsgesellschaft mbH</strong>,<br />
        Sonnenallee 260/262, 12057 Berlin,
      </p>
      <p>
        Rechtsanwälte Mathis Ruff, Francisca Brauns, Ulrike Gehrke, Vessela Nitcheva, Gina Schulz,
        Helmuth Karsten Braun, Oliver Kessler, Mark Lutze, Alexander Schaub, Andre Schey, Robert Spank, Johannes Strunz
        und Stefan Zimmermann.
      </p>
      <cite style={{ textAlign: 'right', display: 'block' }}>
        - nachfolgend <strong>Kanzlei</strong> genannt -
      </cite>
      <h4>1. Mandatsumfang und Vergütung</h4>
      <p dangerouslySetInnerHTML={{ __html: getChangingParagraph1() }} />
      <ul>
        <li>Tatvorwurf: {auth.charge}</li>
      </ul>
      <p>eine pauschale Vergütung in Höhe von:</p>
      <ul>
        <li>{total()} EUR inkl. USt.</li>
      </ul>
      <p dangerouslySetInnerHTML={{ __html: getChangingParagraph2() }} />
      <h4>2. Hinweise</h4>
      <p>
        Der Auftraggeber wird darauf hingewiesen, dass sich die gesetzlichen Gebühren nach dem
        Rechtsanwaltsvergütungsgesetz (RVG) richten. Sollte die vereinbarte Vergütung die gesetzliche Vergütung nach dem
        RVG übersteigen, richten sich im Falle des Obsiegens etwaige Erstattungen durch Dritte (z.B. der Staatskasse)
        nach der gesetzlich vorgesehenen Anwaltsvergütung und nicht nach der vereinbarten Vergütung. Die vereinbarte
        Vergütung wird daher unter Umständen von Dritten nicht vollständig übernommen.
      </p>
      <h4>3. Anrechnungsausschluss</h4>
      <p>
        Eine Anrechnung der vereinbarten Vergütung auf eventuell später entstehende Anwaltsgebühren wird ausgeschlossen.
      </p>
      <h4>4. Fälligkeit</h4>
      <p dangerouslySetInnerHTML={{ __html: getChangingParagraph3() }} />
      <h4>5. Hinweis zum Verbraucherstreitbeilegungsgesetz</h4>
      <p>
        Für vermögensrechtliche Streitigkeiten aus dem Mandatsverhältnis ist die Schlichtungsstelle der
        Rechtsanwaltschaft, Rauchstraße 26, 10787 Berlin, www.s-d-r.org, zuständig. Die Kanzlei ist nicht bereit und
        verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </React.Fragment>
  )
}

export default CompensationAgreementLong
