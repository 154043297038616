const AssignUsJob = () => (
  <div>
    <p>
      Damit wir die Deckungsanfrage bei Ihrer Rechtsschutzversicherung stellen und direkt mit dieser abrechnen können, müssen Sie uns dies bitte noch durch den Klick auf den Button bestätigen.
    </p>
    <p style={{paddingTop: 12}}>
      <span style={{fontStyle: "italic"}}>Keine Sorge! Für den unwahrscheinlichen Fall, dass Ihre Versicherung die Kostendeckung verweigert, werden wir auf Ihren Wunsch hin nicht weiter tätig und Ihnen entstehen selbstverständlich keine Kosten.</span>
    </p>
  </div>
)

export default AssignUsJob