import React from 'react'
import { Route } from 'react-router-dom'
import NotFoundPage from '../../pages/not-found/not-found.page'
import { setAuth } from '../../redux/auth/auth.actions'
import { setActiveSection, setUploadFilesOnAuth } from '../../redux/user-attachment-ui/user-attachment-ui.actions'
import { setAuthRequestEndError, setAuthRequestEndSuccess } from '../../redux/ui/ui.actions'
import { extractAuthInfoForAttachment } from '../../utils/redux'
import { getActiveSectionName } from '../../utils/utils'
import useAuthentication from '../../hooks/useAuthentication'
import { pushUserDataToOptimizely } from '../../utils/optimizely'
import { ACTIVE_SECTION } from '../../redux/user-attachment-ui/user.attachment-ui.utils'

const AttachmentAuthentication = ({ children, ...restProps }) => {
  const { isAuthInProgress, isAuthSuccess, secret, variationPersist, dispatch } = useAuthentication(async (data) => {
    dispatch(setAuth(extractAuthInfoForAttachment(data)))
    dispatch(setUploadFilesOnAuth(data.attachmentSections))
    dispatch(setActiveSection(getActiveSectionName(data.attachmentSections)))
    await variationPersist(data, secret, (success) => {
      if (success) {
        dispatch(setAuthRequestEndSuccess())
        const hasInsurance = (attachmentSections) =>
          typeof attachmentSections.find((section) => section.name === ACTIVE_SECTION.INSURANCE) !== 'undefined'
        pushUserDataToOptimizely({
          has_rsv: hasInsurance(data.attachmentSections) ? 'Ja' : 'Nein',
        })
      } else {
        dispatch(setAuthRequestEndError())
      }
    })
  })

  return <Route {...restProps} render={() => (isAuthInProgress || isAuthSuccess ? children : <NotFoundPage />)} />
}

AttachmentAuthentication.displayName = 'AttachmentAuthentication'

export default AttachmentAuthentication
