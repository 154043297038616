import React from 'react'
import { useTranslation } from 'react-i18next'
import SvgCheckLogo from '../../svg/check-logo'
import './cost-coverage-label.scss'

const CostCoverageLabel = () => {
  const { t } = useTranslation()

  return (
    <div data-anchor="cost-coverage-label" className="cost-coverage-label">
      <SvgCheckLogo className="cost-coverage-label__icon" />
      <p className="cost-coverage-label__text" dangerouslySetInnerHTML={{ __html: t('dataGather.costCoverageLabel') }} />
    </div>
  )
}

CostCoverageLabel.displayName = 'CostCoverageLabel'

export default CostCoverageLabel
