import React from 'react'
import { useSelector } from 'react-redux'
import { Header, utils } from '@legal-one/leadfunnel-ui-kit'
import { LayoutDefault } from '@legal-one/crm-vollmacht-v2-ui-kit'
import Footer from '../../components/footer/footer.component'
import Attachment from '../../components/attachment/attachment.component'
import Sidebar from '../../components/sidebar/sidebar.component'
import { UI } from '../../redux/ui/ui.keys'
import { selectUiKey } from '../../redux/ui/ui.selectors'
import { selectAuthKey } from '../../redux/auth/auth.selectors'
import { AUTH } from '../../redux/auth/auth.keys'
import { useUploadTracking } from '../../hooks/useUploadTracking'
import './attachment.scss'
import { pushEventToOptimizely, pushPageDataToOptimizely } from '../../utils/optimizely'

const AttachmentPage = () => {
  const isAuthInProgress = useSelector(selectUiKey(UI.IS_AUTH_IN_PROGRESS))
  const isInitInProgress = useSelector(selectUiKey(UI.IS_INIT_IN_PROGRESS))
  const caseSigned = useSelector(selectAuthKey(AUTH.CASE_SIGNED))
  const apiUrl = useSelector(selectAuthKey(AUTH.API_URL))

  const trackUpload = useUploadTracking()

  React.useEffect(() => {
    if (apiUrl) {
      trackUpload()
    }
  }, [apiUrl])

  React.useEffect(() => {
    if (caseSigned) {
      utils.pushToDataLayer({ event: 'caseSigned' })
    }
  }, [caseSigned])

  React.useEffect(() => {
    pushPageDataToOptimizely('url_targeting_for_sos_ovm_attachment')
  }, [])

  return (
    <LayoutDefault
      main={Attachment}
      header={Header}
      footer={Footer}
      sidebar={Sidebar}
      isLoading={Boolean(isAuthInProgress || isInitInProgress)}
    />
  )
}

AttachmentPage.displayName = 'AttachmentPage'

export default AttachmentPage
