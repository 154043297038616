export const httpsResponseInterceptor = (response) => response

export const httpsErrorInterceptor = (error) => {
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 404:
        return error.response
      default:
        return error.response
    }
  } else {
    return {
      data: error.message,
      status: 500,
    }
  }
}
