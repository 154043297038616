const CompensationAgreementModal = () => (
  <>
    <p>
      Die Vergütungsvereinbarung wird zwischen Ihnen und der Mathis Ruff Rechtsanwaltsgesellschaft mbH, Sonnenallee
      260/262, 12057 Berlin, Rechtsanwälte Mathis Ruff, Francisca Brauns, Ulrike Gehrke, Vessela
      Nitcheva, Gina Schulz, Helmuth Karsten Braun, Oliver Kessler, Mark Lutze, Alexander Schaub, Andre Schey, Robert
      Spank, Johannes Strunz und Stefan Zimmermann geschlossen.
    </p>
  </>
)

export default CompensationAgreementModal
