import {
  RESET_FORM_VALIDATION,
  SET_AUTH_REQUEST_END_ERROR,
  SET_AUTH_REQUEST_END_SUCCESS,
  SET_AUTH_REQUEST_START,
  SET_BUTTON_INSURED_CLASSNAME,
  SET_BUTTON_NOT_INSURED_CLASSNAME,
  SET_INIT_REQUEST_END_ERROR,
  SET_INIT_REQUEST_END_SUCCESS,
  SET_INIT_REQUEST_START,
  SET_NO_BOX_ON_STRAFSACHEN,
  SET_UI,
  SET_UI_KEY,
} from './ui.types'
import { UI } from './ui.keys'
import { updateModuleKeyWithExpectedKey, updateModuleWithExpectedKeys } from '../../utils/redux'

export const INITIAL_UI_STATE = {
  [UI.IS_INIT_IN_PROGRESS]: false,
  [UI.IS_INIT_SUCCESS]: true,
  [UI.IS_AUTH_IN_PROGRESS]: false,
  [UI.IS_AUTH_SUCCESS]: true,
  [UI.SECRET]: '',
  [UI.CASE_IDENTIFIER]: '',
  [UI.CURRENT_STEP]: 1,
  [UI.IS_UPDATE_STEP_FAILED]: false,
  [UI.IS_SIGN_IN_PROGRESS]: false,
  [UI.VARIATION]: '',
  [UI.VALIDATION_RESULTS]: {},
  [UI.LIST_OF_MANDATORY_CHECKBOXES]: ['powerOfAttorneyAgreement', 'agreedToLawInsuranceRequest', 'signature'],
  [UI.CHECKBOX_REFS]: {},
  [UI.ARE_FIELDS_EDITABLE]: true,
  [UI.TOUCHED_FIELDS]: [],
  [UI.FOCUSED_FIELD]: undefined,
  [UI.MAIN_SUBMIT_BUTTON_CLASSNAMES]: 'finish finish-main',
  [UI.SIDEBAR_SUBMIT_BUTTON_CLASSNAMES]: 'finish finish-side',
  [UI.CTA_BUTTON_TEXT]: 'app.button.button_submit_give_us',
  [UI.NO_BOX_ON_STRAFSACHEN]: false,
}

const initMainClassNames = INITIAL_UI_STATE[UI.MAIN_SUBMIT_BUTTON_CLASSNAMES]
const initSidebarClassNames = INITIAL_UI_STATE[UI.SIDEBAR_SUBMIT_BUTTON_CLASSNAMES]

export const UiReducer = (state = INITIAL_UI_STATE, { type, payload }) => {
  switch (type) {
    case SET_UI:
      return updateModuleWithExpectedKeys(state, INITIAL_UI_STATE, payload)
    case SET_UI_KEY:
      return updateModuleKeyWithExpectedKey(state, INITIAL_UI_STATE, payload)
    case SET_INIT_REQUEST_START:
      return {
        ...state,
        [UI.IS_INIT_IN_PROGRESS]: true,
        [UI.IS_INIT_SUCCESS]: false,
      }
    case SET_INIT_REQUEST_END_SUCCESS:
      return {
        ...state,
        [UI.IS_INIT_IN_PROGRESS]: false,
        [UI.IS_INIT_SUCCESS]: true,
      }
    case SET_INIT_REQUEST_END_ERROR:
      return {
        ...state,
        [UI.IS_INIT_IN_PROGRESS]: false,
        [UI.IS_INIT_SUCCESS]: false,
      }
    case SET_AUTH_REQUEST_START:
      return {
        ...state,
        [UI.IS_AUTH_IN_PROGRESS]: true,
        [UI.IS_AUTH_SUCCESS]: false,
      }
    case SET_AUTH_REQUEST_END_SUCCESS:
      return {
        ...state,
        [UI.IS_AUTH_IN_PROGRESS]: false,
        [UI.IS_AUTH_SUCCESS]: true,
      }
    case SET_AUTH_REQUEST_END_ERROR:
      return {
        ...state,
        [UI.IS_AUTH_IN_PROGRESS]: false,
        [UI.IS_AUTH_SUCCESS]: false,
      }
    case SET_BUTTON_INSURED_CLASSNAME:
      return {
        ...state,
        [UI.MAIN_SUBMIT_BUTTON_CLASSNAMES]: initMainClassNames + ' finish-insured',
        [UI.SIDEBAR_SUBMIT_BUTTON_CLASSNAMES]: initSidebarClassNames + ' finish-insured',
      }
    case SET_BUTTON_NOT_INSURED_CLASSNAME:
      return {
        ...state,
        [UI.MAIN_SUBMIT_BUTTON_CLASSNAMES]: initMainClassNames + ' finish-not-insured',
        [UI.SIDEBAR_SUBMIT_BUTTON_CLASSNAMES]: initSidebarClassNames + ' finish-not-insured',
      }
    case RESET_FORM_VALIDATION:
      return {
        ...state,
        [UI.VALIDATION_RESULTS]: {},
        [UI.TOUCHED_FIELDS]: [],
      }
    default:
      return state
  }
}
