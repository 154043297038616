import { createSelector } from 'reselect'
import { AUTH } from './auth.keys'

export const selectAuth = (state) => state.auth

export const selectAuthKey = (key) => createSelector([selectAuth], (auth) => auth[key])

export const selectAttachmentSectionsFiles = createSelector(
  [selectAuthKey(AUTH.ATTACHMENT_SECTIONS)],
  (attachmentSections) => attachmentSections.find((section) => section.name === 'files'),
)

export const selectAttachmentSectionsDocumentType = createSelector([selectAttachmentSectionsFiles], (sectionsFiles) => {
  if (sectionsFiles && sectionsFiles.data && sectionsFiles.data.documentType) {
    return sectionsFiles.data.documentType
  } else {
    return []
  }
})

export const selectHasInsuranceInAttachment = createSelector(
  [selectAuthKey(AUTH.ATTACHMENT_SECTIONS)],
  (attachmentSections) => attachmentSections.findIndex((section) => section.name === 'insurance') !== -1,
)
