import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSection } from '../../../../../redux/user-attachment-ui/user-attachment-ui.actions'
import { ACTIVE_SECTION } from '../../../../../redux/user-attachment-ui/user.attachment-ui.utils'
import Section from '../../../../../components/attachment/_parts/Section'
import UploadFiles from './upload-files.component'
import {
  selectFilesAreUploaded,
  selectFilesAreUploading,
} from '../../../../../redux/user-attachment-ui/user-attachment-ui.selectors'
import { selectAttachmentSectionsDocumentType } from '../../../../../redux/auth/auth.selectors'

const SectionFileUploadV2 = ({ index, isActive }) => {
  const dispatch = useDispatch()
  const filesAreUploading = useSelector(selectFilesAreUploading)
  const filesAreUploaded = useSelector(selectFilesAreUploaded)
  const attachmentSectionsDocumentType = useSelector(selectAttachmentSectionsDocumentType)

  const getSectionSubtitle = () => {
    return filesAreUploaded || attachmentSectionsDocumentType.length
      ? 'Sobald Sie neue Behördenschreiben erhalten, übermitteln Sie uns diese bitte umgehend.'
      : 'Wir können mit der Fallbearbeitung des registrierten Verstoßes erst beginnen, wenn uns <b>das letzte Schreiben, das Sie von der Behörde  erhalten</b> haben, vorliegt.'
  }

  const handleTitleClick = () => {
    dispatch(setActiveSection(ACTIVE_SECTION.FILES))
  }

  return (
    <Section
      title="Behördenschreiben"
      index={index}
      subtitle={getSectionSubtitle()}
      isCollapsed={isActive === false}
      allowScroll={isActive === true}
      onTitleClick={handleTitleClick}
      loading={filesAreUploading}
      height={500}
    >
      <UploadFiles hideButton />
    </Section>
  )
}

SectionFileUploadV2.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default SectionFileUploadV2
