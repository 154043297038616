import { isEmpty } from 'lodash'

export function debounce(fn, tm) {
  let timer
  return function () {
    const fnCall = () => fn.apply(this, arguments)
    clearTimeout(timer)
    timer = setTimeout(fnCall, tm)
  }
}

export function isNotEmptyString(str) {
  return str !== ''
}

export function isValidDate(str) {
  const [day, month, year] = str.split('/')
  if (Number(month) > 12) return false
  if (Number(day) > new Date(Number(year), Number(month), 0).getDate()) return false
  const date = new Date(Number(year), Number(month) - 1, Number(day))
  return !isNaN(Date.parse(date.toString()))
}

export function isCorrectYear(year) {
  return Number(year).toString() === year
}

export function isDateBetween(str, min, max) {
  const arr = str.split('/')
  const date = new Date(Number(arr[2]), Number(arr[1]) - 1, Number(arr[0]))
  return date >= min && date <= max && isCorrectYear(arr[2])
}

export function isDateCorrect(str) {
  const isValid = isValidDate(str)
  const isBetween = isDateBetween(str, new Date(1900, 0, 1), new Date())
  return isValid && isBetween
}

export function isObjectEmpty(obj) {
  for (const prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }
  return JSON.stringify(obj) === JSON.stringify({})
}

export function clickOrEnterKey(e, func) {
  e.preventDefault()
  if (e.type === 'click' || e.key === 'Enter') {
    func()
  }
}

/**
 * Turn integer into currency price
 * @param {number} amount
 * @param {string} currency
 * @returns {string}
 */
export function getPrice(amount = 100, currency = '€') {
  return `${amount},00 ${currency}`
}

export function convertDate(date) {
  const rawDate = date.split('/')
  if (rawDate.length === 3 && rawDate.every((r) => Number.isInteger(Number(r)))) {
    const correctOrder = [rawDate[2], rawDate[1], rawDate[0]]
    return correctOrder.join('-')
  } else {
    return parserCustomDate(date)
  }
}

export function getActiveSectionName(attachmentSections) {
  let activeSectionName = ''
  if (!isEmpty(attachmentSections)) {
    const activeSection = attachmentSections.find((section) => section.completed === false)
    if (activeSection) {
      activeSectionName = activeSection.name
    }
    if (!activeSection && attachmentSections.length === 1) {
      activeSectionName = attachmentSections[0].name
    }
    if (activeSectionName === '') {
      activeSectionName = attachmentSections[attachmentSections.length - 1].name
    }
  }
  return activeSectionName
}

/**
 * Check if passed date is in correct format
 * @param {string} date
 * @returns {false}
 */
export const isDateInExpectedFormat = (date) => {
  const dateArray = date.split('/')
  return dateArray.length === 3 && dateArray.every((d) => Number.isInteger(Number(d)))
}

/**
 * Parse custom date for display style
 * @param {string} date
 * @returns {string|*}
 */
export const parserCustomDate = (date) => {
  if (isDateInExpectedFormat(date)) {
    return date
  }
  const rawDate = date.split('T')[0].split('-')
  if (rawDate.length === 3 && rawDate.every((r) => Number.isInteger(Number(r)))) {
    const correctOrder = [rawDate[0], rawDate[1], rawDate[2]]
    return correctOrder.join('-')
  } else {
    return ''
  }
}

/**
 * Remove from passed object unexpected keys
 * @param {Object} obj
 * @param {[string]} expectedKeys
 * @returns {{[p: string]: *}}
 */
export function clearUnexpectedKeys(obj, expectedKeys = []) {
  return Object.entries(obj).reduce((obj, [key, value]) => {
    const index = expectedKeys.indexOf(key)
    return index >= 0 ? Object.assign(obj, { [key]: value }) : obj
  }, {})
}

export function trackJSErrorLog(error) {
  window.TrackJS && window.TrackJS.console.error(error)
}

export function validateAuthResponse(data) {
  const keys = [
    'allowedPath',
    'attachmentSections',
    'caseGroupLawyerNames',
    'charge',
    'firstViewUrl',
    'fullName',
    'poaData',
    'reference',
    'total',
    'uploadUuid',
  ]
  return typeof data === 'object' && keys.every((key) => data[key] !== undefined)
}

export const getValueBasedOnBuyer = (buyer, caseRuff, caseRightmart, caseDefault) => {
  switch (buyer) {
    case 'ruff':
    case 'ruff-old':
      return caseRuff
    case 'rightmart':
      return caseRightmart
    default:
      return caseDefault
  }
}
