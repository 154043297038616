import React from 'react'
import { Route } from 'react-router-dom'
import NotFoundPage from '../../pages/not-found/not-found.page'
import { setAuthRequestEndError, setAuthRequestEndSuccess } from '../../redux/ui/ui.actions'
import { setAuth } from '../../redux/auth/auth.actions'
import { setForm } from '../../redux/form/form.actions'
import { extractAuthInfoForVariation, extractUserInfo } from '../../utils/redux'
import useAuthentication from '../../hooks/useAuthentication'

const RemunerationAuthentication = ({ children, ...restProps }) => {
  const { isAuthInProgress, isAuthSuccess, secret, variationPersist, dispatch } = useAuthentication(async (data) => {
    dispatch(setAuth(extractAuthInfoForVariation(data)))
    dispatch(setForm(extractUserInfo(data)))
    await variationPersist(data, secret, (success) => {
      if (success) {
        dispatch(setAuthRequestEndSuccess())
      } else {
        dispatch(setAuthRequestEndError())
      }
    })
  })

  return <Route {...restProps} render={() => (isAuthInProgress || isAuthSuccess ? children : <NotFoundPage />)} />
}

RemunerationAuthentication.displayName = 'RemunerationAuthentication'

export default RemunerationAuthentication
