import {
  SET_INIT_REQUEST_END_ERROR,
  SET_INIT_REQUEST_END_SUCCESS,
  SET_INIT_REQUEST_START,
  SET_AUTH_REQUEST_END_ERROR,
  SET_AUTH_REQUEST_END_SUCCESS,
  SET_AUTH_REQUEST_START,
  SET_BUTTON_INSURED_CLASSNAME,
  SET_BUTTON_NOT_INSURED_CLASSNAME,
  SET_UI,
  SET_UI_KEY,
  RESET_FORM_VALIDATION,
} from './ui.types'
import { keyValueAction } from '../../utils/redux'

export const setUi = (leadInfo) => ({
  type: SET_UI,
  payload: leadInfo,
})

export const setUiKey = (key, value) => keyValueAction(SET_UI_KEY, key, value)

export const setInitRequestStart = () => ({
  type: SET_INIT_REQUEST_START,
  payload: null,
})

export const setInitRequestEndSuccess = () => ({
  type: SET_INIT_REQUEST_END_SUCCESS,
  payload: null,
})

export const setInitRequestEndError = () => ({
  type: SET_INIT_REQUEST_END_ERROR,
  payload: null,
})

export const setAuthRequestStart = () => ({
  type: SET_AUTH_REQUEST_START,
  payload: null,
})

export const setAuthRequestEndSuccess = () => ({
  type: SET_AUTH_REQUEST_END_SUCCESS,
  payload: null,
})

export const setAuthRequestEndError = () => ({
  type: SET_AUTH_REQUEST_END_ERROR,
  payload: null,
})

export const setButtonInsuredClassname = () => ({
  type: SET_BUTTON_INSURED_CLASSNAME,
  payload: null,
})

export const setButtonNotInsuredClassname = () => ({
  type: SET_BUTTON_NOT_INSURED_CLASSNAME,
  payload: null,
})

export const resetFormValidation = () => ({
  type: RESET_FORM_VALIDATION,
  payload: null,
})
