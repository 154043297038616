import {
  SET_ACTIVE_SECTION,
  SET_IS_INSURANCE_INFORMATION_POSTED,
  SET_IS_FILE_POSTED,
  SET_IS_CLIENT_SURVEY_POSTED,
  SET_INSURANCE_NAME,
  SET_INSURANCE_NUMBER,
  SET_UPLOAD_FILES_ON_AUTH,
  SET_UPLOAD_COUNT,
  ADD_ACCEPTED_FILES,
  DELETE_ACCEPTED_FILE,
  SET_FILE_UPLOAD_AFTER_POST,
  SET_WAIVER_ON_DRIVING_BAN_IMPORTANT,
  SET_CAR_OWNER,
  SET_RELATIONSHIP_OWNER_DRIVER,
  SET_ADDITIONAL_LEGAL_INSURANCE,
  SET_ADDITIONAL_INFORMATION,
  SET_FILES_ARE_UPLOADING,
  SET_FILES_ARE_UPLOADED,
} from './user-attachment-ui.types'

export const setActiveSection = (activeSection) => ({
  type: SET_ACTIVE_SECTION,
  payload: activeSection,
})

export const setIsInsuranceInformationPosted = (isInsuranceInformationPosted) => ({
  type: SET_IS_INSURANCE_INFORMATION_POSTED,
  payload: isInsuranceInformationPosted,
})

export const setIsFilePosted = (isFilePosted) => ({
  type: SET_IS_FILE_POSTED,
  payload: isFilePosted,
})

export const setIsClientSurveyPosted = (isClientSurveyPosted) => ({
  type: SET_IS_CLIENT_SURVEY_POSTED,
  payload: isClientSurveyPosted,
})

export const setInsuranceName = (insuranceName) => ({
  type: SET_INSURANCE_NAME,
  payload: insuranceName,
})

export const setInsuranceNumber = (insuranceNumber) => ({
  type: SET_INSURANCE_NUMBER,
  payload: insuranceNumber,
})

export const setUploadFilesOnAuth = (attachmentSections) => ({
  type: SET_UPLOAD_FILES_ON_AUTH,
  payload: attachmentSections,
})

export const setUploadCount = (uploadCount) => ({
  type: SET_UPLOAD_COUNT,
  payload: uploadCount,
})

export const setFilesAreUploading = (filesAreUploading) => ({
  type: SET_FILES_ARE_UPLOADING,
  payload: filesAreUploading,
})

export const setFilesAreUploaded = (filesAreUploaded) => ({
  type: SET_FILES_ARE_UPLOADED,
  payload: filesAreUploaded,
})

export const addAcceptedFiles = ({ fileUploadIndex, acceptedFiles }) => ({
  type: ADD_ACCEPTED_FILES,
  payload: {
    fileUploadIndex,
    acceptedFiles,
  },
})

export const deleteAcceptedFile = ({ fileUploadIndex, file }) => ({
  type: DELETE_ACCEPTED_FILE,
  payload: { fileUploadIndex, file },
})

export const setFileUploadAfterPost = (fileUploadIndex) => ({
  type: SET_FILE_UPLOAD_AFTER_POST,
  payload: fileUploadIndex,
})

export const setWaiverOnDrivingBanImportant = (waiverOnDrivingBanImportant) => ({
  type: SET_WAIVER_ON_DRIVING_BAN_IMPORTANT,
  payload: waiverOnDrivingBanImportant,
})

export const setCarOwner = (carOwner) => ({
  type: SET_CAR_OWNER,
  payload: carOwner,
})

export const setRelationshipOwnerDriver = (relationshipOwnerDriver) => ({
  type: SET_RELATIONSHIP_OWNER_DRIVER,
  payload: relationshipOwnerDriver,
})

export const setAdditionalLegalInsurance = (additionalLegalInsurance) => ({
  type: SET_ADDITIONAL_LEGAL_INSURANCE,
  payload: additionalLegalInsurance,
})

export const setAdditionalInformation = (additionalInformation) => ({
  type: SET_ADDITIONAL_INFORMATION,
  payload: additionalInformation,
})
