import * as React from 'react'
function SvgCheckMark({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M2 7l4.213 7 6.62-12" stroke="#2ECC71" strokeWidth={2} strokeLinecap="square" strokeLinejoin="bevel" />
    </svg>
  )
}
export default SvgCheckMark
