import React from 'react'
import PropTypes from 'prop-types'
import TinyCollapse from 'react-tiny-collapse'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAuthKey } from '../../redux/auth/auth.selectors'
import { AUTH } from '../../redux/auth/auth.keys'
import { UI } from '../../redux/ui/ui.keys'
import { selectUiKey } from '../../redux/ui/ui.selectors'
import {
  InfoBoxContent,
  InfoBoxContentItem,
  InfoBoxItemContent,
  InfoBoxItemTitle,
  InfoBoxWrapper,
} from './info-box.styles'
import { getPathFromLocation } from '../../utils/url'
import { ALLOWED_PATH } from '../../constants/allowed-path'
import InfoBoxUserSummary from '../info-box-user-summary/info-box-user-summary.component'

const InfoBox = (props) => {
  const location = useLocation()

  const charge = useSelector(selectAuthKey(AUTH.CHARGE))
  const reference = useSelector(selectAuthKey(AUTH.REFERENCE))
  const currentStep = useSelector(selectUiKey(UI.CURRENT_STEP))

  const { t } = useTranslation()

  return (
    <InfoBoxWrapper data-anchor="info-box">
      <InfoBoxContent>
        <InfoBoxContentItem>
          <InfoBoxItemTitle>{t('app.info-box.charge')}:</InfoBoxItemTitle>
          <InfoBoxItemContent>{charge}</InfoBoxItemContent>
        </InfoBoxContentItem>
        <InfoBoxContentItem>
          <InfoBoxItemTitle>{t('app.info-box.mandat')}:</InfoBoxItemTitle>
          <InfoBoxItemContent>{reference}</InfoBoxItemContent>
        </InfoBoxContentItem>
      </InfoBoxContent>
      {getPathFromLocation(location) !== ALLOWED_PATH.ATTACHMENT && (
        <TinyCollapse isOpen={currentStep === 3 && props.isOnSidebar}>
          <InfoBoxUserSummary />
        </TinyCollapse>
      )}
    </InfoBoxWrapper>
  )
}

InfoBox.displayName = 'InfoBox'

InfoBox.propTypes = {
  isOnSidebar: PropTypes.bool,
}

export default InfoBox
