import { SET_FORM, SET_FORM_KEY } from './form.types'
import { FORM } from './form.keys'
import { updateModuleKeyWithExpectedKey, updateModuleWithExpectedKeys } from '../../utils/redux'
import { AUTH } from '../auth/auth.keys'

export const INITIAL_FORM_STATE = {
  [FORM.REMUNERATION_AGREEMENT]: false,
  [FORM.IS_SWITCHED]: false,
  [FORM.IS_BIRTHDATE_EMPTY]: false,
  // From backend-end request
  [FORM.SALUTATION]: '',
  [FORM.FIRST_NAME]: '',
  [FORM.LAST_NAME]: '',
  [FORM.BIRTH_NAME]: '',
  [FORM.BIRTH_DATE]: '',
  [FORM.BIRTH_CITY]: '',
  [FORM.STREET]: '',
  [FORM.HOUSE_NUMBER]: '',
  [FORM.POST_CODE]: '',
  [FORM.CITY]: '',
  [FORM.SIGNATURE]: '',
  [FORM.POWER_OF_ATTORNEY_AGREEMENT]: false,
  [FORM.AGREED_TO_LAW_INSURANCE_REQUEST]: false,
  [FORM.DROP_REVOCATION_AGREEMENT]: false,
}

export const FormReducer = (state = INITIAL_FORM_STATE, { type, payload }) => {
  switch (type) {
    case SET_FORM:
      return updateModuleWithExpectedKeys(state, INITIAL_FORM_STATE, payload)
    case SET_FORM_KEY:
      return updateModuleKeyWithExpectedKey(state, INITIAL_FORM_STATE, payload)
    default:
      return state
  }
}
