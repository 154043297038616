export const UI = {
  BASE_URL: 'baseUrl',
  IS_INIT_IN_PROGRESS: 'isInitInProgress',
  IS_INIT_SUCCESS: 'isInitSuccess',
  IS_AUTH_IN_PROGRESS: 'isAuthInProgress',
  IS_AUTH_SUCCESS: 'isAuthSuccess',
  SECRET: 'secret',
  CASE_IDENTIFIER: 'caseIdentifier',
  CURRENT_STEP: 'currentStep',
  IS_UPDATE_STEP_FAILED: 'isUpdateStepFailed',
  IS_SIGN_IN_PROGRESS: 'isSignInProgress',
  VARIATION: 'variation',
  VALIDATION_RESULTS: 'validationResults',
  LIST_OF_MANDATORY_CHECKBOXES: 'listOfMandatoryCheckboxes',
  CHECKBOX_REFS: 'checkboxRefs',
  ARE_FIELDS_EDITABLE: 'areFieldsEditable',
  TOUCHED_FIELDS: 'touchedFields',
  FOCUSED_FIELD: 'focusedField',
  MAIN_SUBMIT_BUTTON_CLASSNAMES: 'mainSubmitButtonClassNames',
  SIDEBAR_SUBMIT_BUTTON_CLASSNAMES: 'sidebarSubmitButtonClassnames',
  CTA_BUTTON_TEXT: 'ctaButtonText',
  NO_BOX_ON_STRAFSACHEN: 'noBoxOnStrafsachen',
}
