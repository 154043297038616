import React, { useEffect } from 'react'
import { Header } from '@legal-one/leadfunnel-ui-kit'
import { LayoutDefault } from '@legal-one/crm-vollmacht-v2-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../components/footer/footer.component'
import Main from '../../components/main/main.component'
import Sidebar from '../../components/sidebar/sidebar.component'
import { UI } from '../../redux/ui/ui.keys'
import { selectUiKey } from '../../redux/ui/ui.selectors'
import { setUiKey } from '../../redux/ui/ui.actions'

const VariationBPage = () => {
  const isAuthInProgress = useSelector(selectUiKey(UI.IS_AUTH_IN_PROGRESS))
  const isInitInProgress = useSelector(selectUiKey(UI.IS_INIT_IN_PROGRESS))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUiKey(UI.VARIATION, 'b'))
  }, [])

  return (
    <LayoutDefault
      main={Main}
      header={Header}
      footer={Footer}
      sidebar={Sidebar}
      isLoading={Boolean(isAuthInProgress || isInitInProgress)}
    />
  )
}

VariationBPage.displayName = 'VariationBPage'

export default VariationBPage
