import React from 'react'
import { Button } from '@legal-one/leadfunnel-ui-kit'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUiKey } from '../../redux/ui/ui.selectors'
import { UI } from '../../redux/ui/ui.keys'
import SidebarStepTitle from '../sidebar-step-title/sidebar-step-title.component'
import SidebarTimetable from '../sidebar-timetable/sidebar-timetable.component'
import InfoBox from '../info-box/info-box.component'
import { getPathFromLocation } from '../../utils/url'
import { ALLOWED_PATH } from '../../constants/allowed-path'
import useGoToAttachment from '../../hooks/useGoToAttachment'
import useSidebar from '../../hooks/useSidebar'
import { SidebarButtonWrapper, SidebarSpace, SidebarTitle, SidebarWrapper } from './sidebar.styles'
import SecurityLogos from '../security-logos/security-logos.component'

const Sidebar = () => {
  const location = useLocation()

  const { t } = useTranslation()

  const ctaButtonClassNames = useSelector(selectUiKey(UI.SIDEBAR_SUBMIT_BUTTON_CLASSNAMES))
  const currentStep = useSelector(selectUiKey(UI.CURRENT_STEP))
  const isSignInProgress = useSelector(selectUiKey(UI.IS_SIGN_IN_PROGRESS))
  const checkboxRefs = useSelector(selectUiKey(UI.CHECKBOX_REFS))
  const validationResult = useSelector(selectUiKey(UI.VALIDATION_RESULTS))
  const ctaButtonText = useSelector(selectUiKey(UI.CTA_BUTTON_TEXT))

  const goToAttachment = useGoToAttachment(() => {
    const { powerOfAttorneyAgreementRef, agreedToLawInsuranceRequestRef, remunerationAgreementRef } = checkboxRefs
    if (!validationResult.powerOfAttorneyAgreement && powerOfAttorneyAgreementRef.current) {
      return powerOfAttorneyAgreementRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    if (!validationResult.agreedToLawInsuranceRequest && agreedToLawInsuranceRequestRef.current) {
      return agreedToLawInsuranceRequestRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    if (!validationResult.remunerationAgreement && remunerationAgreementRef.current) {
      return remunerationAgreementRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  })

  const steps = useSidebar(getPathFromLocation(location) === ALLOWED_PATH.ATTACHMENT)

  return (
    <SidebarWrapper data-anchor="sidebar">
      <SidebarSpace offsetX offsetY>
        <SidebarTitle>{t('app.sidebar-user-info.title')}</SidebarTitle>
      </SidebarSpace>
      <SidebarSpace offsetX offsetY>
        {steps.map(({ name, checked }) => (
          <SidebarStepTitle key={name} title={name} isPassed={checked} />
        ))}
      </SidebarSpace>
      <SidebarSpace offsetY>
        <InfoBox isOnSidebar={true} />
      </SidebarSpace>
      {getPathFromLocation(location) !== ALLOWED_PATH.ATTACHMENT && currentStep === 3 && (
        <SidebarSpace offsetX offsetY>
          <SidebarButtonWrapper>
            <Button
              type="button"
              label={t(ctaButtonText)}
              fullWidth
              color="orange"
              size="lg"
              className={ctaButtonClassNames}
              spreadClassNames={ctaButtonClassNames}
              loading={isSignInProgress}
              onClick={goToAttachment}
              onKeyPress={goToAttachment}
            />
          </SidebarButtonWrapper>
        </SidebarSpace>
      )}
      {getPathFromLocation(location) === ALLOWED_PATH.ATTACHMENT && (
        <SidebarSpace offsetX offsetY>
          <SidebarTimetable />
        </SidebarSpace>
      )}
      <SidebarSpace offsetX offsetY>
        <SecurityLogos />
      </SidebarSpace>
    </SidebarWrapper>
  )
}

Sidebar.displayName = 'Sidebar'

export default Sidebar
