export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION'
export const SET_IS_INSURANCE_INFORMATION_POSTED = 'SET_IS_INSURANCE_INFORMATION_POSTED'
export const SET_IS_FILE_POSTED = 'SET_IS_FILE_POSTED'
export const SET_IS_CLIENT_SURVEY_POSTED = 'SET_IS_CLIENT_SURVEY_POSTED'
export const SET_INSURANCE_NAME = 'SET_INSURANCE_NAME'
export const SET_INSURANCE_NUMBER = 'SET_INSURANCE_NUMBER'
export const SET_UPLOAD_FILES_ON_AUTH = 'SET_UPLOAD_FILES_ON_AUTH'
export const SET_UPLOAD_COUNT = 'SET_UPLOAD_COUNT'
export const ADD_ACCEPTED_FILES = 'ADD_ACCEPTED_FILES'
export const DELETE_ACCEPTED_FILE = 'DELETE_ACCEPTED_FILE'
export const SET_FILE_UPLOAD_AFTER_POST = 'SET_FILE_UPLOAD_AFTER_POST'
export const SET_WAIVER_ON_DRIVING_BAN_IMPORTANT = 'SET_WAIVER_ON_DRIVING_BAN_IMPORTANT'
export const SET_CAR_OWNER = 'SET_CAR_OWNER'
export const SET_RELATIONSHIP_OWNER_DRIVER = 'SET_RELATIONSHIP_OWNER_DRIVER'
export const SET_ADDITIONAL_LEGAL_INSURANCE = 'SET_ADDITIONAL_LEGAL_INSURANCE'
export const SET_ADDITIONAL_INFORMATION = 'SET_ADDITIONAL_INFORMATION'
export const SET_FILES_ARE_UPLOADING = 'SET_FILES_ARE_UPLOADING'
export const SET_FILES_ARE_UPLOADED = 'SET_FILES_ARE_UPLOADED'
