import React, { useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { default as ReactDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { Button, utils } from '@legal-one/leadfunnel-ui-kit'
import RadioInput from '../../Form/RadioInput'
import FileUpload from '../../../../../svg/file-upload'
import { FileCheckDuotone, TrashDuotone } from '../../../../../svg'
import InfoIcon from '../../../../../svg/info-icon'
import Select from '../../Form/Select'
import useModal from '../../../../../hooks/useModal'
import { closeAndResetModal } from '../../../../../redux/modal/modal.actions'
import { selectAttachmentSectionsDocumentType, selectAuthKey } from '../../../../../redux/auth/auth.selectors'
import { addUploadedFileToAttachmentSection, setAuthKey } from '../../../../../redux/auth/auth.actions'
import './upload-files.scss'
import {
  setActiveSection,
  setFilesAreUploading,
  setFilesAreUploaded,
} from '../../../../../redux/user-attachment-ui/user-attachment-ui.actions'
import { ACTIVE_SECTION } from '../../../../../redux/user-attachment-ui/user.attachment-ui.utils'
import { selectFilesAreUploaded } from '../../../../../redux/user-attachment-ui/user-attachment-ui.selectors'
import SvgPlus from '../../../../../svg/plus'
import SvgCheckMark from '../../../../../svg/check-mark'
import { pushEventToOptimizely } from '../../../../../utils/optimizely'
import { selectUiKey } from '../../../../../redux/ui/ui.selectors'
import { UI } from '../../../../../redux/ui/ui.keys'
import { AUTH } from '../../../../../redux/auth/auth.keys'
import { postFiles } from '../../../../../axios/requests'
import { useHistory } from 'react-router-dom'

const maxSize = 6000000

const TOPICS = [
  {
    value: 'penaltyNoteLetter',
    label: 'Bußgeldbescheid',
  },
  {
    value: 'lawHearingLetter',
    label: 'Anhörungsbogen',
  },
  {
    value: 'witnessQuestionnaire',
    label: 'Zeugenfragebogen',
  },
  {
    value: 'unknown',
    label: 'Sonstiges / Unsicher',
  },
]

const UploadFiles = () => {
  const dispatch = useDispatch()
  const { openFileSize } = useModal()
  const filesAreUploaded = useSelector(selectFilesAreUploaded)
  const secret = useSelector(selectUiKey(UI.SECRET))
  const uploadUuid = useSelector(selectAuthKey(AUTH.UPLOAD_UUID))
  const attachmentSectionsDocumentType = useSelector(selectAttachmentSectionsDocumentType)
  const { openFormTypeExplanationModal, openFileUpload, openFileUploadSuccess } = useModal()
  const dropzoneStartRef = useRef()
  const dropzoneEndRef = useRef()
  const [hasEmail, setHasEmail] = useState()
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [documentType, setDocumentType] = useState([])
  const [topic, setTopic] = useState()
  const history = useHistory()

  const getFileSize = (fileSize) => {
    const shouldUseMbVsKb = !!Math.round(fileSize / 1000000)
    if (shouldUseMbVsKb) {
      return `${Math.round(fileSize / 1000000)}MB`
    } else {
      return `${Math.round(fileSize / 1000)}KB`
    }
  }

  const postUploadedFiles = async () => {
    const formData = new FormData()
    uploadedFiles.forEach((uploadedFile, index) => {
      formData.append(`files[${index}]`, uploadedFile, uploadedFile.name)
    })
    formData.append('secret', String(secret))
    formData.append('uploadUuid', String(uploadUuid))
    formData.append('document_type', topic)
    formData.append('optimizely', Cookies.get('optimizely_data'))
    formData.append('optimizely_visitor_id', Cookies.get('optimizely_visitor_id'))
    let source = ''
    if (history.location.search.indexOf('source') > -1 && history.location.search.toLowerCase().indexOf('sms') > -1) {
      source = 'sms'
    }
    formData.append('source', source)
    utils.pushToDataLayer({ event: 'filesSend' })
    if (typeof formData.get('uploadUuid') === 'undefined') {
      window.TrackJS &&
        window.TrackJS.console.error(
          `UploadUuid is undefined when trying to post files for customer with secret ${secret}`,
        )
    }
    try {
      const { status, data } = await postFiles(formData)
      dispatch(setAuthKey(AUTH.UPLOAD_UUID, data.uploadUuid))
      return status === 200
    } catch (e) {
      dispatch(setFilesAreUploading(false))
      dispatch(setFilesAreUploaded(false))
      throw new Error(e.message)
    }
  }

  const onRadioChange = ({ value }) => {
    pushEventToOptimizely(value ? 'initiateUploadAction' : 'noLetterReceived')
    setHasEmail(value)
  }

  const onDrop = (acceptedFiles) => {
    const files = acceptedFiles.map(
      (acceptedFile, index) =>
        new File([acceptedFile], `${uploadedFiles.length + index + 1}-${acceptedFile.name}`, {
          type: acceptedFile.type,
        }),
    )
    setUploadedFiles((prevState) => [...prevState, ...files])
    if (dropzoneEndRef.current) {
      dropzoneEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const onDelete = (e, fileName) => {
    e.stopPropagation()
    setUploadedFiles((prevState) => prevState.filter((uploadedFile) => uploadedFile.name !== fileName))
  }

  const onTopicChange = ({ value }) => {
    pushEventToOptimizely('documentSelected')
    setTopic(value)
  }

  const onAddMoreClick = () => {
    setHasEmail(true)
  }

  const onUploadFiles = async (e) => {
    e.stopPropagation()
    pushEventToOptimizely('triggerUpload')
    openFileUpload(e, [
      {
        type: 'default',
        label: 'Nein',
        callback: () => {
          dispatch(closeAndResetModal())
        },
      },
      {
        type: 'success',
        label: 'Ja, alles vollständig',
        callback: () => {
          pushEventToOptimizely('confirmUpload')
          dispatch(
            closeAndResetModal(async () => {
              dispatch(setFilesAreUploading(true))
              // const isPostedSuccessfully = await mockSendFiles()
              const isPostedSuccessfully = await postUploadedFiles()
              dispatch(setFilesAreUploading(false))
              if (isPostedSuccessfully) {
                openFileUploadSuccess(e, [
                  {
                    type: 'success',
                    label: 'Okay',
                    callback: () => {
                      pushEventToOptimizely('uploadSuccess')
                      dispatch(setActiveSection(ACTIVE_SECTION.SURVEY))
                      dispatch(setFilesAreUploaded(true))
                      dispatch(closeAndResetModal())
                      dispatch(addUploadedFileToAttachmentSection(topic))
                    },
                  },
                ])
              }
            }),
          )
        },
        id: 'upload-send-finalize',
      },
    ])
  }

  useEffect(() => {
    if (hasEmail && dropzoneStartRef.current) {
      dropzoneStartRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    if (hasEmail === false) {
      dispatch(setActiveSection(ACTIVE_SECTION.SURVEY))
    }
  }, [hasEmail])

  useEffect(() => {
    setDocumentType(
      attachmentSectionsDocumentType
        .map((d) => {
          switch (d) {
            case 'penaltyNoteLetter':
              return 'Bußgeldbescheid'
            case 'lawHearingLetter':
              return 'Anhörungsbogen'
            case 'witnessQuestionnaire':
              return 'Zeugenfragebogen'
            default:
              return 'Behördenschreiben'
          }
        })
        .map((d, index, arr) => {
          const arrayOfIndexes = arr.reduce((result, item, index) => {
            if (item === d) result.push(index)
            return result
          }, [])
          const dPosition = arrayOfIndexes.indexOf(index)
          return dPosition > 0 ? `${d} #${dPosition + 1}` : d
        }),
    )
  }, [attachmentSectionsDocumentType])

  return (
    <div className="upload-files">
      {!filesAreUploaded && documentType.length === 0 ? (
        <RadioInput
          label="Haben Sie bereits Post von der Behörde erhalten?"
          name="hasEmail"
          onChange={onRadioChange}
          options={[
            { label: 'Ja', value: true },
            { label: 'Nein', value: false },
          ]}
          waitForCallback={false}
        />
      ) : (
        <div className="upload-files__add-more-wrapper">
          <button className="upload-files__add-more" type="button" onClick={onAddMoreClick}>
            <SvgPlus />
            Weiteres Behördenschreiben übermitteln
          </button>
        </div>
      )}
      <div ref={dropzoneStartRef} />
      {hasEmail === true && (
        <ReactDropzone
          noClic={false}
          noKeyboard={true}
          multiple={true}
          maxSize={maxSize}
          accept="image/jpeg, image/png, image/jpeg, image/jpeg, image/gif, application/pdf, image/png"
          onDrop={onDrop}
          onDropRejected={openFileSize}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="upload-files__section" {...getRootProps()}>
              <input {...getInputProps()} />
              {documentType.length === 0 ? (
                <div className="upload-files__info">
                  Perfekt! Übermitteln Sie jetzt bitte <u>das letzte Schreiben</u> das Sie bereits erhalten haben.
                </div>
              ) : null}
              <div className="upload-files__dropzone">
                <div className="upload-files__title">
                  <strong>Zuletzt erhaltenes Behördenschreiben</strong>
                  <br /> (alle Vorder- und Rückseiten sofern bedruckt)
                </div>
                <div className="upload-files__list">
                  {uploadedFiles.map((uploadedFile) => (
                    <div key={uploadedFile.name} className="upload-files__list-item">
                      <FileCheckDuotone className="upload-files__check-icon" />
                      <div className="upload-files__list-item-text">
                        {uploadedFile.name} - {getFileSize(uploadedFile.size)}
                      </div>
                      <button
                        type="button"
                        className="upload-files__delete-icon"
                        onClick={(e) => onDelete(e, uploadedFile.name)}
                      >
                        <TrashDuotone />
                      </button>
                    </div>
                  ))}
                </div>
                <FileUpload className="upload-files__icon" />
                <button className="upload-files__invite">
                  Datei(en) auswählen /<br />
                  Dokumentenseite abfotografieren
                </button>
                <div className="upload-files__size">
                  Maximale Dateigröße pro Datei: 6 MB. Unterstützte Dateitypen: PDF, JPG, GIF, PNG.
                </div>
                {uploadedFiles.length > 0 && (
                  <>
                    <div className="upload-files__topic">
                      <div className="upload-files__topic-title">
                        <p>Um welches Behördenschreiben handelt es sich?</p>
                        <button onClick={openFormTypeExplanationModal}>
                          <InfoIcon />
                        </button>
                      </div>
                      <div className="upload-files__select">
                        <Select
                          stopPropagation
                          onChange={onTopicChange}
                          id="insurance-company-select"
                          placeholder="Dokumententyp auswählen ..."
                          hasSearch={false}
                          options={TOPICS}
                          height={175}
                          waitForCallback={true}
                          hideButton={false}
                        />
                      </div>
                    </div>
                    <Button
                      color="orange"
                      size="sm"
                      label="Behördenschreiben übermitteln"
                      className="upload-files__button"
                      fullWidth
                      onClick={onUploadFiles}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </ReactDropzone>
      )}
      {filesAreUploaded || documentType.length !== 0 ? (
        <div className="upload-files__added-files">
          <p className="upload-files__added-files-title">Bereits übermittelt</p>
          <ul>
            {documentType.map((doc, index) => (
              <li key={doc + index} className="upload-files__added-files-item">
                <span>{doc}</span>
                <SvgCheckMark />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <div ref={dropzoneEndRef} />
    </div>
  )
}

export default UploadFiles
