import { applyMiddleware, createStore } from 'redux'
// import logger from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from './rootReducer'

const middlewares = [thunk]

// if (process.env.NODE_ENV === 'development') {
//  middlewares.push(logger)
// }

export default createStore(rootReducer, applyMiddleware(...middlewares))
