import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAuthKey } from '../redux/auth/auth.selectors'
import {
  selectIsClientSurveyPosted,
  selectIsFilePosted,
  selectIsInsuranceInformationPosted,
} from '../redux/user-attachment-ui/user-attachment-ui.selectors'
import { ALLOWED_PATH } from '../constants/allowed-path'
import { ACTIVE_SECTION } from '../redux/user-attachment-ui/user.attachment-ui.utils'
import useRemuneration from './useRemuneration'
import { AUTH } from '../redux/auth/auth.keys'
import { UI } from '../redux/ui/ui.keys'
import {
  selectIsAllowedToGoFromFirstStep,
  selectIsAllowedToGoFromSecondStep,
  selectIsAllowedToGoFromThirdStep,
  selectUiKey,
} from '../redux/ui/ui.selectors'

const useSidebar = (isAttachment) => {
  const allowedPath = useSelector(selectAuthKey(AUTH.ALLOWED_PATH))
  const areFieldsEditable = useSelector(selectUiKey(UI.ARE_FIELDS_EDITABLE))
  const isAllowedToGoFromFirstStep = useSelector(selectIsAllowedToGoFromFirstStep)
  const isAllowedToGoFromSecondStep = useSelector(selectIsAllowedToGoFromSecondStep)
  const isAllowedToGoFromThirdStep = useSelector(selectIsAllowedToGoFromThirdStep)
  const attachmentSections = useSelector(selectAuthKey(AUTH.ATTACHMENT_SECTIONS)) || []
  const isInsuranceInformationPosted = useSelector(selectIsInsuranceInformationPosted)
  const isFilePosted = useSelector(selectIsFilePosted)
  const isClientSurveyPosted = useSelector(selectIsClientSurveyPosted)

  const { t } = useTranslation()

  const { isRemunerationPage, remunerationTitle } = useRemuneration()

  const stepsForAttachmentPage = () =>
    attachmentSections.map((section) => {
      let step
      switch (section.name) {
        case ACTIVE_SECTION.INSURANCE:
          step = {
            name: `${section.index}. ${t('app.title.section_one')}`,
            checked: section.completed === true || isInsuranceInformationPosted === true,
          }
          break
        case ACTIVE_SECTION.FILES:
          step = {
            name: `${section.index}. ${t('app.title.section_two')}`,
            checked: section.completed === true || isFilePosted === true,
          }
          break
        case ACTIVE_SECTION.SURVEY:
          step = {
            name: `${section.index}. ${t('app.title.section_three')}`,
            checked: section.completed === true || isClientSurveyPosted === true,
          }
          break
        default:
          step = {
            name: 'Default',
            checked: false,
          }
      }
      return step
    })

  const stepsForNonAttachmentPage = () => {
    const steps = [
      {
        name: isRemunerationPage ? remunerationTitle : `3. ${t('app.title.step_three')}`,
        checked: isAllowedToGoFromThirdStep || false,
      },
    ]
    return allowedPath === ALLOWED_PATH.BASE || (isRemunerationPage && !areFieldsEditable)
      ? [
          {
            name: `1. ${t('app.title.step_one')}`,
            checked: isAllowedToGoFromFirstStep || false,
          },
          {
            name: `2. ${t('app.title.step_two')}`,
            checked: isAllowedToGoFromSecondStep || false,
          },
          ...steps,
        ]
      : steps
  }

  const steps = (isAttachment) => {
    return isAttachment ? stepsForAttachmentPage() : stepsForNonAttachmentPage()
  }

  return steps(isAttachment)
}

export default useSidebar
