import { CLOSE_MODAL, RESET_MODAL, SHOW_MODAL } from './modal.types'

export const openModal = (
  key,
  title,
  message,
  modalType = 'default',
  actions = [],
  id = null,
  showCookieConsent = null,
) => {
  const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1)

  const checkWindowKey = (key) => {
    const windowKeyName = 'modalUpdate' + capitalizeFirstLetter(key)
    return window[windowKeyName]
  }

  if (checkWindowKey(key)) {
    return {
      type: SHOW_MODAL,
      payload: {
        actions,
        title: checkWindowKey(key).title || title,
        titleTypeHtml: Boolean(checkWindowKey(key).title),
        message: checkWindowKey(key).message || message,
        messageTypeHtml: Boolean(checkWindowKey(key).message),
        modalType,
        id,
        showCookieConsent,
      },
    }
  } else {
    return {
      type: SHOW_MODAL,
      payload: {
        actions,
        title,
        titleTypeHtml: false,
        message,
        messageTypeHtml: false,
        modalType,
        id,
        showCookieConsent,
      },
    }
  }
}

export const closeModal = () => ({
  type: CLOSE_MODAL,
  payload: null,
})

export const resetModal = () => ({
  type: RESET_MODAL,
  payload: null,
})

export const closeAndResetModal = (callback) => {
  return (dispatch) => {
    dispatch(closeModal())
    setTimeout(() => {
      dispatch(resetModal(), 1250)
      if (typeof callback === 'function') {
        callback()
      }
    })
  }
}
