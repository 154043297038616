import { SET_AUTH, SET_AUTH_KEY, ADD_UPLOADED_FILE_TO_ATTACHMENT_SECTION } from './auth.types'
import { keyValueAction } from '../../utils/redux'

export const setAuth = (leadInfo) => ({
  type: SET_AUTH,
  payload: leadInfo,
})

export const setAuthKey = (key, value) => keyValueAction(SET_AUTH_KEY, key, value)

export const addUploadedFileToAttachmentSection = (documentType) => ({
  type: ADD_UPLOADED_FILE_TO_ATTACHMENT_SECTION,
  payload: documentType,
})
