import React, { Component } from 'react'
import { scroller, Element } from 'react-scroll'
import PropTypes from 'prop-types'
import TinyCollapse from 'react-tiny-collapse'
import cx from 'classnames'
import { ChevronDownDuotone } from '../../../svg'
import Button from './Form/Button'
import Spinner from './Spinner'
import { pushEventToOptimizely } from '../../../utils/optimizely'

// TODO add Polyfill for requestAnimationFrame because of tiny-collapse

class Section extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputRefIndexes: [],
      activeIndex: -1,
      next: true,
    }

    this.content = React.createRef()
    this.wrapper = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('keypress', this.enter)

    const flatChildren = React.Children.toArray(this.props.children)
    const state = this.createRefsByChildren(flatChildren)

    state.activeIndex = state.inputRefIndexes[0].index

    React.Children.forEach(flatChildren, (child, index) => {
      if (child.props.readOnly === true) {
        state.activeIndex = index
      }
    })

    this.setState(state)
  }

  componentDidUpdate(prevProps, prevState) {
    const flatChildren = React.Children.toArray(this.props.children)
    const prevFlatChildren = React.Children.toArray(prevProps.children)

    const { activeIndex, next } = this.state

    const { allowScroll = true } = this.props

    if (flatChildren.length !== prevFlatChildren.length) {
      const state = this.createRefsByChildren(flatChildren)

      // Number Input has been removed lower the active index
      if (
        prevFlatChildren.find((child) => child.type.displayName === 'NumberInput') &&
        !flatChildren.find((child) => child.type.displayName === 'NumberInput')
      ) {
        state.activeIndex = activeIndex - 1
      }

      this.setState(state, () => {
        // if just one dropzone has been added, we assume that the add another document button was pressed, so we need to go to the new created file upload
        if (
          flatChildren.length - prevFlatChildren.length === 1 &&
          flatChildren[flatChildren.length - 1].type.displayName === 'Dropzone' &&
          flatChildren[0].type.displayName !== 'NumberInput'
        ) {
          this.nextInput()
        } else if (next === true) {
          allowScroll && this.scrollToRef(activeIndex)
        }
      })
    } else if (prevProps.isCollapsed === true && this.props.isCollapsed === false) {
      setTimeout(() => allowScroll && this.scrollToRef(activeIndex), 200)
    } else if (this.props.isCollapsed === false && prevState.activeIndex !== activeIndex) {
      allowScroll && this.scrollToRef(activeIndex)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.enter)
  }

  handleAddFileClick = (index, onClickAddFile) => {
    onClickAddFile()
  }

  enter = (target) => {
    if (target.charCode === 13 && this.props.isCollapsed === false) {
      const next = this[`ref_next_${this.state.activeIndex}`].current
      next.props.onClick()
    }
  }

  createRefsByChildren = (children) => {
    const inputRefIndexes = []

    React.Children.forEach(children, (child, index) => {
      this[`ref_${index}`] = React.createRef()
      this[`ref_wrapper_${index}`] = React.createRef()
      this[`ref_next_${index}`] = React.createRef()
      inputRefIndexes.push({ index })
    })

    return {
      inputRefIndexes,
    }
  }

  scrollToRef = (refIndex) => {
    const ref = this[`ref_wrapper_${refIndex}`].current

    if (!ref) return

    setTimeout(() => {
      scroller.scrollTo(`ref_wrapper_${refIndex}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      })
    }, 100)
  }

  nextInput = (waitForCallback = false, isRadio = false) => {
    const { inputRefIndexes, activeIndex } = this.state

    const refKey = `ref_${activeIndex}`

    const isValid = this[refKey].current.validate(waitForCallback).isValid

    if ((isValid === true || isValid === null) && (waitForCallback === false || isRadio === true)) {
      const nextIndex = activeIndex + 1

      const next = nextIndex < inputRefIndexes.length

      this.setState({
        activeIndex: next ? nextIndex : activeIndex,
        next,
      })

      const { onSectionFinish } = this.props

      if (next === false && onSectionFinish) {
        onSectionFinish()
      }
    }
  }

  handleInactiveClick = (index) => {
    const { activeIndex } = this.state

    if (this[`ref_${index}`].current === null) return

    const displayName = this[`ref_${index}`].current._reactInternalFiber // check if is defined to prevent error
      ? this[`ref_${index}`].current._reactInternalFiber.type.displayName
      : ''

    if (displayName === 'Dropzone') {
      return
    }

    const isActive = index === activeIndex

    if (!isActive && activeIndex > index - 1) {
      this.setState({
        activeIndex: index,
        next: true,
      })
    }
  }

  renderChildren = (child, index) => {
    const { activeIndex } = this.state

    const isActive = index === activeIndex && this.props.isCollapsed === false
    const wasActive = index < activeIndex

    const show =
      typeof child.props.showAfterSectionIndexSubmitted !== 'number' ||
      child.props.showAfterSectionIndexSubmitted < activeIndex

    return (
      <React.Fragment>
        {child && show && (
          <>
            <Element name={`ref_wrapper_${index}`} />
            <div
              className={cx('section-input-group', {
                'is-active': isActive,
                editable: wasActive,
                'not-active': !isActive,
                'no-spacing': child.props.noSpacing === true,
                'fileupload-border': child.type.displayName === 'Dropzone',
                'read-only': child.props.readOnly === true,
              })}
              id={`section-input-group-${index + 1}`}
              ref={this[`ref_wrapper_${index}`]}
              onClick={() => this.handleInactiveClick(index)}
              style={{
                cursor:
                  !isActive && activeIndex > index - 1 && child.type.displayName !== 'Dropzone' ? 'pointer' : null,
              }}
            >
              {
                <React.Fragment>
                  {React.cloneElement(child, {
                    ref: this[`ref_${index}`],
                    nextCallback: child.props.waitForCallback === true && isActive ? () => this.nextInput() : null,
                    isActive,
                    ...child.props,
                  })}
                  {index === activeIndex && !child.props.hideButton && !child.props.readOnly ? (
                    <div
                      className={cx(
                        { 'file-upload-footer': child.props.showFooter === true },
                        'section-submit-container',
                      )}
                    >
                      <Button
                        label={
                          child.type.displayName === 'Dropzone'
                            ? 'Dokument #' + (child.props.fileUploadIndex + 1) + ' übermitteln'
                            : 'Weiter'
                        }
                        ref={this[`ref_next_${index}`]}
                        onClick={() => {
                          if (this.props.title === 'Behördenschreiben' && child.type.displayName === 'Dropzone') {
                            pushEventToOptimizely('triggerUpload')
                          }
                          if (this.props.title === 'Behördenschreiben' && child.type.displayName !== 'Dropzone') {
                            pushEventToOptimizely('initiateUploadAction')
                          }
                          this.nextInput(child.props.waitForCallback === true, child.type.displayName === 'RadioInput')
                        }}
                        type="secondary"
                      />
                      {child.type.displayName !== 'Dropzone' && (
                        <span className="section-submit-hint">
                          oder <em>EINGABE</em> drücken
                        </span>
                      )}
                    </div>
                  ) : (
                    child.props.showFooter === true && (
                      <div className="file-upload-footer">
                        {child.type.displayName === 'Dropzone' &&
                          child.props.onClickAddFile &&
                          child.props.showAddButton === true && (
                            <div
                              onClick={() => this.handleAddFileClick(index, child.props.onClickAddFile)}
                              className="file-upload-footer-add-more"
                            >
                              <span>Weiteres Behördenschreiben hinzufügen</span>
                            </div>
                          )}
                      </div>
                    )
                  )}
                </React.Fragment>
              }
            </div>
          </>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { title, children, subtitle, index, onTitleClick, isCollapsed = false, loading = false } = this.props

    const flatChildren = React.Children.toArray(children)

    return (
      <div ref={this.wrapper}>
        <div className={cx('section-container')} id={`section-${index}`}>
          {index && (
            <div className="section-index">
              <span>{index}</span>
            </div>
          )}
          <div
            className={cx('section-title', {
              'section-title-active': isCollapsed === false,
            })}
            onClick={onTitleClick ? onTitleClick : null}
          >
            {title}
            <ChevronDownDuotone />
          </div>
          <TinyCollapse isOpen={isCollapsed === false}>
            {loading === true && <Spinner />}
            <div className="section-wrapper">
              {subtitle && <div className="section-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />}
              <div className="section-content">
                <div className="section-content-wrapper" ref={this.wrapper}>
                  {React.Children.map(flatChildren, this.renderChildren)}
                </div>
              </div>
            </div>
          </TinyCollapse>
        </div>
      </div>
    )
  }
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isCollapsed: PropTypes.bool.isRequired, // default true
  onTitleClick: PropTypes.func.isRequired,
  onSectionFinish: PropTypes.func,
  height: PropTypes.number, // as pixel for seciton-content
  subtitle: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool, // default false
  allowScroll: PropTypes.bool,
}

export default Section
