import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Inputmask from 'inputmask'

class TextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValid: null,
      value: '',
      maskValue: 'DD.MM.JJJJ',
      isFocused: false,
      errorMessage: null,
    }

    this.input = React.createRef()
  }

  componentDidMount() {
    const {
      dateInputMask = false,
    } = this.props

    if (dateInputMask === true) {
      this.setState({
        maskValue: 'DD.MM.JJJJ',
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive === false && this.props.isActive === true) {
      this.input.current && this.input.current.focus && this.input.current.focus()
    }
  }

  validate = fireCallback => {
    const {
      validator,
      onChange,
      dateInputMask = false,
    } = this.props

    const value = dateInputMask === true
      ? this.state.maskValue
      : this.state.value

    let isValid = null
    let errorMessage = null
    if (validator) {
      const result = validator(value)
      isValid = result.valid
      errorMessage = result.errorMessage
    }

    this.setState({
      isValid,
      value,
      errorMessage,
    })

    const returnObject = {
      isValid,
      value,
    }

    if (fireCallback === true && onChange) {
      onChange(returnObject)
    }

    return returnObject
  }

  onChange = event => {
    const value = event.target.value

    this.setState({
      value,
    }, () => this.validate(true))
  }

  setValue = value => {
    this.setState({
      value,
    })
  }

  focus = () => {
    this.input.current.focus()
  }

  onChangeMask = (event) => {
    const matchArray = event.target.value.match(/\d+/g)

    const value = matchArray
      ? matchArray.join('')
      : ''

    const formattedDate = Inputmask.format(value, {
      mask: '99.99.9999',
      placeholder: 'DD.MM.JJJJ',
    })

    const deleting = value.length < this.state.value.length

    this.setState({
      maskValue: formattedDate,
      value,
    }, () => {
      this.validate(true)
      let cursorPosition = value.length

      // skip first dot in placeholder
      if (value.length >= 2) {
        cursorPosition++
      }

      // skip second dot in placeholder
      if (value.length >= 4) {
        cursorPosition++
      }

      // if user uses backspace, ignore second dot and move cursor to next number
      if (deleting === true && value.length === 4) {
        cursorPosition--
      }

      // if user uses backspace, ignore firs dot and move cursor to next number
      if (deleting === true && value.length === 2) {
        cursorPosition--
      }

      // set cursor position
      this.input.current.selectionStart = this.input.current.selectionEnd = cursorPosition
    })
  }

  onFocusMask = () => {
    this.input.current.selectionStart = this.input.current.selectionEnd = 0
  }

  onFocus = () => (
    this.setState({
      isFocused: true,
    })
  )

  onBlur = () => (
    this.setState({
      isFocused: false,
    })
  )

  render() {
    const {
      id,
      label,
      dateInputMask = false,
      placeholder,
      area = false,
      inputMode,
    } = this.props

    const {
      value,
      maskValue,
      isValid,
      isFocused,
      errorMessage,
    } = this.state

    return (
      <div className={cx('text-input-container', {
        'text-area': area === true,
        'is-valid': isValid === true,
        'is-not-valid': isValid === false,
      })}>
        {errorMessage && isFocused === false &&
        <div className="text-input-error">
          {errorMessage}
        </div>}
        {label && <label htmlFor={id} className="text-input-label">{label}</label>}
        {dateInputMask && area === false &&
        <input
          id={id}
          type={'text'}
          className="text-input"
          placeholder={placeholder}
          value={maskValue}
          onChange={this.onChangeMask}
          onFocus={this.onFocusMask}
          onBlur={this.onBlur}
          ref={this.input}
          inputMode={inputMode ? inputMode : null} />
        }
        {!dateInputMask && area === false &&
        <input
          id={id}
          type={'text'}
          className="text-input"
          placeholder={placeholder}
          value={value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          ref={this.input}
          inputMode={inputMode ? inputMode : null} />
        }
        {!dateInputMask && area === true &&
        <textarea
          id={id}
          className="text-input"
          placeholder={placeholder}
          value={value}
          ref={this.input}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur} />
        }
      </div>
    )
  }
}

TextInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  area: PropTypes.bool,
  placeholder: PropTypes.string,
  dateInputMask: PropTypes.bool,
  validator: PropTypes.func,
  onChange: PropTypes.func,
  inputMode: PropTypes.string,
  isActive: PropTypes.bool,
}

export default TextInput
