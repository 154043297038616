import styled from 'styled-components'
import { MEDIA_QUERIES } from '@legal-one/leadfunnel-ui-kit'

export const InfoUserBoxWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 24px;

  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    display: none;
  }
`
