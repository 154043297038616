import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import Button from './attachment/_parts/Form/Button'
import { closeAndResetModal } from '../redux/modal/modal.actions'
import cx from 'classnames'
import { TimesCircleDuotone } from '../svg'

const Modal = ({ modal, closeAndResetModal }) => {
  const {
    isOpen,
    actions,
    title,
    message,
    type,
    id = null,
    showCookieConsent = null,
    titleTypeHtml,
    messageTypeHtml,
  } = modal

  ReactModal.setAppElement('#root')

  const dismiss = type !== 'alert' && type !== 'alert-narrow'

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={dismiss ? closeAndResetModal : null}
      shouldCloseOnEsc={dismiss}
      closeTimeoutMS={200}
      id={id}
      portalClassName={'modal-portal'}
      overlayClassName={{
        base: 'modal-overlay',
        afterOpen: 'modal-overlay--after-open',
        beforeClose: 'modal-overlay--before-close',
      }}
      className={{
        base: cx({
          'modal-dialog': type === 'default' || type === 'alert' || !type,
          'modal-dialog-narrow': type === 'narrow' || type === 'alert-narrow',
        }),
        afterOpen: 'modal-dialog--after-open',
        beforeClose: 'modal-dialog--before-close',
      }}
      bodyOpenClassName="modal-is-open"
    >
      {dismiss && (
        <span onClick={closeAndResetModal} className="modal-dialog-close">
          <TimesCircleDuotone />
        </span>
      )}
      <div className="modal-dialog-header">
        {titleTypeHtml ? <div dangerouslySetInnerHTML={{ __html: title }} /> : title}
      </div>
      <div className="modal-dialog-content">
        {messageTypeHtml ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message}
      </div>
      {showCookieConsent && (
        <p
          dangerouslySetInnerHTML={{
            __html: document.getElementById('cmp-container').firstElementChild.innerHTML,
          }}
        />
      )}
      {actions.length > 0 && (
        <div className="modal-dialog-footer">
          <div className="button-group no-margin button-group-right">
            {actions.map((action) => (
              <Button
                key={action.label}
                label={action.label}
                onClick={action.callback}
                type={action.type}
                id={action.id ? action.id : null}
              />
            ))}
          </div>
        </div>
      )}
    </ReactModal>
  )
}

Modal.propTypes = {
  modal: PropTypes.object.isRequired,
  closeAndResetModal: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeAndResetModal,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
