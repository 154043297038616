import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '../redux/ui/ui.keys'
import { selectUiKey } from '../redux/ui/ui.selectors'
import {
  setAuthRequestEndError,
  setAuthRequestStart,
  setInitRequestEndError,
  setInitRequestEndSuccess,
  setInitRequestStart,
  setUiKey,
} from '../redux/ui/ui.actions'
import { getAuthenticate, getInit } from '../axios/requests'
import { fixForSourceSmsWrongQueryFormat, getSecretFromLocation } from '../utils/url'
import useVariationPersist from '../hooks/useVariationPersist'
import useRedirect from '../hooks/useRedirect'
import { setAuthKey } from '../redux/auth/auth.actions'
import { AUTH } from '../redux/auth/auth.keys'
import { trackJSErrorLog, validateAuthResponse } from '../utils/utils'
import { pushPageDataToOptimizely } from '../utils/optimizely'

const useAuthentication = (fillStore) => {
  const isInitInProgress = useSelector(selectUiKey(UI.IS_INIT_IN_PROGRESS))
  const isInitSuccess = useSelector(selectUiKey(UI.IS_INIT_SUCCESS))
  const isAuthInProgress = useSelector(selectUiKey(UI.IS_AUTH_IN_PROGRESS))
  const isAuthSuccess = useSelector(selectUiKey(UI.IS_AUTH_SUCCESS))
  const secret = useSelector(selectUiKey(UI.SECRET))

  const location = useLocation()

  const history = useHistory()

  const dispatch = useDispatch()

  const redirect = useRedirect()

  const variationPersist = useVariationPersist()

  const startTestNoBoxOnStrafsachen = (data) => {
    if (data.charge === 'Strafsache' && data.poaData && data.poaData.latestSentPoaType === 'insurance') {
      pushPageDataToOptimizely('no_box_on_strafsachen')
    }
  }

  const handleOptimizelyCustomEvent = ({ detail }) => {
    if (detail['no_box_on_strafsachen']) {
      dispatch(setUiKey(UI.NO_BOX_ON_STRAFSACHEN, true))
    }
  }

  useEffect(() => {
    const init = async () => {
      dispatch(setInitRequestStart())
      if (process.env.NODE_ENV === 'development') {
        await new Promise((resolve) => {
          setTimeout(() => {
            dispatch(setAuthKey(AUTH.NODE_ENV, process.env.REACT_APP_NODE_ENV))
            dispatch(setAuthKey(AUTH.API_URL, process.env.REACT_APP_API_URL))
            dispatch(setAuthKey(AUTH.UNIFIED_API_URL, process.env.REACT_APP_UNIFIED_API_URL))
            dispatch(setInitRequestEndSuccess())
            resolve()
          }, 500)
        })
      } else {
        const { status, data } = await getInit()
        if (status === 200) {
          dispatch(setAuthKey(AUTH.NODE_ENV, data['NODE_ENV']))
          dispatch(setAuthKey(AUTH.API_URL, data['API_URL']))
          dispatch(setAuthKey(AUTH.UNIFIED_API_URL, data['UNIFIED_API_URL']))
          dispatch(setInitRequestEndSuccess())
        } else {
          dispatch(setInitRequestEndError())
        }
      }
    }

    init()

    const secret = getSecretFromLocation(location)

    // Handle case for / path without secret param. Should show not found page
    if (secret) {
      const hasErrorInSecret = fixForSourceSmsWrongQueryFormat(location, secret)
      if (hasErrorInSecret && hasErrorInSecret.path === '/attachment') {
        history.push('/attachment/' + hasErrorInSecret.secret + '?source=sms')
      }
      dispatch(setUiKey(UI.SECRET, hasErrorInSecret ? hasErrorInSecret.secret : getSecretFromLocation(location)))
    } else {
      dispatch(setAuthRequestEndError())
    }

    document.addEventListener('optimizely', handleOptimizelyCustomEvent)

    return () => {
      document.removeEventListener('optimizely', handleOptimizelyCustomEvent)
    }
  }, [])

  useEffect(() => {
    const authenticate = async (secret) => {
      if (secret) {
        dispatch(setAuthRequestStart())
        const response = await getAuthenticate(secret)

        if (response.status === 200 && validateAuthResponse(response.data)) {
          const isRedirected = redirect(response.data, secret)
          if (!isRedirected) {
            // TODO: company-change. Hardcoded for now. Uncomment once BE is ready and will provide buyer key in /api/authenticate GET
            dispatch(setAuthKey(AUTH.BUYER, response.data.buyer))
            // dispatch(setAuthKey(AUTH.BUYER, 'ruff'))
            startTestNoBoxOnStrafsachen(response.data)
            fillStore(response.data)
          }
        }
        if (response.status === 200 && !validateAuthResponse(response.data)) {
          trackJSErrorLog(`Auth request error. Response: ${JSON.stringify(response.data)}.`)
        }
        if (response.status !== 200) {
          dispatch(setAuthRequestEndError())
        }
      }
    }
    if (isInitSuccess) {
      authenticate(secret)
    }
  }, [isInitSuccess, secret, location.pathname])

  return {
    isInitInProgress,
    isInitSuccess,
    isAuthInProgress,
    isAuthSuccess,
    secret,
    location,
    dispatch,
    redirect,
    variationPersist,
  }
}

export default useAuthentication
