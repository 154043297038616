import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthKey } from '../redux/auth/auth.selectors'
import { AUTH } from '../redux/auth/auth.keys'
import { setAuthKey } from '../redux/auth/auth.actions'
import { POA } from '../constants/poa-types'
import { setFormKey } from '../redux/form/form.actions'
import { FORM } from '../redux/form/form.keys'

export const usePoaFromUrl = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const latestSentPoaType = useSelector(selectAuthKey(AUTH.LATEST_SENT_POA_TYPE))

  useEffect(() => {
    const poa = new URLSearchParams(location.search).get('poa');
    if (!!latestSentPoaType && !!poa && Object.values(POA).indexOf(poa) >= 0 && latestSentPoaType !== poa) {
      dispatch(setAuthKey(AUTH.LATEST_SENT_POA_TYPE, poa))
      dispatch(setFormKey(FORM.IS_SWITCHED, true))
    }
  }, [latestSentPoaType])
}