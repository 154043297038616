import { useHistory, useLocation } from 'react-router-dom'
import { getPathFromLocation, removeLastCharFromString, takeVariationFromUrl } from '../utils/url'
import { ALLOWED_PATH } from '../constants/allowed-path'

const useRedirect = () => {
  const history = useHistory()
  const location = useLocation()

  const currentPath = getPathFromLocation(location)

  const getSearchParams = (withSecret, secret) => {
    if (withSecret) {
      const urlSearchParam = new URLSearchParams(location.search)
      urlSearchParam.set('secret', secret)
      return '?'.concat(
        urlSearchParam
          .toString()
          .split('&')
          .sort((s) => (s.indexOf('secret') < 0 ? 1 : -1))
          .join('&'),
      )
    } else {
      const urlSearchParam = new URLSearchParams(location.search)
      urlSearchParam.delete('secret')
      return '?'.concat(urlSearchParam.toString())
    }
  }

  return (data, secret) => {
    const variationOfFirstViewUrl = takeVariationFromUrl(removeLastCharFromString(data.firstViewUrl, '/'))

    switch (data.allowedPath) {
      case ALLOWED_PATH.BASE:
        if (currentPath !== ALLOWED_PATH.BASE) {
          history.push({
            pathname: variationOfFirstViewUrl ? `/${variationOfFirstViewUrl}` : '/',
            search: getSearchParams(true, secret),
          })
          return true
        }
        return false
      case ALLOWED_PATH.ATTACHMENT:
        if (currentPath !== ALLOWED_PATH.ATTACHMENT) {
          history.push(`${data.allowedPath}/${secret}${getSearchParams(false)}`)
          return true
        }
        return false
      case ALLOWED_PATH.REMUNERATION:
        if (currentPath !== ALLOWED_PATH.REMUNERATION) {
          history.push(`${data.allowedPath}/${secret}${getSearchParams(false)}`)
          return true
        }
        return false
      default:
        throw new Error(`API error. Unexpected allowedPath "${data.allowedPath}"`)
    }
  }
}

export default useRedirect
