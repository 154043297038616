import styled from 'styled-components'
import { COLORS, MEDIA_QUERIES } from '@legal-one/leadfunnel-ui-kit'

export const RegularText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;
  color: ${COLORS.BLACK_1};
`

export const SignaturePadWrapper = styled.div`
  margin: 0 0 25px 0;
  padding: 0;
`

export const SubmitButton = styled.div`
  margin: 28px 0 0 0;
  padding: 0;
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }
`

export const UnderSubmitSwitch = styled.div`
  margin: 28px 0 24px 0;
`

export const SwitchPoaType = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`

export const SwitchPoaTypeText = styled.span`
  margin: 0;
  padding: 0;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: ${COLORS.BLUE_4};
`

export const SwitchPoaTypeLink = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: ${({ green }) => (green ? COLORS.GREEN_1 : COLORS.ORANGE_1)}};
  :hover {
    cursor: pointer;
  }
  :focus {
    outline-color: ${COLORS.ORANGE_1};
    outline-style: auto;
    outline-offset: 2px;
    border-radius: 4px;
  }
`

export const UnderSubmitButtonText = styled(RegularText)`
  margin: 24px 0 0 0;
  padding: 0;
  text-align: center;
  color: ${COLORS.GREY_4};
  [data-tag='link'] {
    color: ${COLORS.BLUE_5};
  }
`

export const LogoItemsWrapper = styled.div`
  margin: 28px 0 0 0;
  padding: 0;
`

export const PriceLineText = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;
  color: ${COLORS.BLUE_4};
`

export const PriceLine = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  ${PriceLineText}:first-of-type {
    flex-basis: 70%;
    padding-right: 4px;
    text-align: left;
  }
  ${PriceLineText}:last-of-type {
    flex-basis: 30%;
    padding-left: 4px;
    text-align: right;
  }
`

export const BlueBorderedBox = styled.div`
  padding: ${({ small }) => (small ? '10px' : '16px')};
  border: 2px solid ${COLORS.WHITE_5};
  border-radius: 4px;
  background-color: ${COLORS.WHITE_7};
`

export const CostCoverageSelectorWrapper = styled.div`
  margin: 20px 0;
  padding: 0;
`

export const NoInsuranceWrapper = styled.div`
  margin: 20px 0 28px;
  padding-top: 20px;
  border-top: 1px solid ${COLORS.WHITE_2};
`

export const CheckboxesWrapper = styled.div`
  margin: 0 0 10px 0;
  padding: 0 22px 0 0;
  > *:first-child {
    margin-top: 0;
    padding-top: 0;
  }
`

export const MainWrapper = styled.main`
  margin: 0;
  padding: 28px 40px;
  display: block;
  background-color: ${COLORS.WHITE_1};
  @media screen and (min-width: ${MEDIA_QUERIES.PHONE_1}) {
    padding: 30px 40px 24px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.PHONE_2}) {
    padding-top: 35px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    padding-top: 35px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    padding-top: 24px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
  }
`

export const GenderSelector = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  > *:first-of-type {
    margin-right: 8px;
  }
`
