import React from 'react'
import PropTypes from 'prop-types'
import { CheckIcon } from '@legal-one/leadfunnel-ui-kit'
import { SidebarStepTitleIcon, SidebarStepTitleText, SidebarStepTitleWrapper } from './sidebar-step-title.styles'
import { getIconColor } from './sidebar-step-title.utils'

const SidebarStepTitle = ({ title, isPassed }) => (
  <SidebarStepTitleWrapper data-anchor="sidebar-step-title">
    <SidebarStepTitleText>{title}</SidebarStepTitleText>
    <SidebarStepTitleIcon>
      <CheckIcon color={getIconColor(isPassed)} size="xs" />
    </SidebarStepTitleIcon>
  </SidebarStepTitleWrapper>
)

SidebarStepTitle.displayName = 'SidebarStepTitle'

SidebarStepTitle.propTypes = {
  title: PropTypes.string,
  isPassed: PropTypes.bool,
}

export default SidebarStepTitle
