import { useRef, useEffect } from 'react'

export const usePrevious = (data) => {
	const ref = useRef()

	useEffect(() => {
		ref.current = data
	}, [data])

	return ref.current
}
