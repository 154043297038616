import { isString } from 'lodash'
import { ALLOWED_PATH } from '../constants/allowed-path'

/**
 * Define variation type based on pathname
 * @param {string} pathname
 * @returns {string} variation
 */
export function defineVariation(pathname) {
  switch (pathname) {
    case '':
    case '/':
    case '/a':
      return 'a'
    case '/b':
      return 'b'
    case '/c':
      return 'c'
    default:
      return ''
  }
}

export function isUserInfoPath(pathname) {
  return defineVariation(pathname) !== ''
}

export function isUserAttachmentPath(pathname) {
  const path = pathname.split('/')
  return path[1] === 'attachment'
}

/**
 * Cut last char from string if it matches passed char
 * @param {string} string
 * @param {string} char
 * @returns {string} cut string
 */
export function removeLastCharFromString(string, char) {
  if (!isString(string)) return string
  const lastChar = string.charAt(string.length - 1)
  return lastChar === char ? string.slice(0, -1) : string
}

/**
 * Define variation type of passed url
 * @param {string} url
 * @returns {string} variation
 */
export function takeVariationFromUrl(url) {
  if (!isString(url)) return ''
  const splittedUrl = url.split('/')
  switch (splittedUrl.length) {
    case 3:
      return ''
    case 4:
      return defineVariation('/' + splittedUrl.splice(-1, 3))
    default:
      return ''
  }
}

/**
 * Get "secret" param from url search
 * @param {string} search
 * @returns {string}
 */
export function getSecretFromLocationSearch({ search }) {
  const secret = new URLSearchParams(search).get('secret')
  return secret ? secret : ''
}

export function getSecretFromLocationPathname({ pathname }) {
  return pathname.split('/')[2]
}

export function getSecretFromLocation(location) {
  const path = getPathFromLocation(location)
  switch (path) {
    case ALLOWED_PATH.BASE:
      return getSecretFromLocationSearch(location)
    case ALLOWED_PATH.ATTACHMENT:
    case ALLOWED_PATH.REMUNERATION:
      return getSecretFromLocationPathname(location)
    default:
      throw new Error(`Unexpected path "${location.pathname}"`)
  }
}

export function fixForSourceSmsWrongQueryFormat(location, secret) {
  const path = getPathFromLocation(location)
  if (secret.indexOf('&source=sms') >= 0) {
    const fixedSecret = secret.replace('&source=sms', '')
    return fixedSecret.length === 100 ? { path, secret: secret.replace('&source=sms', '') } : false
  } else {
    return false
  }
}

/**
 * Get path from react location hook
 * @param {Object} location
 * @returns {string}
 */
export function getPathFromLocation(location) {
  if (
    location.pathname === '' ||
    location.pathname === '/' ||
    location.pathname === '/a' ||
    location.pathname === '/b' ||
    location.pathname === '/c'
  ) {
    return ALLOWED_PATH.BASE
  } else if (/^\/attachment\//.test(location.pathname)) {
    return ALLOWED_PATH.ATTACHMENT
  } else if (/^\/remuneration\//.test(location.pathname)) {
    return ALLOWED_PATH.REMUNERATION
  } else {
    throw new Error(`Can not detect expected PATH from "${location.pathname}"`)
  }
}
