import { createSelector } from 'reselect'
import { UI } from './ui.keys'

export const selectUi = (state) => state.ui

export const selectUiKey = (key) => createSelector([selectUi], (ui) => ui[key])

export const selectIsAllowedToGoFromFirstStep = createSelector(
  [selectUiKey(UI.VALIDATION_RESULTS)],
  (validationResults) =>
    validationResults.firstName &&
    validationResults.lastName &&
    validationResults.birthDate &&
    validationResults.birthCity,
)

export const selectIsAllowedToGoFromSecondStep = createSelector(
  [selectUiKey(UI.VALIDATION_RESULTS)],
  (validationResults) =>
    validationResults.street && validationResults.houseNumber && validationResults.postCode && validationResults.city,
)

export const selectIsAllowedToGoFromThirdStep = createSelector(
  [selectUiKey(UI.LIST_OF_MANDATORY_CHECKBOXES), selectUiKey(UI.VALIDATION_RESULTS)],
  (listOfMandatoryCheckboxes, validationResults) =>
    listOfMandatoryCheckboxes.every((checkbox) => validationResults[checkbox]),
)
