import React, { useEffect, useState, Fragment } from 'react'
import { includes } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { InputCheckbox, SignaturePad, Button } from '@legal-one/leadfunnel-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { setFormKey } from '../../../../../redux/form/form.actions'
import { FORM } from '../../../../../redux/form/form.keys'
import { selectFormKey } from '../../../../../redux/form/form.selectors'
import { setButtonInsuredClassname, setButtonNotInsuredClassname, setUiKey } from '../../../../../redux/ui/ui.actions'
import { UI } from '../../../../../redux/ui/ui.keys'
import { selectUiKey } from '../../../../../redux/ui/ui.selectors'
import { AUTH } from '../../../../../redux/auth/auth.keys'
import { selectAuthKey } from '../../../../../redux/auth/auth.selectors'
import useModal from '../../../../../hooks/useModal'
import useUserInfoForm from '../../../../../hooks/useUserInfoForm'
import useGoToAttachment from '../../../../../hooks/useGoToAttachment'
import useSignature from '../../../../../hooks/useSignature'
import { POA } from '../../../../../constants/poa-types'
import NoInsurance from './_parts/no-insurance/no-insurance.component'
import {
  CheckboxesWrapper,
  BlueBorderedBox,
  LogoItemsWrapper,
  NoInsuranceWrapper,
  SignaturePadWrapper,
  SubmitButton,
  UnderSubmitButtonText,
  UnderSubmitSwitch,
  SwitchPoaTypeLink,
  SwitchPoaTypeText,
  SwitchPoaType,
} from '../../../../../styles/common.styles'
import SecurityLogos from '../../../../security-logos/security-logos.component'
import CostCoverageLabel from '../../../../cost-coverage-label/cost-coverage-label.component'
import { INITIAL_FORM_STATE } from '../../../../../redux/form/form.reducer'
import InfoBoxUserSummary from '../../../../info-box-user-summary/info-box-user-summary.component'
import { InfoUserBoxWrapper } from '../../step-3.styles'
import { trackAlternateCta } from '../../../../../axios/requests'
import { getValueBasedOnBuyer } from '../../../../../utils/utils'

// TODO: Need to remove all variation A conditions

const Step3v2 = () => {
  const listWithAgreed = [FORM.POWER_OF_ATTORNEY_AGREEMENT, FORM.AGREED_TO_LAW_INSURANCE_REQUEST, FORM.SIGNATURE]
  const listWithoutAgreed = [FORM.POWER_OF_ATTORNEY_AGREEMENT, FORM.SIGNATURE, FORM.REMUNERATION_AGREEMENT]

  const [isMoneySectionShown, setIsMoneySectionShown] = useState(false)

  const { userInfoForm, validationResult, isUpdateStepFailed, ctaButtonText, onChange } = useUserInfoForm()
  const { openAuthoritiesModal, openClientConditionModal, openRevocationModal, openCompensationAgreementModal } =
    useModal()

  const secret = useSelector(selectUiKey(UI.SECRET))
  const noBoxOnStrafsachen = useSelector(selectUiKey(UI.NO_BOX_ON_STRAFSACHEN))
  const trackCtaButton = () => {
    trackAlternateCta(secret, {
      modal_variation: 1,
      clicked_first_cta: 1,
    })
  }

  const goToAttachment = useGoToAttachment(() => {
    const { powerOfAttorneyAgreementRef, agreedToLawInsuranceRequestRef, remunerationAgreementRef } = checkboxRefs
    if (!validationResult.powerOfAttorneyAgreement && powerOfAttorneyAgreementRef.current) {
      return powerOfAttorneyAgreementRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    if (!validationResult.agreedToLawInsuranceRequest && agreedToLawInsuranceRequestRef.current) {
      return agreedToLawInsuranceRequestRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    if (!validationResult.remunerationAgreement && remunerationAgreementRef.current) {
      return remunerationAgreementRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  })

  const { signature, showSignatureSuccess, showSignatureError } = useSignature()

  const { t } = useTranslation()
  const variation = useSelector(selectUiKey(UI.VARIATION))
  const isSignInProgress = useSelector(selectUiKey(UI.IS_SIGN_IN_PROGRESS))
  const ctaButtonClassNames = useSelector(selectUiKey(UI.MAIN_SUBMIT_BUTTON_CLASSNAMES))
  const caseGroupLawyerNames = useSelector(selectAuthKey(AUTH.CASE_GROUP_LAWYER_NAMES))
  const listOfMandatoryCheckboxes = useSelector(selectUiKey(UI.LIST_OF_MANDATORY_CHECKBOXES))
  const checkboxRefs = useSelector(selectUiKey(UI.CHECKBOX_REFS))
  const isSwitched = useSelector(selectFormKey(FORM.IS_SWITCHED))
  const poaType = useSelector(selectAuthKey(AUTH.LATEST_SENT_POA_TYPE))
  const buyer = useSelector(selectAuthKey(AUTH.BUYER))

  const dispatch = useDispatch()

  const A = variation === 'a'
  const C = variation === 'c'

  useEffect(() => {
    if (
      oneOf(
        POA.NO_LCI,
        POA.NO_LCI_500,
        POA.NO_LCI_A,
        POA.NO_LCI_B,
        POA.ALCOHOL_400,
        POA.ALCOHOL_500,
        POA.ALCOHOL_1000,
        POA.NO_LCI_DISCOUNTED,
        POA.NO_LCI_400_COURT,
        POA.RA_250,
        POA.RA_300,
        POA.RA_400,
        POA.RA_500,
        POA.RA_700,
        POA.RA_1000,
        POA.LCI_UNCERTAIN,
        POA.LCI_UNCERTAIN_500,
        POA.LCI_UNCERTAIN_500_AUTOMATIC,
        POA.ARAG_UNCERTAIN,
        POA.ARAG_UNCERTAIN_500,
        POA.DEUTSCHE_DIREKT_UNCERTAIN_500,
      )
    ) {
      setIsMoneySectionShown(true)
    }
  }, [poaType])

  useEffect(() => {
    if (isMoneySectionShown) {
      dispatch(setUiKey(UI.CTA_BUTTON_TEXT, 'app.button.button_submit_give_us'))
      dispatch(setButtonNotInsuredClassname())
    } else {
      if (window['useAlternateCta']) {
        dispatch(setUiKey(UI.CTA_BUTTON_TEXT, 'app.button.button_submit_assign_us'))
      }
      dispatch(setButtonInsuredClassname())
    }
  }, [isMoneySectionShown])

  const oneOf = (...types) => {
    return includes(types, poaType)
  }

  const toggleIsMoneySectionShown = (e) => {
    e.preventDefault()
    if (e.type === 'click' || e.key === 'Enter') {
      setIsMoneySectionShown(!isMoneySectionShown)
      dispatch(setFormKey(FORM.IS_SWITCHED, !isSwitched))
      dispatch(setFormKey(FORM.REMUNERATION_AGREEMENT, INITIAL_FORM_STATE[FORM.REMUNERATION_AGREEMENT]))
    }
  }

  const isCheckboxTwoVisible = () => {
    const condition1 = oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B) && (A || C) && !isMoneySectionShown
    const condition2 = oneOf(POA.PENDING_ARAG, POA.PENDING_VER_SO_ARAG) && (A || C)
    const condition3 =
      oneOf(
        POA.LCI_UNCERTAIN,
        POA.LCI_UNCERTAIN_500,
        POA.LCI_UNCERTAIN_500_AUTOMATIC,
        POA.ARAG_UNCERTAIN,
        POA.ARAG_UNCERTAIN_500,
        POA.DEUTSCHE_DIREKT_UNCERTAIN_500,
      ) &&
      (A || C) &&
      !isMoneySectionShown
    if (condition1 || condition2 || condition3) {
      if (!listWithAgreed.every((l) => listOfMandatoryCheckboxes.includes(l))) {
        dispatch(setUiKey(UI.LIST_OF_MANDATORY_CHECKBOXES, listWithAgreed))
      }
    } else {
      if (!listOfMandatoryCheckboxes.every((l) => listWithoutAgreed.includes(l))) {
        dispatch(setUiKey(UI.LIST_OF_MANDATORY_CHECKBOXES, listWithoutAgreed))
      }
    }
    return condition1 || condition2 || condition3
  }

  const isNoInsuranceVisible = () => {
    const condition1 =
      oneOf(
        POA.NO_LCI,
        POA.NO_LCI_500,
        POA.NO_LCI_A,
        POA.NO_LCI_B,
        POA.ALCOHOL_400,
        POA.ALCOHOL_500,
        POA.ALCOHOL_1000,
        POA.NO_LCI_DISCOUNTED,
        POA.NO_LCI_400_COURT,
        POA.RA_250,
        POA.RA_300,
        POA.RA_400,
        POA.RA_500,
        POA.RA_700,
        POA.RA_1000,
      ) &&
      (A || C) &&
      isMoneySectionShown
    const condition2 =
      oneOf(
        POA.LCI_UNCERTAIN,
        POA.LCI_UNCERTAIN_500,
        POA.LCI_UNCERTAIN_500_AUTOMATIC,
        POA.ARAG_UNCERTAIN,
        POA.ARAG_UNCERTAIN_500,
        POA.DEUTSCHE_DIREKT_UNCERTAIN_500,
      ) &&
      (A || C) &&
      isMoneySectionShown
    const condition3 = oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B) && C && isMoneySectionShown
    return condition1 || condition2 || condition3
  }

  const isNoInsuranceSwitchVisible = () => {
    const condition1 =
      oneOf(
        POA.LCI_UNCERTAIN,
        POA.LCI_UNCERTAIN_500,
        POA.LCI_UNCERTAIN_500_AUTOMATIC,
        POA.ARAG_UNCERTAIN,
        POA.ARAG_UNCERTAIN_500,
        POA.DEUTSCHE_DIREKT_UNCERTAIN_500,
      ) &&
      (A || C) &&
      isMoneySectionShown
    const condition2 = oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B) && C && isMoneySectionShown
    return condition1 || condition2
  }

  const isCostCoverageLabelVisible = () => {
    const condition1 =
      oneOf(
        POA.LCI_UNCERTAIN,
        POA.LCI_UNCERTAIN_500,
        POA.LCI_UNCERTAIN_500_AUTOMATIC,
        POA.ARAG_UNCERTAIN,
        POA.ARAG_UNCERTAIN_500,
        POA.DEUTSCHE_DIREKT_UNCERTAIN_500,
      ) &&
      (A || C) &&
      !isMoneySectionShown
    const condition2 = oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B) && A
    const condition3 = oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B) && C && !isMoneySectionShown
    const condition4 = oneOf(POA.PENDING_ARAG, POA.PENDING_VER_SO_ARAG) && (A || C)
    return condition1 || condition2 || condition3 || condition4
  }

  const isUnderButtonSwitchVisible = () => {
    return oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B) && C && !isMoneySectionShown
  }

  return (
    <Fragment>
      <InfoUserBoxWrapper>
        <InfoBoxUserSummary isBlack />
      </InfoUserBoxWrapper>
      <CheckboxesWrapper>
        <InputCheckbox
          value={userInfoForm[FORM.POWER_OF_ATTORNEY_AGREEMENT]}
          ref={checkboxRefs.powerOfAttorneyAgreementRef}
          name={FORM.POWER_OF_ATTORNEY_AGREEMENT}
          hasError={isUpdateStepFailed && !validationResult[FORM.POWER_OF_ATTORNEY_AGREEMENT]}
          onChange={onChange}
        >
          <Trans
            i18nKey={getValueBasedOnBuyer(
              buyer,
              'dataGather.powerOfAttorneyAgreementRuff',
              'dataGather.powerOfAttorneyAgreementRightmart',
              'dataGather.powerOfAttorneyAgreement',
            )}
          >
            A<span>{{ caseGroupLawyerNames }}</span>
            <button data-tag="link-bold" onClick={openAuthoritiesModal} onKeyPress={openAuthoritiesModal}>
              L1
            </button>
            C
          </Trans>
        </InputCheckbox>
        {isCheckboxTwoVisible() && (
          <InputCheckbox
            value={userInfoForm[FORM.AGREED_TO_LAW_INSURANCE_REQUEST]}
            ref={checkboxRefs.agreedToLawInsuranceRequestRef}
            name={FORM.AGREED_TO_LAW_INSURANCE_REQUEST}
            label={getValueBasedOnBuyer(
              buyer,
              t('dataGather.agreedToLawInsuranceRequestRuff'),
              t('dataGather.agreedToLawInsuranceRequestRightmart'),
              t('dataGather.agreedToLawInsuranceRequest'),
            )}
            hasError={isUpdateStepFailed && !validationResult[FORM.AGREED_TO_LAW_INSURANCE_REQUEST]}
            onChange={onChange}
          />
        )}
        <InputCheckbox
          value={userInfoForm[FORM.DROP_REVOCATION_AGREEMENT]}
          name={FORM.DROP_REVOCATION_AGREEMENT}
          label={getValueBasedOnBuyer(
            buyer,
            t('dataGather.dropRevocationAgreementRuff'),
            t('dataGather.dropRevocationAgreementRightmart'),
            t('dataGather.dropRevocationAgreement'),
          )}
          onChange={onChange}
        />
      </CheckboxesWrapper>
      <SignaturePadWrapper>
        <SignaturePad
          generatedSignature={signature}
          title={t('dataGather.signature_title')}
          imageMode={{
            autogenerate: t('dataGather.signature_signature'),
            goToHandwriteMode: t('dataGather.signature_change_to_mouse'),
          }}
          handMode={{
            back: t('dataGather.signatureBackToMouse'),
            erase: t('dataGather.signatureEraseAll'),
          }}
          showSuccess={showSignatureSuccess}
          showError={showSignatureError}
          onUpdateSignature={(value) => dispatch(setFormKey(FORM.SIGNATURE, value))}
        />
      </SignaturePadWrapper>
      {isNoInsuranceVisible() && (
        <NoInsuranceWrapper data-anchor="no-insurance-wrap">
          <NoInsurance
            isSwitchVisible={isNoInsuranceSwitchVisible()}
            linkClickHandler={openCompensationAgreementModal}
            switchToggleHandler={toggleIsMoneySectionShown}
          />
        </NoInsuranceWrapper>
      )}
      {isCostCoverageLabelVisible() && !noBoxOnStrafsachen && (
        <BlueBorderedBox data-anchor="blue-box-wrap">
          <CostCoverageLabel />
        </BlueBorderedBox>
      )}
      <SubmitButton>
        <Button
          type="button"
          label={t(ctaButtonText)}
          fullWidth
          color="orange"
          size="lg"
          className={ctaButtonClassNames}
          spreadClassNames={ctaButtonClassNames}
          loading={isSignInProgress}
          onClick={() => {
            if (window.useAlternateCta) {
              trackCtaButton()
            }
            goToAttachment()
          }}
        />
      </SubmitButton>
      {isUnderButtonSwitchVisible() && (
        <UnderSubmitSwitch>
          <SwitchPoaType>
            <SwitchPoaTypeText>{t('dataGather.notSureTitle1')}</SwitchPoaTypeText>
            <SwitchPoaTypeLink
              className="insuranceToggle"
              onClick={toggleIsMoneySectionShown}
              onKeyPress={toggleIsMoneySectionShown}
            >
              {t('dataGather.notSureLink')}
            </SwitchPoaTypeLink>
          </SwitchPoaType>
        </UnderSubmitSwitch>
      )}
      <UnderSubmitButtonText>
        <Trans i18nKey="dataGather.submitSubTitle">
          A
          <button data-tag="link" tabIndex={0} onClick={openClientConditionModal} onKeyPress={openClientConditionModal}>
            L1
          </button>
          B
          <button data-tag="link" tabIndex={0} onClick={openRevocationModal} onKeyPress={openRevocationModal}>
            L2
          </button>
          C
        </Trans>
      </UnderSubmitButtonText>
      <LogoItemsWrapper>
        <SecurityLogos />
      </LogoItemsWrapper>
    </Fragment>
  )
}

Step3v2.displayName = 'Step3v2'

export default Step3v2
