import React from 'react'
import { SidebarTimetableText, SidebarTimetableTitle, SidebarTimetableWrapper } from './sidebar-timetable.styles'
import { useTranslation } from 'react-i18next'

const SidebarTimetable = () => {
  const { t } = useTranslation()

  return (
    <SidebarTimetableWrapper data-anchor="timetable">
      <SidebarTimetableTitle>{t('app.sidebar-user-info.timetableTitle')}</SidebarTimetableTitle>
      {[t('app.sidebar-user-info.timetable1'), t('app.sidebar-user-info.timetable2')].map((title, index) => (
        <SidebarTimetableText key={`timetable-item-${index}`}>{title}</SidebarTimetableText>
      ))}
    </SidebarTimetableWrapper>
  )
}

SidebarTimetable.displayName = 'SidebarTimetable'

export default SidebarTimetable
