import * as React from 'react'
function SvgInfoIcon({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle cx={7.5} cy={7.5} r={7} stroke="#FF8D02" />
      <path
        d="M7.988 5.624v5.544H6.82V5.624h1.168zm.196-1.673a.705.705 0 01-.065.298.78.78 0 01-.729.479.76.76 0 01-.299-.06.864.864 0 01-.239-.169.76.76 0 01-.228-.549c0-.108.02-.21.06-.304a.84.84 0 01.407-.413.717.717 0 01.603 0c.099.04.184.096.256.169a.72.72 0 01.169.244.717.717 0 01.065.305z"
        fill="#FF8D02"
      />
    </svg>
  )
}
export default SvgInfoIcon
