import { useLocation } from 'react-router-dom'
import { postFirstView } from '../axios/requests'
import { ALLOWED_PATH } from '../constants/allowed-path'
import { removeLastCharFromString, takeVariationFromUrl } from '../utils/url'

const useVariationPersist = () => {
  const location = useLocation()

  const contains = (str1, str2) => str1.indexOf(str2) !== -1

  return async (data, secret, cb) => {
    if (data.firstViewUrl) {
      const currentUrl = window.location.origin + window.location.pathname
      const redirect = new URLSearchParams(location.search).get('redirect')

      if (redirect === 'yes' && data.allowedPath === ALLOWED_PATH.BASE && data.firstViewUrl !== currentUrl) {
        const { status } = await postFirstView(currentUrl, secret)
        return status === 204 ? cb(true) : cb(false)
      }

      if (
        redirect === 'yes' &&
        data.allowedPath !== ALLOWED_PATH.BASE &&
        !contains(data.firstViewUrl, window.location.origin)
      ) {
        const variationOfFirstViewUrl = takeVariationFromUrl(removeLastCharFromString(data.firstViewUrl, '/'))
        const { status } = await postFirstView(window.location.origin + '/' + variationOfFirstViewUrl, secret)
        return status === 204 ? cb(true) : cb(false)
      }

      if (data.allowedPath === ALLOWED_PATH.BASE && data.firstViewUrl !== currentUrl) {
        return window.location.replace(data.firstViewUrl + window.location.search)
      }

      if (data.allowedPath !== ALLOWED_PATH.BASE && !contains(data.firstViewUrl, window.location.origin)) {
        const firstViewUrl = new URL(data.firstViewUrl).origin
        return window.location.replace(firstViewUrl + window.location.pathname)
      }
      cb(true)
    } else {
      const url = window.location.origin + location.pathname
      const { status } = await postFirstView(url, secret)
      status === 204 ? cb(true) : cb(false)
    }
  }
}

export default useVariationPersist
