import React from 'react'
import { Header, NotFound } from '@legal-one/leadfunnel-ui-kit'
import { LayoutNoSidebar } from '@legal-one/crm-vollmacht-v2-ui-kit'
import Footer from '../../components/footer/footer.component'
import { useSelector } from 'react-redux'
import { selectAuthKey } from '../../redux/auth/auth.selectors'
import { AUTH } from '../../redux/auth/auth.keys'
import { getValueBasedOnBuyer } from '../../utils/utils'

const NotFoundPage = () => {
  // TODO FUN-236: later remove once merging company ends
  const buyer = useSelector(selectAuthKey(AUTH.BUYER))

  const caseRuff = 'Mathis Ruff Rechtsanwaltsgesellschaft mbH<br/>Sonnenallee 260/262<br/>12057 Berlin'

  const caseRightmart = 'rightmart Berlin Rechtsanwaltsgesellschaft mbH<br/>Sonnenallee 260/262<br/>12057 Berlin'

  const caseDefault = 'rightmart Berlin Rechtsanwaltsgesellschaft mbH<br/>Sonnenallee 260/262<br/>12057 Berlin'

  getValueBasedOnBuyer(buyer)

  return (
    <LayoutNoSidebar
      main={() => (
        <NotFound
          title="Entschuldigung, da ist wohl etwas schief gelaufen!"
          subtitle="Fehler 404"
          table={{
            title: 'Bitte kontaktieren Sie uns doch per:',
            content: [
              {
                title: 'E-Mail:',
                content: 'kanzlei@sos-verkehrsrecht.de',
              },
              {
                title: 'Telefon:',
                content: '030 20 898 12 11',
              },
              {
                title: 'Fax:',
                content: '030 20 898 12 13',
              },
              {
                title: 'Post:',
                content: getValueBasedOnBuyer(buyer, caseRuff, caseRightmart, caseDefault),
              },
            ],
          }}
        />
      )}
      header={Header}
      footer={Footer}
      isLoading={false}
    />
  )
}

NotFoundPage.displayName = 'NotFoundPage'

export default NotFoundPage
