import React from 'react'
import PropTypes from 'prop-types'
import './file-size.css'
import { useDispatch } from 'react-redux'
import { closeAndResetModal, closeModal } from '../../redux/modal/modal.actions'

const FileSizeModal = ({ files }) => {
  const dispatch = useDispatch()

  const getMaxSizeText = () => {
    return files.length === 1
      ? 'überschreitet die maximale Dateigröße von 6 MB.'
      : 'überschreiten die maximale Dateigröße von 6 MB.'
  }

  const getWarningText = () => {
    return files.length === 1 ? 'Wählen Sie bitte eine kleinere Datei aus.' : 'Wählen Sie bitte kleinere Dateien aus.'
  }

  const onClick = () => {
    dispatch(closeModal())
  }

  return (
    <div className="file-size">
      <ul className="file-size__list">
        {files.map((file) => (
          <li key={file.name}>
            <i>{file.name} </i>- {parseFloat(String(file.size / 1024 / 1024)).toFixed(2)} MB
          </li>
        ))}
      </ul>
      <p>{getMaxSizeText()}</p>
      <p>{getWarningText()}</p>
      <div className="file-size__action">
        <button type="button" className="file-size__button" onClick={onClick}>
          Verstanden, Fenster schließen
        </button>
      </div>
    </div>
  )
}

FileSizeModal.propTypes = {
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      lastModified: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      webkitRelativePath: PropTypes.string,
    }),
  ),
}

export default FileSizeModal
