import React, { useEffect, useState } from 'react'
import { Button } from '@legal-one/leadfunnel-ui-kit'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '../../redux/ui/ui.keys'
import {
  selectIsAllowedToGoFromFirstStep,
  selectIsAllowedToGoFromSecondStep,
  selectUiKey,
} from '../../redux/ui/ui.selectors'
import { setUiKey } from '../../redux/ui/ui.actions'
import { MainButton, MainButtons, MainForm, MainInfoBox, MainStepTitle, MainTitle, MainWrapper } from './main.styles'
import Step1 from '../../components/data-gather/step-1/step-1.component'
import Step2 from '../../components/data-gather/step-2/step-2.component'
import Step3 from '../../components/data-gather/step-3/step-3.component'
import Remuneration from '../remuneration/remuneration.component'
import useRemuneration from '../../hooks/useRemuneration'
import InfoBox from '../info-box/info-box.component'

const Main = () => {
  const [title, setTitle] = useState('')

  const currentStep = useSelector(selectUiKey(UI.CURRENT_STEP))
  const isAllowedToGoFromFirstStep = useSelector(selectIsAllowedToGoFromFirstStep)
  const isAllowedToGoFromSecondStep = useSelector(selectIsAllowedToGoFromSecondStep)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { isRemunerationPage, remunerationTitle } = useRemuneration()

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setTitle(`1. ${t('app.title.step_one')}`)
        break
      case 2:
        setTitle(`2. ${t('app.title.step_two')}`)
        break
      case 3:
        setTitle(isRemunerationPage ? remunerationTitle : `3. ${t('app.title.step_three')}`)
        break
      default:
        setTitle('')
    }
  }, [currentStep, remunerationTitle, isRemunerationPage])

  const handleBackwardClick = (e) => {
    e.preventDefault()
    dispatch(setUiKey(UI.CURRENT_STEP, currentStep - 1))
  }

  const handleForwardClick = (e) => {
    e.preventDefault()
    switch (currentStep) {
      case 1:
        if (isAllowedToGoFromFirstStep) {
          dispatch(setUiKey(UI.CURRENT_STEP, currentStep + 1))
          dispatch(setUiKey(UI.IS_UPDATE_STEP_FAILED, false))
        } else {
          dispatch(setUiKey(UI.IS_UPDATE_STEP_FAILED, true))
        }
        break
      case 2:
        if (isAllowedToGoFromSecondStep) {
          dispatch(setUiKey(UI.CURRENT_STEP, currentStep + 1))
          dispatch(setUiKey(UI.IS_UPDATE_STEP_FAILED, false))
        } else {
          dispatch(setUiKey(UI.IS_UPDATE_STEP_FAILED, true))
        }
        break
      default:
        break
    }
  }

  return (
    <MainWrapper data-anchor="main">
      <MainTitle data-anchor="main-title">{t('app.title.page')}</MainTitle>
      <MainInfoBox data-anchor="main-infobox">
        <InfoBox isOnSidebar={false} />
      </MainInfoBox>
      <MainForm data-anchor="main-form" hasNoBottomPadding={currentStep === 3}>
        <MainStepTitle>{title}</MainStepTitle>
        {currentStep === 1 && <Step1 />}
        {currentStep === 2 && <Step2 />}
        {currentStep === 3 && isRemunerationPage && <Remuneration />}
        {currentStep === 3 && !isRemunerationPage && <Step3 />}
      </MainForm>
      {currentStep < 3 && (
        <MainButtons data-anchor="main-buttons">
          {currentStep > 1 && (
            <MainButton>
              <Button label={t('app.button.button_back')} color="white-grey" size="xl" onClick={handleBackwardClick} />
            </MainButton>
          )}
          <MainButton>
            <Button label={t('app.button.button_next')} color="orange" size="xl" forward onClick={handleForwardClick} />
          </MainButton>
        </MainButtons>
      )}
    </MainWrapper>
  )
}

Main.displayName = 'Main'

export default Main
