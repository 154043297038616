import store from '../redux/store'
import { AUTH } from '../redux/auth/auth.keys'

const getBaseUrl = () => {
  const storeState = store.getState()
  return storeState.auth[AUTH.API_URL]
}

const getUnifiedBaseUrl = () => {
  const storeState = store.getState()
  return storeState.auth[AUTH.UNIFIED_API_URL]
}

const path = {
  root: () => '/config.json',
  authenticate: (secret) => getBaseUrl() + '/authenticate/' + secret,
  sign: () => getBaseUrl() + '/sign',
  signRemuneration: () => getBaseUrl() + '/sign/remuneration',
  signature: (firstName, lastName) => getBaseUrl() + `/getsignature/${firstName} ${lastName}`,
  firstView: () => getBaseUrl() + '/first-view',
  insuranceInformation: () => getBaseUrl() + '/insurance-information',
  files: () => getBaseUrl() + '/files',
  clientSurvey: () => getBaseUrl() + '/client-survey',
  sendEmail: () => getBaseUrl() + '/send-email',
  trackAlternateCta: (secret) => getBaseUrl() + '/track-cta/' + secret,
  trackAttachmentReminder: (secret) => getBaseUrl() + '/attachement-reminder/' + secret,
  postMarketingCase: (caseIdentifier) => getUnifiedBaseUrl() + '/marketing/case/' + caseIdentifier,
}

export default path
