import { createSelector } from 'reselect'
import { FORM } from './form.keys'

export const selectForm = (state) => state.form

export const selectFormKey = (key) => createSelector([selectForm], (form) => form[key])

export const selectUserData = createSelector([selectForm], (form) => ({
  [FORM.FIRST_NAME]: form[FORM.FIRST_NAME],
  [FORM.LAST_NAME]: form[FORM.LAST_NAME],
  [FORM.BIRTH_NAME]: form[FORM.BIRTH_NAME],
  [FORM.BIRTH_DATE]: form[FORM.BIRTH_DATE],
  [FORM.BIRTH_CITY]: form[FORM.BIRTH_CITY],
  [FORM.STREET]: form[FORM.STREET],
  [FORM.HOUSE_NUMBER]: form[FORM.HOUSE_NUMBER],
  [FORM.POST_CODE]: form[FORM.POST_CODE],
  [FORM.CITY]: form[FORM.CITY],
}))
