import React from 'react'
import { Footer } from '@legal-one/crm-vollmacht-v2-ui-kit'
import { useTranslation } from 'react-i18next'
import useModal from '../../hooks/useModal'

const FooterComponent = () => {
  const { t } = useTranslation()

  const { openImprintModal, openInformationModal, openPrivacyPolicyModal } = useModal()

  const links = [
    { label: t('app.footer.link_info'), action: openInformationModal },
    { label: t('app.footer.link_date'), action: openPrivacyPolicyModal },
    { label: t('app.footer.link_impressum'), action: openImprintModal },
  ]

  return <Footer company="sos-verkehrsrecht.de" links={links} />
}

FooterComponent.displayName = 'Footer'

export default FooterComponent
