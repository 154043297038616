import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormKey } from '../../redux/form/form.selectors'
import { Header } from '@legal-one/leadfunnel-ui-kit'
import {LayoutDefault} from '@legal-one/crm-vollmacht-v2-ui-kit'
import Footer from '../../components/footer/footer.component'
import Main from '../../components/main/main.component'
import Sidebar from '../../components/sidebar/sidebar.component'
import { UI } from '../../redux/ui/ui.keys'
import { selectUiKey } from '../../redux/ui/ui.selectors'
import { FORM } from '../../redux/form/form.keys'
import { setUiKey } from '../../redux/ui/ui.actions'
import { getPathFromLocation } from '../../utils/url'
import { useLocation } from 'react-router-dom'
import { ALLOWED_PATH } from '../../constants/allowed-path'
import { selectAuthKey } from '../../redux/auth/auth.selectors'
import { AUTH } from '../../redux/auth/auth.keys'

const RemunerationPage = () => {
  const isAuthInProgress = useSelector(selectUiKey(UI.IS_AUTH_IN_PROGRESS))
  const isInitInProgress = useSelector(selectUiKey(UI.IS_INIT_IN_PROGRESS))
  const allowedPath = useSelector(selectAuthKey(AUTH.ALLOWED_PATH))
  const isBirthDateEmpty = useSelector(selectFormKey(FORM.IS_BIRTHDATE_EMPTY))

  const location = useLocation()

  const dispatch = useDispatch()

  React.useEffect(() => {
    const path = getPathFromLocation(location)
    if (path === ALLOWED_PATH.REMUNERATION && path === allowedPath) {
      dispatch(setUiKey(UI.ARE_FIELDS_EDITABLE, !isBirthDateEmpty));
      dispatch(setUiKey(UI.LIST_OF_MANDATORY_CHECKBOXES, [FORM.REMUNERATION_AGREEMENT, FORM.SIGNATURE]));
      if (!isBirthDateEmpty) {
        dispatch(setUiKey(UI.CURRENT_STEP, 3))
      } else {
        dispatch(setUiKey(UI.CURRENT_STEP, 1))
      }
    }
  }, [allowedPath, isBirthDateEmpty])



  return (
    <LayoutDefault
      main={Main}
      header={Header}
      footer={Footer}
      sidebar={Sidebar}
      isLoading={Boolean(isAuthInProgress || isInitInProgress)}
    />
  )
}

RemunerationPage.displayName = 'RemunerationPage'

export default RemunerationPage
