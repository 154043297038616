import { combineReducers } from 'redux'
import { AuthReducer } from './auth/auth.reducer'
import { FormReducer } from './form/form.reducer'
import modalReducer from './modal/modal.reducer'
import { UiReducer } from './ui/ui.reducer'
import userAttachmentUiReducer from './user-attachment-ui/user-attachment-ui.reducer'

export default combineReducers({
  auth: AuthReducer,
  form: FormReducer,
  modal: modalReducer,
  ui: UiReducer,
  userAttachmentUi: userAttachmentUiReducer,
})
