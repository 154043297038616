import './form-type-explanation.css'
import { Button } from '@legal-one/leadfunnel-ui-kit'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../redux/modal/modal.actions'

const FormTypeExplanationModal = () => {
  const dispatch = useDispatch()

  return (
    <div className="form-type-explanation">
      <h2>Worin unterscheiden sich Bußgeldbescheid, Anhörungsbogen und Zeugenfragebogen?</h2>
      <h4>Bußgeldbescheid</h4>
      <p>
        Sie erkennen ihn an der Bezeichnung <strong>“Bußgeldbescheid”</strong> prominent in der obern hälfte des
        Schreibens.
      </p>
      <h4>Anhörungsbogen</h4>
      <p>
        Sie erkennen ihn an der Bezeichnung <strong>“Anhörungsbogen”</strong> oder{' '}
        <strong>“Anhörung zum Verstoß...”</strong> prominent in der obern hälfte des Schreibens.
      </p>
      <h4>Zeugenfragebogen</h4>
      <p>
        Sie erkennen ihn an der Bezeichnung <strong>“Zeugenfragebogen”</strong> oder{' '}
        <strong>“Feststellung des Fahrzeughalters”</strong> prominent in der obern hälfte des Schreibens.
      </p>
      <Button
        color="orange"
        size="sm"
        label="Verstanden, Fenster schließen"
        fullWidth
        onClick={() => dispatch(closeModal())}
      />
    </div>
  )
}

export default FormTypeExplanationModal
