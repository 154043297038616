const ClientConditionsModal = () => (
  <>
    <h4>I.</h4>
    <p>
      Gegenüber den folgenden Dienstleistern entbindet der Mandant die beauftragten Rechtsanwälte sowie sämtliche
      Mitarbeiter der rightmart Berlin Rechtsanwaltsgesellschaft mbH von der Verschwiegenheitspflicht, sofern und soweit dies
      für eine sachgerechte und effiziente Bearbeitung und Abwicklung des Mandats in rechtlich-inhaltlicher sowie
      administrativer Hinsicht oder zur Sicherung der Honorarforderungen von der rightmart Berlin Rechtsanwaltsgesellschaft 
      mbH und zur Beachtung steuerlicher Vorschriften, denen die rightmart Berlin Rechtsanwaltsgesellschaft mbH unterliegt,
      sinnvoll ist: (Rechtsschutz-) Versicherungen; in das Mandat in sozialadäquater Art und Weise eingebundene externe
      Übersetzer und Dolmetscher, Rechtsanwälte, Steuerberater, Wirtschaftsprüfer, Forderungsmanager, Sachverständige
      und sonstige externe Berater oder Mitarbeiter; externe EDV-Dienstleister.
    </p>
    <h4>II.</h4>
    <p>
      Die beauftragten Rechtsanwälte machen den Mandanten darauf aufmerksam, dass die Kommunikation übers Internet und
      via E-Mail keine vollständige Sicherheit in Bezug auf die Geheimhaltung der übermittelten Daten und Informationen
      bietet, auch wenn die Kanzlei mittels transportverschlüsselter E-Mails kommuniziert. Der Mandant stimmt einer
      Kommunikation mittels "einfacher E-Mail" in Kenntnis der potentiellen Gefahren in seiner Angelegenheit zu. Dies
      schließt die Kommunikation mit Dritten (z.B. Rechtsschutzversicherungen, Gutachtern) mit ein.
    </p>
    <h4>III.</h4>
    <p>
      Dem Mandanten ist bekannt, dass er gemäß § 10 Abs. 1 S. 1 RVG einen Anspruch auf eine im Original unterschriebene
      Rechnung hat. Er verzichtet jedoch ausdrücklich auf diesen Anspruch und bestätigt, dass er die Rechnung
      ausschließlich elektronisch übermittelt haben möchte (§ 14 Abs. 1 S. 7 UStG).
    </p>
    <h4>IV.</h4>
    <p>
      Der Mandant erklärt sich damit einverstanden, dass zur Unterrichtung seiner Rechtsschutzversicherung über den
      Fortgang der Angelegenheit (digitale) Kopien angefertigt und an diese weitergeleitet werden.
    </p>
    <h4>V</h4>
    <p>
      Für vermögensrechtliche Streitigkeiten aus dem Mandatsverhältnis ist die Schlichtungsstelle der
      Rechtsanwaltschaft, Rauchstraße 26, 10787 Berlin,{' '}
      <a data-tag="link" href="http://www.s-d-r.org" target="_blank" rel="noreferrer">
        www.s-d-r.org
      </a>
      , zuständig. Die Kanzlei ist nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle teilzunehmen.
    </p>
  </>
)

export default ClientConditionsModal
