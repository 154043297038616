import axios from 'axios'
import { httpsResponseInterceptor, httpsErrorInterceptor } from './interceptors'

const TIMEOUT = 30000

export const HTTPS_INIT = axios.create({
  baseURL: '/',
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const HTTPS = axios.create({
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const HTTPS_MULTIPART = axios.create({
  timeout: 180000,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

HTTPS.interceptors.response.use(httpsResponseInterceptor, httpsErrorInterceptor)

HTTPS_MULTIPART.interceptors.response.use(httpsResponseInterceptor, httpsErrorInterceptor)
