import { useHistory, useParams } from 'react-router-dom'
import { trackAttachmentReminder } from '../axios/requests'

export const useUploadTracking = () => {
  const history = useHistory()
  const params = useParams()
  const search = history.location.search

  return () => {
    if (search.indexOf('source') > -1 && search.toLowerCase().indexOf('sms') > -1) {
        trackAttachmentReminder(params.secret)
      }
    
  }
}
