import styled from 'styled-components'
import { COLORS } from '@legal-one/leadfunnel-ui-kit'

export const SidebarTimetableWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const SidebarTimetableTitle = styled.h6`
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.GREY_10};
`

export const SidebarTimetableText = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.GREY_10};
`
