import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { includes } from 'lodash/collection'
import { InputCheckbox } from '@legal-one/leadfunnel-ui-kit'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthKey } from '../../../../../../../redux/auth/auth.selectors'
import { AUTH } from '../../../../../../../redux/auth/auth.keys'
import { selectFormKey } from '../../../../../../../redux/form/form.selectors'
import { setFormKey } from '../../../../../../../redux/form/form.actions'
import { getValueBasedOnBuyer, getPrice } from '../../../../../../../utils/utils'
import { POA } from '../../../../../../../constants/poa-types'
import {
  NoInsuranceCheckboxWrapper,
  NoInsuranceContentText,
  NoInsurancePriceText,
  NoInsurancePriceWrapper,
  NoInsuranceSwitch,
  NoInsuranceTitle,
  NoInsuranceWrapper,
} from './no-insurance.styles'
import { FORM } from '../../../../../../../redux/form/form.keys'
import { selectUiKey } from '../../../../../../../redux/ui/ui.selectors'
import { UI } from '../../../../../../../redux/ui/ui.keys'
import {
  BlueBorderedBox,
  SwitchPoaType,
  SwitchPoaTypeLink,
  SwitchPoaTypeText,
} from '../../../../../../../styles/common.styles'
import useModal from '../../../../../../../hooks/useModal'

const NoInsuranceComponent = (props) => {
  const { isSwitchVisible, linkClickHandler, switchToggleHandler } = props

  const total = useSelector(selectAuthKey(AUTH.TOTAL))
  const switchTotal = useSelector(selectAuthKey(AUTH.SWITCH_TOTAL))
  const remunerationAgreement = useSelector(selectFormKey(FORM.REMUNERATION_AGREEMENT))
  const isUpdateStepFailed = useSelector(selectUiKey(UI.IS_UPDATE_STEP_FAILED))
  const checkboxRef = useSelector(selectUiKey(UI.CHECKBOX_REFS))
  const poaType = useSelector(selectAuthKey(AUTH.LATEST_SENT_POA_TYPE))
  const variation = useSelector(selectUiKey(UI.VARIATION))
  const buyer = useSelector(selectAuthKey(AUTH.BUYER))

  const dispatch = useDispatch()

  const { openCompensationAgreementLongModal } = useModal()

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(setFormKey(FORM.REMUNERATION_AGREEMENT, false))
  }, [])

  const handleIsRemunerationAgreedChange = ({ value }) => {
    dispatch(setFormKey(FORM.REMUNERATION_AGREEMENT, value))
  }

  const oneOf = (...types) => {
    return includes(types, poaType)
  }

  const noInsuranceContent = (poaType) => {
    switch (poaType) {
      case POA.NO_LCI_400_COURT:
      case POA.ALCOHOL_1000:
      case POA.RA_1000:
        return (
          <Trans
            i18nKey={getValueBasedOnBuyer(
              buyer,
              'dataGather.step3NoInsuranceTextCourtRuff',
              'dataGather.step3NoInsuranceTextCourtRightmart',
              'dataGather.step3NoInsuranceTextCourt',
            )}
          >
            A
            <button data-tag="link-bold" tabIndex={0} onClick={linkClickHandler} onKeyPress={linkClickHandler}>
              L1
            </button>
            B
          </Trans>
        )
      default:
        return (
          <Trans
            i18nKey={getValueBasedOnBuyer(
              buyer,
              'dataGather.step3NoInsuranceTextRuff',
              'dataGather.step3NoInsuranceTextRightmart',
              'dataGather.step3NoInsuranceText',
            )}
          >
            A
            <button data-tag="link-bold" tabIndex={0} onClick={linkClickHandler} onKeyPress={linkClickHandler}>
              L1
            </button>
            B
          </Trans>
        )
    }
  }

  const getTotal = () => {
    return oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B) && variation === 'c' ? switchTotal : total
  }

  return (
    <NoInsuranceWrapper data-anchor="no-insurance">
      <NoInsuranceTitle>{t('dataGather.step3NoInsuranceTitle')}</NoInsuranceTitle>
      <NoInsuranceContentText>{noInsuranceContent(poaType)}</NoInsuranceContentText>
      <NoInsurancePriceWrapper>
        <NoInsurancePriceText>{t('dataGather.constSectionOnePrice')}</NoInsurancePriceText>
        <NoInsurancePriceText>{getPrice(getTotal())}</NoInsurancePriceText>
      </NoInsurancePriceWrapper>
      {isSwitchVisible && (
        <NoInsuranceSwitch>
          <BlueBorderedBox small>
            <SwitchPoaType>
              <SwitchPoaTypeText>{t('dataGather.notSureTitle2')}</SwitchPoaTypeText>
              <SwitchPoaTypeLink
                green
                className="insuranceToggle"
                onClick={switchToggleHandler}
                onKeyPress={switchToggleHandler}
              >
                {t('dataGather.notSureLink')}
              </SwitchPoaTypeLink>
            </SwitchPoaType>
          </BlueBorderedBox>
        </NoInsuranceSwitch>
      )}
      <NoInsuranceCheckboxWrapper>
        <InputCheckbox
          ref={checkboxRef.remunerationAgreementRef}
          value={remunerationAgreement}
          name={FORM.REMUNERATION_AGREEMENT}
          onChange={handleIsRemunerationAgreedChange}
          hasError={isUpdateStepFailed && !remunerationAgreement}
        >
          <Trans i18nKey="dataGather.step3NoInsuranceCheckbox">
            A
            <button
              data-tag="link-bold"
              tabIndex={0}
              onClick={openCompensationAgreementLongModal}
              onKeyPress={openCompensationAgreementLongModal}
            >
              L1
            </button>
            B
          </Trans>
        </InputCheckbox>
      </NoInsuranceCheckboxWrapper>
    </NoInsuranceWrapper>
  )
}

NoInsuranceComponent.displayName = 'NoInsurance'

NoInsuranceComponent.propTypes = {
  isSwitchVisible: PropTypes.bool.isRequired,
  linkClickHandler: PropTypes.func.isRequired,
  switchToggleHandler: PropTypes.func.isRequired,
}

export default NoInsuranceComponent
