import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ChevronDownDuotone, SearchDuotone } from '../../../../svg'
import TinyCollapse from 'react-tiny-collapse'
import TextInput from './TextInput'

class Select extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValid: null,
      value: '',
      valueLabel: '',
      isOpen: false,
      query: '',
      errorMessage: '',
    }

    this.wrapper = React.createRef()
    this.icon = React.createRef()
    this.search = React.createRef()
    this.freeText = React.createRef()
    this.itemContainer = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
    document.addEventListener('keypress', (e) => {
      if (e.charCode === 13 && this.state.isOpen === true) {
        this.validate(true)
        this.setState({ isOpen: false })
      }
    })

    const { options } = this.props

    options.forEach((option) => {
      if (Object.prototype.hasOwnProperty.call(option, 'defaultValue') && option.defaultValue === true) {
        this.setState({
          value: option.value,
          valueLabel: option.label,
        })
      }
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
    document.removeEventListener('keypress', this.handleClick, false)
  }

  validate = (fireCallback) => {
    const { validator, onChange, nextCallback, waitForCallback } = this.props

    const { value } = this.state

    if (value === 'Other' && this.freeText.current) {
      this.freeText.current.setValue('')
      this.freeText.current.focus()
    }

    let isValid = null
    let errorMessage = null

    if (validator) {
      const result = validator(value)
      isValid = result.valid
      errorMessage = result.errorMessage
    }

    this.setState({
      isValid,
      errorMessage,
    })

    const returnObject = {
      isValid,
      value,
    }

    if (fireCallback === true && onChange) {
      onChange(returnObject)
      waitForCallback && nextCallback && nextCallback()
    }

    return returnObject
  }

  handleClick = (event) => {
    if (this.wrapper.current.contains(event.target) || this.icon.current.contains(event.target)) {
      return
    }

    this.hideDropdown()
  }

  handleFreeTextChange = (item) => {
    this.setState({
      value: item.value,
      valueLabel: item.value,
    })
  }

  renderSingleOption = (option, index) => {
    const { query, value } = this.state

    const { hasSearch, options } = this.props

    let label = option.label

    // underline match if query is set
    if (hasSearch === true && query.length > 0) {
      const matchIndex = label.toLowerCase().indexOf(query.toLowerCase())

      const stringBeforeMatch = label.substr(0, matchIndex)
      const match = label.substr(matchIndex, query.length)
      const stringAfterMatch = label.substr(matchIndex + query.length, label.length)

      label = `${stringBeforeMatch}<b><u>${match}</u></b>${stringAfterMatch}`
    }

    const cleanOption = {
      value: option.isFreeText === true ? '' : option.value,
      label: option.label,
    }

    const itemClick = (e) => {
      if (this.props.stopPropagation) e.stopPropagation()
      this.setValue(cleanOption)
    }

    return (
      <div className="select-dropdown-item" key={index} onClick={itemClick}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {option.isFreeText === true && options.filter((item) => item.value === value).length === 0 && value !== '' && (
          <TextInput id={`extra-select-text-${index}`} onChange={this.handleFreeTextChange} ref={this.freeText} />
        )}
      </div>
    )
  }

  toggleDropdown = (e) => {
    if (this.props.stopPropagation) e.stopPropagation()
    const nextIsOpen = !this.state.isOpen

    const { hasSearch = true } = this.props

    this.setState(
      {
        isOpen: nextIsOpen,
      },
      () => {
        if (nextIsOpen === true && hasSearch === true) {
          setTimeout(() => this.search.current.focus(), 100)
        }
      },
    )
  }

  hideDropdown = () => {
    this.setState({
      isOpen: false,
    })
  }

  setValue = (option) => {
    this.setState(
      {
        value: option.value !== '' ? option.value : 'Other',
        valueLabel: option.label,
        isOpen: option.value === '',
      },
      () => {
        if (option.value !== '') {
          this.validate(true)
        } else {
          this.itemContainer.current.scrollTop = 10000
          this.freeText.current.focus()
        }
      },
    )
  }

  doesOptionContainQuery = (option) => option.label.toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1

  changeQuery = (event) => {
    this.setState({ query: event.target.value })
  }

  renderFilteredResult = (options) => {
    const filteredOptions = options.filter(this.doesOptionContainQuery).map(this.renderSingleOption)

    if (filteredOptions.length > 0) {
      return filteredOptions
    }

    return (
      <div className="select-no-result-container">
        <span className="select-no-result-message">Keine Versicherung gefunden.</span>
      </div>
    )
  }

  render() {
    const { id, options, placeholder, label, hasSearch = true, searchPlaceholder } = this.props

    const { isValid, isOpen, valueLabel, query, errorMessage } = this.state

    return (
      <div
        className={cx('select-container', {
          'is-valid': isValid === true,
          'is-not-valid': isValid === false,
        })}
      >
        {label && <div className="select-label">{label}</div>}
        <div className="select-dropdown-wrapper" ref={this.wrapper}>
          {errorMessage && isOpen === false && <div className="select-dropdown-error">{errorMessage}</div>}
          <input
            id={id}
            type="text"
            className="select-input"
            onClick={this.toggleDropdown}
            value={valueLabel}
            placeholder={placeholder}
            readOnly
          />
          <span
            className={cx('select-icon-down', {
              'is-open': isOpen === true,
            })}
            onClick={this.toggleDropdown}
            ref={this.icon}
          >
            <ChevronDownDuotone />
          </span>
          <TinyCollapse isOpen={isOpen === true} className="select-dropdown">
            <div>
              {hasSearch === true && (
                <div className="select-dropdown-search-container">
                  <input
                    ref={this.search}
                    type="text"
                    className="select-dropdown-search"
                    value={query}
                    placeholder={searchPlaceholder ? searchPlaceholder : ''}
                    onChange={this.changeQuery}
                  />
                  <span className="select-dropdown-search-icon">
                    <SearchDuotone />
                  </span>
                </div>
              )}
              <div className="select-dropdown-item-container" ref={this.itemContainer}>
                {hasSearch === true && query !== ''
                  ? this.renderFilteredResult(options)
                  : options.map(this.renderSingleOption)}
              </div>
            </div>
          </TinyCollapse>
        </div>
      </div>
    )
  }
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]).isRequired,
      defaultValue: PropTypes.bool,
      isFreeText: PropTypes.bool,
    }),
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasSearch: PropTypes.bool,
  validator: PropTypes.func,
  onChange: PropTypes.func,
  waitForCallback: PropTypes.bool,
  nextCallback: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  stopPropagation: PropTypes.bool,
}

Select.defaultProps = {
  hasSearch: true,
}

export default Select
