import {
  SET_ACTIVE_SECTION,
  SET_IS_INSURANCE_INFORMATION_POSTED,
  SET_IS_FILE_POSTED,
  SET_IS_CLIENT_SURVEY_POSTED,
  SET_INSURANCE_NAME,
  SET_INSURANCE_NUMBER,
  SET_UPLOAD_FILES_ON_AUTH,
  SET_UPLOAD_COUNT,
  ADD_ACCEPTED_FILES,
  DELETE_ACCEPTED_FILE,
  SET_FILE_UPLOAD_AFTER_POST,
  SET_WAIVER_ON_DRIVING_BAN_IMPORTANT,
  SET_CAR_OWNER,
  SET_RELATIONSHIP_OWNER_DRIVER,
  SET_ADDITIONAL_LEGAL_INSURANCE,
  SET_ADDITIONAL_INFORMATION,
  SET_FILES_ARE_UPLOADING,
  SET_FILES_ARE_UPLOADED,
} from './user-attachment-ui.types'
import { getInitialFileUploadObject } from './user.attachment-ui.utils'
import { isEmpty } from 'lodash'

const INITIAL_STATE = {
  activeSection: '',
  isInsuranceInformationPosted: false,
  isFilePosted: false,
  isClientSurveyPosted: false,
  insuranceName: '',
  insuranceNumber: '',
  fileUploadCount: '_',
  fileUploads: [],
  filesAreUploading: false,
  filesAreUploaded: false,
  waiverOnDrivingBanImportant: false,
  carOwner: '',
  relationshipOwnerDriver: '',
  additionalLegalInsurance: '',
  additionalInformation: '',
}

const userAttachmentUiReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: payload,
      }
    case SET_IS_INSURANCE_INFORMATION_POSTED:
      return {
        ...state,
        isInsuranceInformationPosted: payload,
      }
    case SET_IS_FILE_POSTED:
      return {
        ...state,
        isFilePosted: payload,
      }
    case SET_IS_CLIENT_SURVEY_POSTED:
      return {
        ...state,
        isClientSurveyPosted: payload,
      }
    case SET_INSURANCE_NAME:
      return {
        ...state,
        insuranceName: payload,
      }
    case SET_INSURANCE_NUMBER:
      return {
        ...state,
        insuranceNumber: payload,
      }
    case SET_UPLOAD_FILES_ON_AUTH:
      if (isEmpty(payload)) {
        return { ...state }
      } else {
        const filesSection = payload.find((section) => section.name === 'files')
        let fileUploadCount = 0
        const fileUploads = []
        if (filesSection.completed === true) {
          fileUploadCount = filesSection.data.uploadsCount
        }
        if (fileUploadCount > 0) {
          Array.from({ length: fileUploadCount }).forEach((item, index) => {
            fileUploads.push(getInitialFileUploadObject(index, true))
          })
        }
        return {
          ...state,
          fileUploadCount,
          fileUploads,
        }
      }
    case SET_UPLOAD_COUNT: {
      const fileUploads = Array.from(state.fileUploads)
      for (let i = fileUploads.length; i < payload; i++) {
        fileUploads.push(getInitialFileUploadObject(i))
      }
      if (state.fileUploads.length > payload) {
        fileUploads.splice(payload - 1, state.fileUploads.length)
      }

      return {
        ...state,
        fileUploadCount: parseInt(payload),
        fileUploads: fileUploads,
      }
    }
    case ADD_ACCEPTED_FILES: {
      const { fileUploadIndex, acceptedFiles } = payload
      const fileUploads = Array.from(state.fileUploads)
      fileUploads[fileUploadIndex].acceptedFiles = fileUploads[fileUploadIndex].acceptedFiles.concat(acceptedFiles)
      return {
        ...state,
        fileUploads: fileUploads,
      }
    }
    case DELETE_ACCEPTED_FILE: {
      const { fileUploadIndex, file } = payload
      const acceptedFiles = Array.from(state.fileUploads[fileUploadIndex].acceptedFiles)
      const fileUploads = Array.from(state.fileUploads)
      const index = acceptedFiles.findIndex((f) => f.path === file.path)
      acceptedFiles.splice(index, 1)
      fileUploads[fileUploadIndex].acceptedFiles = acceptedFiles
      return {
        ...state,
        fileUploads: fileUploads,
      }
    }
    case SET_FILE_UPLOAD_AFTER_POST: {
      const { fileUploads } = state
      fileUploads.forEach((fileUpload) => {
        if (fileUpload.index === payload) {
          fileUpload.success = true
        }
      })
      return {
        ...state,
        fileUploads,
      }
    }
    case SET_WAIVER_ON_DRIVING_BAN_IMPORTANT:
      return {
        ...state,
        waiverOnDrivingBanImportant: payload,
      }
    case SET_CAR_OWNER:
      return {
        ...state,
        carOwner: payload,
      }
    case SET_RELATIONSHIP_OWNER_DRIVER:
      return {
        ...state,
        relationshipOwnerDriver: payload,
      }
    case SET_ADDITIONAL_LEGAL_INSURANCE:
      return {
        ...state,
        additionalLegalInsurance: payload,
      }
    case SET_ADDITIONAL_INFORMATION:
      return {
        ...state,
        additionalInformation: payload,
      }
    case SET_FILES_ARE_UPLOADING:
      return {
        ...state,
        filesAreUploading: payload,
      }
    case SET_FILES_ARE_UPLOADED:
      return {
        ...state,
        filesAreUploaded: payload,
      }
    default:
      return state
  }
}

export default userAttachmentUiReducer
