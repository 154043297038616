import React, { useEffect, useState, Fragment } from 'react'
import { InputRadio, InputCheckbox } from '@legal-one/leadfunnel-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormKey } from '../../../../../../../redux/form/form.selectors'
import { selectAuthKey } from '../../../../../../../redux/auth/auth.selectors'
import { setFormKey } from '../../../../../../../redux/form/form.actions'
import { Trans, useTranslation } from 'react-i18next'
import useModal from '../../../../../../../hooks/useModal'
import { getValueBasedOnBuyer, getPrice } from '../../../../../../../utils/utils'
import { usePrevious } from '../../../../../../../hooks/usePrevious'
import { POA } from '../../../../../../../constants/poa-types'
import { includes } from 'lodash'
import { AUTH } from '../../../../../../../redux/auth/auth.keys'
import { FORM } from '../../../../../../../redux/form/form.keys'
import { selectUiKey } from '../../../../../../../redux/ui/ui.selectors'
import { UI } from '../../../../../../../redux/ui/ui.keys'
import {
  setButtonInsuredClassname,
  setButtonNotInsuredClassname,
  setUiKey,
} from '../../../../../../../redux/ui/ui.actions'
import {
  CostCoverageSelectorWrapper,
  CostCoverageSelectorSwitchText,
  CostCoverageSelectorPrice,
  CostCoverageSelectorTableText,
  CostCoverageSelectorContent,
  CostCoverageSelectorBoard,
  CostCoverageSelectorSwitchRadio,
  CostCoverageSelectorSwitch,
  CostCoverageSelectorSwitchItem,
  CostCoverageSelectorBoardLine,
  CostCoverageSelectorCheckbox,
} from './cost-coverage-selector.styles'
import { BlueBorderedBox, PriceLineText } from '../../../../../../../styles/common.styles'
import CostCoverageLabel from '../../../../../../cost-coverage-label/cost-coverage-label.component'
import { INITIAL_FORM_STATE } from '../../../../../../../redux/form/form.reducer'

const CostCoverageSelector = () => {
  const listOfSelectors = [
    {
      key: '1',
      label: 'Ich möchte die Verfahrens&shy;gebühren selbst bezahlen',
    },
    {
      key: '2',
      label: 'Ich möchte den ARAG Rechts&shy;schutz in Anspruch nehmen',
    },
    {
      key: '3',
      label: 'Ich möchte über meine Rechts&shy;schutzversicherung abrechnen',
    },
  ]

  const [list, setList] = useState([])
  const [value, setValue] = useState(undefined)
  const [focused, toggleFocused] = useState(-1)
  const [selectedValue, setSelectedValue] = useState(-1)
  const [boardOneState, setBoardOneState] = useState(false)
  const [boardTwoState, setBoardTwoState] = useState(false)
  const [boardThreeState, setBoardThreeState] = useState(false)

  const remunerationAgreement = useSelector(selectFormKey(FORM.REMUNERATION_AGREEMENT))
  const poaType = useSelector(selectAuthKey(AUTH.LATEST_SENT_POA_TYPE))
  const validationResult = useSelector(selectUiKey(UI.VALIDATION_RESULTS))
  const agreedToLawInsuranceRequest = useSelector(selectFormKey(FORM.AGREED_TO_LAW_INSURANCE_REQUEST))
  const isUpdateStepFailed = useSelector(selectUiKey(UI.IS_UPDATE_STEP_FAILED))
  const checkboxRefs = useSelector(selectUiKey(UI.CHECKBOX_REFS))
  const isSwitched = useSelector(selectFormKey(FORM.IS_SWITCHED))
  const total = useSelector(selectAuthKey(AUTH.TOTAL))
  const switchTotal = useSelector(selectAuthKey(AUTH.SWITCH_TOTAL))
  const variation = useSelector(selectUiKey(UI.VARIATION))
  const buyer = useSelector(selectAuthKey(AUTH.BUYER))

  const dispatch = useDispatch()

  const prevValue = usePrevious(value)

  const { t } = useTranslation()

  const { openCompensationAgreementModal, openCompensationAgreementLongModal } = useModal()

  // MOVED
  useEffect(() => {
    if (oneOf(POA.NO_LCI, POA.NO_LCI_500, POA.NO_LCI_A, POA.NO_LCI_B)) {
      setList([listOfSelectors[0]])
      setValue(listOfSelectors[0].key)
    }

    if (
      oneOf(
        POA.LCI_UNCERTAIN,
        POA.LCI_UNCERTAIN_500,
        POA.LCI_UNCERTAIN_500_AUTOMATIC,
        POA.DEUTSCHE_DIREKT_UNCERTAIN_500,
      )
    ) {
      setList([listOfSelectors[0], listOfSelectors[2]])
      setValue(listOfSelectors[0].key)
    }

    if (oneOf(POA.ARAG_UNCERTAIN, POA.ARAG_UNCERTAIN_500)) {
      setList([listOfSelectors[0], listOfSelectors[1]])
      setValue(listOfSelectors[0].key)
    }

    if (oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B)) {
      setList([listOfSelectors[2], listOfSelectors[0]])
      setValue(listOfSelectors[2].key)
      return
    }

    if (oneOf(POA.PENDING_ARAG, POA.PENDING_VER_SO_ARAG)) {
      setList([listOfSelectors[1], listOfSelectors[0]])
      setValue(listOfSelectors[1].key)
      return
    }

    if (
      oneOf(
        POA.ALCOHOL_400,
        POA.ALCOHOL_500,
        POA.ALCOHOL_1000,
        POA.NO_LCI_400_COURT,
        POA.NO_LCI_DISCOUNTED,
        POA.RA_250,
        POA.RA_300,
        POA.RA_400,
        POA.RA_500,
        POA.RA_700,
        POA.RA_1000,
      )
    ) {
      setList([listOfSelectors[0]])
      setValue(listOfSelectors[0].key)
    }
  }, [])

  useEffect(() => {
    if (value) {
      const updatedValueIndex = list.map(({ key }) => key).indexOf(value)
      setSelectedValue(getValueByIndex(updatedValueIndex))
    }
    if (prevValue) {
      dispatch(setFormKey(FORM.IS_SWITCHED, !isSwitched))
    }
  }, [value])

  useEffect(() => {
    if (selectedValue >= 0) {
      onUpdateSelector(list[getIndexByValue(selectedValue)].key)
    }

    switch (selectedValue) {
      case '1':
        dispatch(setButtonNotInsuredClassname())
        dispatch(setUiKey(UI.CTA_BUTTON_TEXT, 'app.button.button_submit_give_us'))
        break
      case '2':
      case '3':
        dispatch(setFormKey(FORM.REMUNERATION_AGREEMENT, INITIAL_FORM_STATE[FORM.REMUNERATION_AGREEMENT]))
        dispatch(setButtonInsuredClassname())
        if (window['useAlternateCta']) {
          dispatch(setUiKey(UI.CTA_BUTTON_TEXT, 'app.button.button_submit_assign_us'))
        }
        break
      default:
        dispatch(setButtonInsuredClassname())
    }
  }, [selectedValue])

  useEffect(() => {
    if (remunerationAgreement !== boardOneState) {
      dispatch(setFormKey(FORM.REMUNERATION_AGREEMENT, boardOneState))
    }
  }, [boardOneState])

  useEffect(() => {
    if (agreedToLawInsuranceRequest !== boardTwoState) {
      dispatch(setFormKey(FORM.AGREED_TO_LAW_INSURANCE_REQUEST, boardTwoState))
    }
  }, [boardTwoState])

  useEffect(() => {
    if (agreedToLawInsuranceRequest !== boardThreeState) {
      dispatch(setFormKey(FORM.AGREED_TO_LAW_INSURANCE_REQUEST, boardThreeState))
    }
  }, [boardThreeState])

  const oneOf = (...types) => {
    return includes(types, poaType)
  }

  const onUpdateSelector = (selector) => {
    const list0Key = listOfSelectors[0] && listOfSelectors[0].key
    if (selector === list0Key) {
      dispatch(setFormKey(FORM.REMUNERATION_AGREEMENT, boardOneState))
      dispatch(
        setUiKey(UI.LIST_OF_MANDATORY_CHECKBOXES, [
          FORM.POWER_OF_ATTORNEY_AGREEMENT,
          FORM.SIGNATURE,
          FORM.REMUNERATION_AGREEMENT,
        ]),
      )
    }
    const list1Key = listOfSelectors[1] && listOfSelectors[1].key
    if (selector === list1Key) {
      dispatch(setFormKey(FORM.AGREED_TO_LAW_INSURANCE_REQUEST, boardTwoState))
      dispatch(
        setUiKey(UI.LIST_OF_MANDATORY_CHECKBOXES, [
          FORM.POWER_OF_ATTORNEY_AGREEMENT,
          FORM.SIGNATURE,
          FORM.AGREED_TO_LAW_INSURANCE_REQUEST,
        ]),
      )
    }
    const list2Key = listOfSelectors[2] && listOfSelectors[2].key
    if (selector === list2Key) {
      dispatch(setFormKey(FORM.AGREED_TO_LAW_INSURANCE_REQUEST, boardThreeState))
      dispatch(
        setUiKey(UI.LIST_OF_MANDATORY_CHECKBOXES, [
          FORM.POWER_OF_ATTORNEY_AGREEMENT,
          FORM.SIGNATURE,
          FORM.AGREED_TO_LAW_INSURANCE_REQUEST,
        ]),
      )
    }
    setValue(selector)
  }

  const onFocusHandler = (key) => {
    toggleFocused(key)
  }

  const onBlurHandler = () => {
    toggleFocused(-1)
  }

  const onKeyPressHandler = (e, key) => {
    if (e.key === 'Enter') {
      setSelectedValue(key)
    }
  }

  const onClickHandler = (e, key) => {
    e.preventDefault()
    e.stopPropagation()
    toggleFocused(-1)
    setSelectedValue(key)
  }

  const getIndexByValue = (value) => list.findIndex((s) => s.key === value)

  const getValueByIndex = (index) => list[index].key

  const isSingleItemInList = (list) => list.length <= 1

  const renderItemOneContent = (poaType) => {
    switch (poaType) {
      case POA.NO_LCI_400_COURT:
      case POA.ALCOHOL_1000:
      case POA.RA_1000:
        return (
          <Trans
            i18nKey={getValueBasedOnBuyer(
              buyer,
              'dataGather.costSectionOneNoLCI400Ruff',
              'dataGather.costSectionOneNoLCI400Rightmart',
              'dataGather.costSectionOneNoLCI400',
            )}
          >
            A
            <button data-tag="link-bold" onClick={openCompensationAgreementModal}>
              L1
            </button>
            B
          </Trans>
        )
      default:
        return (
          <Trans
            i18nKey={getValueBasedOnBuyer(
              buyer,
              'dataGather.costSectionOneRuff',
              'dataGather.costSectionOneRightmart',
              'dataGather.costSectionOne',
            )}
          >
            A
            <button data-tag="link-bold" onClick={openCompensationAgreementModal}>
              L1
            </button>
            B
          </Trans>
        )
    }
  }

  const getTotal = () => {
    return oneOf(POA.WITH_LCI, POA.WITH_LCI_A, POA.WITH_LCI_B, POA.PENDING_ARAG, POA.PENDING_VER_SO_ARAG) &&
      variation === 'b'
      ? switchTotal
      : total
  }

  const selectorSwitchClassNames = (key) => {
    switch (key) {
      case '1':
        return 'insuranceToggle'
      case '2':
      case '3':
        return undefined
      default:
        return undefined
    }
  }

  return (
    <CostCoverageSelectorWrapper>
      <CostCoverageSelectorContent>
        <CostCoverageSelectorSwitch visible={!isSingleItemInList(list)}>
          {list.map(({ key, label }) => (
            <CostCoverageSelectorSwitchItem
              key={key}
              focused={key === focused}
              selected={key === selectedValue}
              tabIndex={-1}
              className={selectorSwitchClassNames(key)}
              onFocus={() => onFocusHandler(key)}
              onBlur={onBlurHandler}
              onKeyPress={(e) => onKeyPressHandler(e, key)}
              onMouseDown={(e) => onClickHandler(e, key)}
            >
              <CostCoverageSelectorSwitchRadio>
                <InputRadio
                  label=""
                  name={key}
                  value={key}
                  checked={key === selectedValue}
                  size="xs"
                  tabIndex={0}
                  onChange={() => {}}
                />
              </CostCoverageSelectorSwitchRadio>
              <CostCoverageSelectorSwitchText dangerouslySetInnerHTML={{ __html: label }} />
            </CostCoverageSelectorSwitchItem>
          ))}
        </CostCoverageSelectorSwitch>
        <CostCoverageSelectorBoard fullWidth={isSingleItemInList(list)}>
          <BlueBorderedBox>
            {selectedValue === listOfSelectors[0].key && (
              <Fragment>
                <CostCoverageSelectorTableText>{renderItemOneContent(poaType)}</CostCoverageSelectorTableText>
                <CostCoverageSelectorPrice>
                  <PriceLineText>{t('dataGather.constSectionOnePrice')}</PriceLineText>
                  <PriceLineText>{getPrice(getTotal(total))}</PriceLineText>
                </CostCoverageSelectorPrice>
                <CostCoverageSelectorCheckbox>
                  <InputCheckbox
                    ref={checkboxRefs.remunerationAgreementRef}
                    value={boardOneState}
                    name={FORM.REMUNERATION_AGREEMENT}
                    onChange={({ value }) => setBoardOneState(value)}
                    hasError={isUpdateStepFailed && !boardOneState}
                  >
                    <Trans i18nKey="dataGather.constSectionOneCheckbox">
                      A
                      <button
                        data-tag="link-bold"
                        tabIndex={0}
                        onClick={openCompensationAgreementLongModal}
                        onKeyPress={openCompensationAgreementLongModal}
                      >
                        L1
                      </button>
                      C
                    </Trans>
                  </InputCheckbox>
                </CostCoverageSelectorCheckbox>
              </Fragment>
            )}
            {selectedValue === listOfSelectors[1].key && (
              <Fragment>
                <CostCoverageLabel />
                <CostCoverageSelectorBoardLine />
                <CostCoverageSelectorCheckbox>
                  <InputCheckbox
                    value={boardTwoState}
                    ref={checkboxRefs.agreedToLawInsuranceRequestRef}
                    name={FORM.AGREED_TO_LAW_INSURANCE_REQUEST}
                    id={FORM.AGREED_TO_LAW_INSURANCE_REQUEST}
                    label={getValueBasedOnBuyer(
                      buyer,
                      t('dataGather.agreedToLawInsuranceRequestRuff'),
                      t('dataGather.agreedToLawInsuranceRequestRightmart'),
                      t('dataGather.agreedToLawInsuranceRequest'),
                    )}
                    hasError={isUpdateStepFailed && !validationResult[FORM.AGREED_TO_LAW_INSURANCE_REQUEST]}
                    onChange={({ value }) => setBoardTwoState(value)}
                  />
                </CostCoverageSelectorCheckbox>
              </Fragment>
            )}
            {selectedValue === listOfSelectors[2].key && (
              <Fragment>
                <CostCoverageLabel />
                <CostCoverageSelectorBoardLine />
                <CostCoverageSelectorCheckbox>
                  <InputCheckbox
                    value={boardThreeState}
                    ref={checkboxRefs.agreedToLawInsuranceRequestRef}
                    name={FORM.AGREED_TO_LAW_INSURANCE_REQUEST}
                    id={FORM.AGREED_TO_LAW_INSURANCE_REQUEST}
                    label={getValueBasedOnBuyer(
                      buyer,
                      t('dataGather.agreedToLawInsuranceRequestRuff'),
                      t('dataGather.agreedToLawInsuranceRequestRightmart'),
                      t('dataGather.agreedToLawInsuranceRequest'),
                    )}
                    hasError={isUpdateStepFailed && !validationResult[FORM.AGREED_TO_LAW_INSURANCE_REQUEST]}
                    onChange={({ value }) => setBoardThreeState(value)}
                  />
                </CostCoverageSelectorCheckbox>
              </Fragment>
            )}
          </BlueBorderedBox>
        </CostCoverageSelectorBoard>
      </CostCoverageSelectorContent>
    </CostCoverageSelectorWrapper>
  )
}

export default CostCoverageSelector
