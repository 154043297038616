import styled, { css } from 'styled-components'
import { COLORS, MEDIA_QUERIES } from '@legal-one/leadfunnel-ui-kit'

export const SidebarWrapper = styled.aside`
  margin: 0;
  padding: 0;
  background-color: ${COLORS.WHITE_1};
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`

export const SidebarTitle = styled.h5`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1;
  color: ${COLORS.BLUE_4};
`

export const SidebarSpace = styled.div`
  padding: 0;
  ${({ offsetX }) =>
    offsetX &&
    css`
      margin-left: 22px;
      margin-right: 22px;
      @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_2}) {
        margin-left: 26px;
        margin-right: 26px;
      }
      @media screen and (min-width: ${MEDIA_QUERIES.PC_1}) {
        margin-left: 30px;
        margin-right: 30px;
      }
    `}
  ${({ offsetY }) =>
    offsetY &&
    css`
      margin-top: 22px;
      margin-bottom: 22px;
      @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_2}) {
        margin-top: 26px;
        margin-bottom: 26px;
      }
      @media screen and (min-width: ${MEDIA_QUERIES.PC_1}) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    `}
`

export const SidebarButtonWrapper = styled.div`
  margin: 30px 0;
  // Add specific styles for sidebar button to extend large preset
  button[data-anchor='button'] {
    padding: 10px 8px;
    span[data-anchor='button-text'] {
      font-size: 19px;
    }
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_2}) {
    margin-top: 32px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_2}) {
    margin-top: 36px;
    button[data-anchor='button'] {
      padding: 14px;
      span[data-anchor='button-text'] {
        font-size: 20px;
      }
    }
  }
`
