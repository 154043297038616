import styled, { css } from 'styled-components'
import { PriceLine } from '../../../../../../../styles/common.styles'
import { MEDIA_QUERIES, COLORS } from '@legal-one/leadfunnel-ui-kit'

export const CostCoverageSelectorWrapper = styled.div``

export const CostCoverageSelectorTableText = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: ${COLORS.BLACK_1};
  hyphens: manual;
`

export const CostCoverageSelectorPrice = styled(PriceLine)`
  margin: 20px 0 10px 0;
`

export const CostCoverageSelectorContent = styled.section`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    flex-direction: row;
  }
`

export const CostCoverageSelectorBoard = styled.div`
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    margin-left: 8px;
    ${({ fullWidth }) =>
      fullWidth &&
      css`
        margin-left: 0;
        flex-basis: 100%;
      `}
  }
`

export const CostCoverageSelectorBoardLine = styled.hr`
  margin: 12px 0 4px;
  width: 100%;
  border: 1px solid ${COLORS.WHITE_5};
`

export const CostCoverageSelectorSwitch = styled.div`
  margin: 0 0 12px 0;
  padding: 0;
  display: none;
  flex-basis: 40%;
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    margin-bottom: 0;
    margin-right: 8px;
  }
  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `}
`

export const CostCoverageSelectorSwitchItem = styled.div`
  padding: 8px 5px;
  min-height: 38px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: ${COLORS.GREY_11};
  :focus {
    outline: none;
  }
  :hover {
    cursor: pointer;
  }
  :not(:last-of-type) {
    margin-bottom: 12px;
  }
  ${({ focused }) =>
    focused &&
    css`
      border-color: ${COLORS.ORANGE_1};
      outline: none;
      @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
      }
    `}
  ${({ selected, focused }) =>
    selected &&
    css`
      position: relative;
      background-color: ${COLORS.GREEN_1};
      :hover {
        cursor: default;
      }
      @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
        :before {
          display: block;
          position: absolute;
          z-index: 2;
          right: -8px;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid ${COLORS.GREEN_1};
        }
        ${focused &&
        css`
          :after {
            display: block;
            position: absolute;
            z-index: 1;
            right: -12px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid ${COLORS.ORANGE_1};
          }
        `}
      }
    `}
`

export const CostCoverageSelectorSwitchRadio = styled.div`
  position: relative;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
`

export const CostCoverageSelectorSwitchText = styled.p`
  margin: 0;
  padding: 0 4px 0 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: ${COLORS.WHITE_1};
`

export const CostCoverageSelectorCheckbox = styled.div`
  > * {
    padding-bottom: 0;
  }
`
