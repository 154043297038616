import { HTTPS_INIT, HTTPS, HTTPS_MULTIPART } from './instances'
import path from './path'
import { normalizeAxiosResponse } from './normalize'

export const getInit = async () => {
  const response = await HTTPS_INIT.get(path.root())
  return normalizeAxiosResponse(response)
}

export const getAuthenticate = async (secret) => {
  const response = await HTTPS.get(path.authenticate(secret))
  return normalizeAxiosResponse(response)
}

export const postSign = async (data) => {
  const response = await HTTPS.post(path.sign(), data)
  return normalizeAxiosResponse(response)
}

export const postSignRemuneration = async (data) => {
  const response = await HTTPS.post(path.signRemuneration(), data)
  return normalizeAxiosResponse(response)
}

export const getSignature = async (firstName, lastName) => {
  const response = await HTTPS.get(path.signature(firstName, lastName))
  return normalizeAxiosResponse(response)
}

export const postFirstView = async (url, secret) => {
  const response = await HTTPS.post(path.firstView(), {
    url,
    secret,
  })
  return normalizeAxiosResponse(response)
}

export const postInsuranceInformation = async ({ secret, insuranceName, insuranceNumber }) => {
  const response = await HTTPS.post(path.insuranceInformation(), {
    secret,
    insuranceName,
    insuranceNumber,
  })
  return normalizeAxiosResponse(response)
}

export const postFiles = async (files) => {
  const response = await HTTPS_MULTIPART.post(path.files(), files)
  if (response.status !== 200) {
    const { status, data } = response
    const form = Array.from(files.keys())
      .filter((i) => i.includes('file') || i === 'secret' || i === 'uploadUuid')
      .reduce(
        (result, i) => {
          if (i.includes('file')) result.files.push(files.get(i).name + ' - ' + files.get(i).size + ' bytes')
          if (i === 'secret') result.secret = files.get(i)
          if (i === 'uploadUuid') result.uploadUuid = files.get(i)
          return result
        },
        { files: [], secret: '', uploadUuid: '' },
      )
    const printError = ({ files, secret, uploadUuid }) =>
      `Files: [${files.map((f) => f)}], secret: ${secret}, uploadUuid: ${uploadUuid}`

    throw new Error('Status: ' + status + '. Message: ' + JSON.stringify(data) + '. Data: ' + printError(form))
  }
  return normalizeAxiosResponse(response)
}

export const postClientSurvey = async ({
  secret,
  waiverOnDrivingBanImportant,
  carOwner,
  carDriver,
  relationshipOwnerDriver,
  additionalLegalInsurance,
  additionalInformation,
  chargeDate,
}) => {
  const response = await HTTPS.post(path.clientSurvey(), {
    secret,
    waiverOnDrivingBanImportant,
    carOwner,
    carDriver,
    relationshipOwnerDriver,
    additionalLegalInsurance,
    additionalInformation,
    chargeDate,
  })
  return normalizeAxiosResponse(response)
}

export const sendEmail = async (payload) => {
  const response = await HTTPS.post(path.sendEmail(), payload)
  return normalizeAxiosResponse(response)
}
export const trackAlternateCta = async (secret, payload) => {
  const response = await HTTPS.post(path.trackAlternateCta(secret), payload)
  return normalizeAxiosResponse(response)
}

export const trackAttachmentReminder = async (secret) => {
  const response = await HTTPS.post(path.trackAttachmentReminder(secret))
  return normalizeAxiosResponse(response)
}

export const postMarketingCase = async (caseIdentifier, payload) => {
  const response = await HTTPS.post(path.postMarketingCase(caseIdentifier), payload)
  return normalizeAxiosResponse(response)
}
