export const FORM = {
  REMUNERATION_AGREEMENT: 'remunerationAgreement',
  IS_SWITCHED: 'isSwitched',
  SALUTATION: 'salutation',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  BIRTH_NAME: 'birthName',
  BIRTH_DATE: 'birthDate',
  BIRTH_CITY: 'birthCity',
  STREET: 'street',
  HOUSE_NUMBER: 'houseNumber',
  POST_CODE: 'postCode',
  CITY: 'city',
  SIGNATURE: 'signature',
  POWER_OF_ATTORNEY_AGREEMENT: 'powerOfAttorneyAgreement',
  AGREED_TO_LAW_INSURANCE_REQUEST: 'agreedToLawInsuranceRequest',
  DROP_REVOCATION_AGREEMENT: 'dropRevocationAgreement',
  IS_BIRTHDATE_EMPTY: 'isBirthDateEmpty'
}
