import en from '../translations/en.json'
import de from '../translations/de.json'

const localizations = () => ({
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
})

export default localizations
