export const POA = {
  WITH_LCI: 'insurance',
  WITH_LCI_A: 'insuranceA',
  WITH_LCI_B: 'insuranceB',
  NO_LCI: 'noInsurance',
  NO_LCI_A: 'noInsuranceA',
  NO_LCI_B: 'noInsuranceB',
  NO_LCI_500: 'noInsurance500',
  NO_LCI_DISCOUNTED: 'noInsuranceDiscounted',
  LCI_UNCERTAIN: 'insuranceMaybe',
  LCI_UNCERTAIN_500: 'insuranceMaybe500',
  LCI_UNCERTAIN_500_AUTOMATIC: 'insuranceUncertain500Automatic',
  ALCOHOL_400: 'alcohol400',
  ALCOHOL_500: 'alcohol500',
  ALCOHOL_1000: 'alcohol1000',
  PENDING_ARAG: 'insuranceArag',
  PENDING_VER_SO_ARAG: 'insuranceAragVerso',
  ARAG_UNCERTAIN: 'insuranceAragUncertain',
  ARAG_UNCERTAIN_500: 'insuranceAragUncertain500',
  NO_LCI_400_COURT: 'withoutRsv400Court',
  RA_250: 'RA250',
  RA_300: 'RA300',
  RA_400: 'RA400',
  RA_500: 'RA500',
  RA_700: 'RA700',
  RA_1000: 'RA1000',
  DEUTSCHE_DIREKT_UNCERTAIN_500: 'insuranceUncertain500DeutscheDirektVersicherung',
}
