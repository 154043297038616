export const AUTH = {
  NODE_ENV: 'nodeEnv',
  API_URL: 'apiUrl',
  UNIFIED_API_URL: 'unifiedApiUrl',
  CASE_SIGNED: 'CASE_SIGNED',
  ALLOWED_PATH: 'allowedPath',
  ATTACHMENT_SECTIONS: 'attachmentSections',
  CASE_GROUP_LAWYER_NAMES: 'caseGroupLawyerNames',
  CHARGE: 'charge',
  FIRST_VIEW_URL: 'firstViewUrl',
  FULL_NAME: 'fullName',
  REFERENCE: 'reference',
  TOTAL: 'total',
  SWITCH_TOTAL: 'switchTotal',
  SWITCH_POA_TYPE: 'switchPoaType',
  UPLOAD_UUID: 'uploadUuid',
  HAS_INSURANCE: 'hasInsurance',
  INSURANCE_TYPE: 'insuranceType',
  LATEST_SENT_POA_TYPE: 'latestSentPoaType',
  SHOW_SWITCH: 'showSwitch',
  BUYER: 'buyer',
}
