import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class Button extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      label,
      containerClassName,
      type = 'default',
      ...props
    } = this.props

    return (
      <div className={cx('button-container', containerClassName)}>
        <button className={cx('button', {
          'primary': type === 'primary',
          'secondary': type === 'secondary',
          'success': type === 'success',
          'default': type === 'default',
        })} {...props}>{label}</button>
      </div>
    )
  }
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'success',
    'secondary',
  ]),
}

export default Button
