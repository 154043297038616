import styled from 'styled-components'
import { COLORS } from '@legal-one/leadfunnel-ui-kit'

export const SidebarStepTitleWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
`

export const SidebarStepTitleText = styled.h6`
  margin: 0 6px 0 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.5;
  color: ${COLORS.BLUE_4};
  white-space: nowrap;
`

export const SidebarStepTitleIcon = styled.div`
  margin: 4px 0 0 6px;
  padding: 0;
`
