import { CLOSE_MODAL, RESET_MODAL, SHOW_MODAL } from './modal.types'

const INITIAL_STATE = {
  isOpen: false,
  actions: [],
  title: '',
  titleTypeHtml: false,
  message: null,
  messageTypeHtml: false,
  type: 'default',
  id: null,
  showCookieConsent: null,
}

const modalReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        isOpen: true,
        actions: payload.actions,
        title: payload.title,
        titleTypeHtml: payload.titleTypeHtml,
        message: payload.message,
        messageTypeHtml: payload.messageTypeHtml,
        type: payload.modalType,
        id: payload.id ? payload.id : null,
        showCookieConsent: payload.showCookieConsent ? payload.showCookieConsent : null,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
      }
    case RESET_MODAL:
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export default modalReducer
