import { ADD_UPLOADED_FILE_TO_ATTACHMENT_SECTION, SET_AUTH, SET_AUTH_KEY } from './auth.types'
import { AUTH } from './auth.keys'
import { updateModuleKeyWithExpectedKey, updateModuleWithExpectedKeys } from '../../utils/redux'

export const INITIAL_AUTH_STATE = {
  [AUTH.NODE_ENV]: '',
  [AUTH.API_URL]: '',
  [AUTH.UNIFIED_API_URL]: '',
  [AUTH.CASE_SIGNED]: false,
  [AUTH.ALLOWED_PATH]: '',
  [AUTH.ATTACHMENT_SECTIONS]: [],
  [AUTH.CASE_GROUP_LAWYER_NAMES]: '',
  [AUTH.CHARGE]: '',
  [AUTH.FIRST_VIEW_URL]: '',
  [AUTH.FULL_NAME]: '',
  [AUTH.REFERENCE]: '',
  [AUTH.TOTAL]: 0,
  [AUTH.SWITCH_TOTAL]: 0,
  [AUTH.SWITCH_POA_TYPE]: '',
  [AUTH.UPLOAD_UUID]: '',
  [AUTH.HAS_INSURANCE]: false,
  [AUTH.INSURANCE_TYPE]: '',
  [AUTH.LATEST_SENT_POA_TYPE]: '',
  [AUTH.SHOW_SWITCH]: false,
  [AUTH.BUYER]: '',
}

export const AuthReducer = (state = INITIAL_AUTH_STATE, { type, payload }) => {
  switch (type) {
    case SET_AUTH:
      return updateModuleWithExpectedKeys(state, INITIAL_AUTH_STATE, payload)
    case SET_AUTH_KEY:
      return updateModuleKeyWithExpectedKey(state, INITIAL_AUTH_STATE, payload)
    case ADD_UPLOADED_FILE_TO_ATTACHMENT_SECTION:
      const copy = state.attachmentSections ? [...state.attachmentSections] : state.attachmentSections
      const filesIndex = copy.findIndex((c) => c.name === 'files')
      if (filesIndex === -1) return state
      copy[filesIndex] = {
        ...copy[filesIndex],
        data: {
          uploadsCount: copy[filesIndex].data.uploadsCount + 1,
          documentType: [...copy[filesIndex].data.documentType, payload],
        },
      }
      return {
        ...state,
        attachmentSections: [...copy],
      }
    default:
      return state
  }
}
