const AuthoritiesModal = () => (
    <ul>
      <li>Einlegung von Rechtsbehelfen und Rechtsmitteln, sowie deren Verzicht, Beschränkung und Rücknahme</li>
      <li>Vertretung i.S.v. §§ 73 Abs. 2, 73 Abs. 3 OWiG, § 411 Abs. 2 StPO und ausdrückliche Ermächtigung nach §§ 233 Abs. 1, 234 StPO</li>
      <li>Erteilung von Untervollmacht/Terminsvollmacht</li>
      <li>Empfangnahme von Zustellungen</li>
      <li>Stellung von Kostenfestsetzungsanträgen nach §§ 464 ff. StPO</li>
      <li>Geldempfangsvollmacht</li>
      <li>Akteneinsicht</li>
      <li>Einholung von Auskünften aus dem Fahreignungsregister</li>
    </ul>
)

export default AuthoritiesModal