import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import loadLocalizations from './load-localizations'

const DEFAULT_LOCALIZATION = 'de'

i18n.use(initReactI18next).init({
	resources: loadLocalizations(),
	lng: DEFAULT_LOCALIZATION,
	fallbackLng: DEFAULT_LOCALIZATION,
	interpolation: {
		escapeValue: false,
	},
})
