import * as React from 'react'

function SvgRadioChecked({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <circle stroke="#E3E3E3" strokeWidth={2} fill="#FFF" cx={10} cy={10} r={8} />
        <circle fill="currentColor" cx={10} cy={10} r={5} />
      </g>
    </svg>
  )
}

export default SvgRadioChecked
