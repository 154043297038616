import styled, { css } from 'styled-components'
import { COLORS, MEDIA_QUERIES } from '@legal-one/leadfunnel-ui-kit'

export const MainWrapper = styled.main`
  margin: 0;
  padding-bottom: 30px;
  background-color: ${COLORS.WHITE_1};
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    border-radius: 4px;
  }
`

export const MainTitle = styled.h1`
  margin: 0;
  padding: 20px 16px;
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.3;
  color: ${COLORS.BLUE_4};
  @media screen and (min-width: ${MEDIA_QUERIES.PHONE_1}) {
    padding: 20px 24px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    padding: 22px 30px 22px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    padding: 24px 22px 12px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_2}) {
    padding-left: 26px;
    padding-right: 26px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.PC_1}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const MainInfoBox = styled.div`
  margin: 0;
  padding: 0;
  display: block;
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    display: none;
  }
`

export const MainForm = styled.div`
  margin: 0;
  padding: 22px 16px 30px;
  ${({ hasNoBottomPadding }) =>
    hasNoBottomPadding &&
    css`
      padding-bottom: 0;
    `}}
  @media screen and (min-width: ${MEDIA_QUERIES.PHONE_1}) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    padding-top: 12px;
    padding-left: 22px;
    padding-right: 22px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_2}) {
    padding-left: 26px;
    padding-right: 26px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.PC_1}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const MainButtons = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`

export const MainButton = styled.div`
  margin: 0 4px;
  padding: 0;
`

export const MainStepTitle = styled.h3`
  margin: 0 0 16px 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: ${COLORS.BLUE_4};
`
