import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectFormKey } from '../redux/form/form.selectors'
import { FORM } from '../redux/form/form.keys'
import { UI } from '../redux/ui/ui.keys'
import { selectUiKey } from '../redux/ui/ui.selectors'
import { getSignature } from '../axios/requests'

const useSignature = () => {
  const [signature, setSignature] = useState('')

  const firstName = useSelector(selectFormKey(FORM.FIRST_NAME))
  const lastName = useSelector(selectFormKey(FORM.LAST_NAME))
  const signatureInStore = useSelector(selectFormKey(FORM.SIGNATURE))
  const isUpdateStepFailed = useSelector(selectUiKey(UI.IS_UPDATE_STEP_FAILED))
  const validationResult = useSelector(selectUiKey(UI.VALIDATION_RESULTS))

  useEffect(() => {
    const fetchSignature = async (firstName, lastName) => {
      const { status, data } = await getSignature(firstName, lastName)
      if (status === 200) {
        setSignature(data.raw)
      }
    }
    if (firstName && lastName) {
      fetchSignature(firstName, lastName)
    }
  }, [])

  const showSuccess = () => Boolean(signatureInStore)

  const showError = () => {
    return isUpdateStepFailed && !validationResult.signature
  }

  return { signature, showSignatureSuccess: showSuccess(), showSignatureError: showError() }
}

export default useSignature
