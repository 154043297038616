import * as React from 'react'

function SvgCheckLogo({ title, titleId, ...props }) {
  return (
    <svg
      viewBox="0 0 51 48"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="#2ECC71" fillRule="evenodd">
        <path d="M22.643.102c2.235-.155 5.104.014 7.446.612 1.787.455 4.82 1.05 4.896 3.162.038 1.074-.666 1.906-1.428 2.142-1.133.35-2.35-.365-3.366-.714-3.135-1.08-7.265-1.443-11.016-.51-4.415 1.098-7.749 3.457-10.301 6.63-2.696 3.35-4.643 7.154-4.59 12.443.096 9.758 6.734 17.1 14.891 19.074 4.397 1.064 10.242.17 14.076-1.836 5.44-2.848 9.452-7.67 10.2-15.3.211-2.161-.65-6.193 2.346-6.12 1.83.045 2.234 1.6 2.141 4.284-.206 5.968-2.185 10.583-4.895 14.178-3.552 4.711-8.772 8.477-16.014 9.384-8.175 1.023-14.578-2.021-19.073-5.916C3.313 37.592.149 31.899.102 24.07.039 13.68 5.874 6.224 13.362 2.55c2.67-1.311 5.709-2.2 9.281-2.448" />
        <path d="M50.08 3.774v1.02c-1.801 2.385-4.054 4.462-6.221 6.63-5.436 5.435-10.81 10.708-16.218 16.115-1.02 1.02-2.202 2.856-3.672 2.856-1.693 0-3.559-2.64-4.794-3.876l-2.142-2.142c-.69-.69-1.716-1.538-1.734-2.652-.024-1.54 1.203-2.278 2.244-2.244 1.348.044 2.364 1.548 3.366 2.55 1.086 1.086 2.1 2.167 2.958 3.06C30.52 18.772 37.746 11.315 44.471 4.59c.97-.971 2.083-2.47 3.467-2.448 1.248.02 1.662.676 2.142 1.632" />
      </g>
    </svg>
  )
}

export default SvgCheckLogo
