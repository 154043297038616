import * as React from 'react'
function SvgPlus({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6.8 5.2H12v1.6H6.8V12H5.2V6.8L0 6.823V5.2l5.2-.023V0h1.6v5.2z" fill="#FF8D02" />
    </svg>
  )
}
export default SvgPlus
