import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Step3v1 from './_parts/step-3-v1/step-3-v1.component'
import Step3v2 from './_parts/step-3-v2/step-3-v2.component'
import { selectUiKey } from '../../../redux/ui/ui.selectors'
import { UI } from '../../../redux/ui/ui.keys'
import { setUiKey } from '../../../redux/ui/ui.actions'

const Step3 = () => {
  const powerOfAttorneyAgreementRef = useRef(null)
  const agreedToLawInsuranceRequestRef = useRef(null)
  const remunerationAgreementRef = useRef(null)

  const variation = useSelector(selectUiKey(UI.VARIATION))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setUiKey(UI.CHECKBOX_REFS, {
        powerOfAttorneyAgreementRef,
        agreedToLawInsuranceRequestRef,
        remunerationAgreementRef,
      }),
    )
  }, [])

  switch (variation) {
    case 'a':
      return <Step3v2 />
    case 'b':
      return <Step3v1 />
    case 'c':
      return <Step3v2 />
    default:
      return <Step3v2 />
  }
}

Step3.disabledName = 'Step3'

export default Step3
