import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAuthKey } from '../redux/auth/auth.selectors'
import { selectFormKey } from '../redux/form/form.selectors'
import { ALLOWED_PATH } from '../constants/allowed-path'
import { AUTH } from '../redux/auth/auth.keys'
import { FORM } from '../redux/form/form.keys'

const useRemuneration = () => {
  const [initBirthDay, setInitBirthDay] = useState('')

  const allowedPath = useSelector(selectAuthKey(AUTH.ALLOWED_PATH))
  const birthDate = useSelector(selectFormKey(FORM.BIRTH_DATE))

  const { t } = useTranslation()

  useEffect(() => {
    setInitBirthDay(birthDate)
  }, [birthDate])

  const isRemunerationPage = () => allowedPath === ALLOWED_PATH.REMUNERATION

  const isRemunerationComplete = () => isRemunerationPage() && initBirthDay !== ''

  const remunerationTitle = () => {
    return isRemunerationComplete()
      ? `1. ${t('app.title.section_remuneration')}`
      : `3. ${t('app.title.section_remuneration')}`
  }

  return {
    isRemunerationPage: isRemunerationPage(),
    isRemunerationComplete: isRemunerationComplete(),
    remunerationTitle: remunerationTitle(),
  }
}

export default useRemuneration
