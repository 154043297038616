const ImprintModal = () => (
  <>
    <h4>Mathis Ruff Rechtsanwaltsgesellschaft mbH</h4>
    <p>
      <b>Anschrift:</b> Sonnenallee 260/262, 12057 Berlin
      <br />
      <b>E-Mail:</b> kanzlei@sos-verkehrsrecht.de
      <br />
      <b>Telefonnummer:</b> 030 / 20 898 12 67
      <br />
      <b>Faxnummer:</b> 030 / 20 898 12 13
    </p>
    <p>
      Geschäftsführer: Mathis Ruff
      <br />
      Amtsgericht Charlottenburg, HRB 168224 B<br />
      USt-ID: DE300559956
    </p>
    <p>Verantwortlich für den Inhalt: Mathis Ruff.</p>

    <h2>Hinweise zur Berufshaftpflichtversicherung:</h2>
    <h4>Mathis Ruff Rechtsanwaltsgesellschaft mbH</h4>
    <p>
      R+V Allgemeine Versicherung AG, Raiffeisenplatz 1, 65189 Wiesbaden
      <br />
      Summe pro Versicherungsfall: 2.500.000,- Euro
      <br />
      Jahreshöchstleistung: 10.000.000,- Euro
      <br />
      Räumlicher Geltungsbereich: BRD und alle Mitgliedsstaaten der EU
      <br />
      Versicherungsnummer: 406 84 343536526
    </p>
    <p>
      Die Berufsbezeichnung “Rechtsanwalt” wurde in der Bundesrepublik Deutschland verliehen. Die Mathis Ruff
      Rechtsanwaltsgesellschaft ist Mitglied der Rechtsanwaltskammer Berlin (Littenstraße 9, 10179 Berlin). Die für die
      Gesellschaft tätigen Rechtsanwälte sind jeweils Mitglieder der Rechtsanwaltskammer Berlin (
      <a data-tag="link" href="http://www.rak-berlin.de" target="_blank" rel="noreferrer">
        www.rak-berlin.de
      </a>
      ).
    </p>
    <h2>Berufsrechtliche Regelungen</h2>
    <p>Es gelten folgende berufsrechtliche Regelungen:</p>
    <ul>
      <li>Berufsordnung für Rechtsanwälte (BORA)</li>
      <li>Bundesrechtsanwaltsordnung (BRAO)</li>
      <li>Fachanwaltsordnung (FAO)</li>
      <li>Rechtsanwaltsvergütungsgesetz (RVG)</li>
      <li>Berufsregeln der Rechtsanwälte der Europäischen Union (CCBE)</li>
      <li>Gesetz über die Tätigkeit europäischer Rechtsanwälte in Deutschland (EuRAG)</li>
      <li>Anordnung der Bundesrechtsanwaltskammer zum Geldwäschebekämpfungsgesetz</li>
      <li>Berufsbezeichnung: Rechtsanwalt (Bundesrepublik Deutschland)</li>
    </ul>
    <p>
      Den genauen Inhalt der berufsrechtlichen Regelungen für Rechtsanwälte finden Sie auf der Internetseite der
      Bundesrechtsanwaltskammer: www.brak.de unter der Rubrik “Berufsrecht” (
      <a data-tag="link" href="http://www.brak.de/fuer-anwaelte/berufsrecht" target="_blank" rel="noreferrer">
        www.brak.de/fuer-anwaelte/berufsrecht
      </a>
      ).
    </p>
    <h3>Außergerichtliche Streitschlichtung</h3>
    <p>
      Bei Streitigkeiten zwischen Rechtsanwälten und ihren Auftraggebern besteht auf Antrag die Möglichkeit der
      außergerichtlichen Streitschlichtung bei der regionalen Rechtsanwaltskammer (gemäß § 73 Abs. 2 Nr. 3, Abs. 5 BRAO)
      oder bei der Schlichtungsstelle der Rechtsanwaltschaft bei der Bundesrechtsanwaltskammer (§ 191f BRAO), im
      Internet zu finden über die unter{' '}
      <a
        data-tag="link"
        href="https://www.schlichtungsstelle-der-rechtsanwaltschaft.de/"
        target="_blank"
        rel="noreferrer"
      >
        www.schlichtungsstelle-der-rechtsanwaltschaft.de
      </a>{' '}
      abrufbare Homepage.
    </p>
    <p>
      Für den Fall, dass Online Dienstleistungsverträge i.S.d. ODR-Verordnung geschlossen werden, besteht auch die
      Möglichkeit die Onlinestreitbeilegungs-Plattform unter{' '}
      <a data-tag="link" href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">
        http://ec.europa.eu/consumers/odr/
      </a>{' '}
      zu kontaktieren.
    </p>
    <h2>Allgemeine Informationspflicht nach § 36 VSBG</h2>
    <p>
      Für vermögensrechtliche Streitigkeiten aus dem Mandatsverhältnis ist die Schlichtungsstelle der
      Rechtsanwaltschaft, Rauchstraße 26, 10787 Berlin,{' '}
      <a data-tag="link" href="http://www.s-d-r.org" target="_blank" rel="noreferrer">
        www.s-d-r.org
      </a>
      , zuständig. Die Mathis Ruff Rechtsanwaltsgesellschaft mbH ist nicht bereit und verpflichtet, an
      Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <h2>Haftungsausschluss</h2>
    <h3>Inhalt des Onlineangebots:</h3>
    <p>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
      Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
      oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
      allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
      Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
      wir diese Inhalte umgehend entfernen.
    </p>
    <p>
      Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der
      bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder
      ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die
      Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen,
      sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
    </p>
    <h3>Verweise und Links:</h3>
    <p>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
      können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
      der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
      Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
      erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
      umgehend entfernen.
    </p>
    <h3>Urheberrechte:</h3>
    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
      Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
      Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
      dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
      Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
      Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
      wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
      umgehend entfernen.
    </p>
  </>
)

export default ImprintModal
