import React from 'react'
import {
  SecurityLogosTls,
  SecurityLogosTlsSvg,
  SecurityLogosTuevSvg,
  SecurityLogosWrapper,
} from './security-logos.styles'

const SecurityLogos = () => (
  <SecurityLogosWrapper>
    <SecurityLogosTls>
      <SecurityLogosTlsSvg />
      <br />
      Höchste
      <br />
      Datensicherheit.
    </SecurityLogosTls>
    <SecurityLogosTuevSvg />
  </SecurityLogosWrapper>
)

SecurityLogos.displayName = 'SecurityLogos'

export default SecurityLogos
