import React, { Fragment } from 'react'
import { InputCheckbox, SignaturePad, Button } from '@legal-one/leadfunnel-ui-kit'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UI } from '../../../../../redux/ui/ui.keys'
import { selectUiKey } from '../../../../../redux/ui/ui.selectors'
import { AUTH } from '../../../../../redux/auth/auth.keys'
import { selectAuthKey } from '../../../../../redux/auth/auth.selectors'
import { setFormKey } from '../../../../../redux/form/form.actions'
import { FORM } from '../../../../../redux/form/form.keys'
import useGoToAttachment from '../../../../../hooks/useGoToAttachment'
import useModal from '../../../../../hooks/useModal'
import useSignature from '../../../../../hooks/useSignature'
import useUserInfoForm from '../../../../../hooks/useUserInfoForm'
import CostCoverageSelector from '../../../../../components/data-gather/step-3/_parts/step-3-v1/_parts/cost-coverage-selector/cost-coverage-selector.component'
import {
  CheckboxesWrapper,
  CostCoverageSelectorWrapper,
  LogoItemsWrapper,
  SignaturePadWrapper,
  SubmitButton,
  UnderSubmitButtonText,
} from '../../../../../styles/common.styles'
import SecurityLogos from '../../../../security-logos/security-logos.component'
import { InfoUserBoxWrapper } from '../../step-3.styles'
import InfoBoxUserSummary from '../../../../info-box-user-summary/info-box-user-summary.component'
import { getValueBasedOnBuyer } from '../../../../../utils/utils'

const Step3v1 = () => {
  const { userInfoForm, validationResult, isUpdateStepFailed, ctaButtonText, onChange } = useUserInfoForm()

  const { signature, showSignatureSuccess, showSignatureError } = useSignature()

  const goToAttachment = useGoToAttachment(() => {
    const { powerOfAttorneyAgreementRef, agreedToLawInsuranceRequestRef, remunerationAgreementRef } = checkboxRefs
    if (!validationResult.powerOfAttorneyAgreement && powerOfAttorneyAgreementRef.current) {
      return powerOfAttorneyAgreementRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    if (!validationResult.agreedToLawInsuranceRequest && agreedToLawInsuranceRequestRef.current) {
      return agreedToLawInsuranceRequestRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
    if (!validationResult.remunerationAgreement && remunerationAgreementRef.current) {
      return remunerationAgreementRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  })

  const { openAuthoritiesModal, openClientConditionModal, openRevocationModal } = useModal()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const caseGroupLawyerNames = useSelector(selectAuthKey(AUTH.CASE_GROUP_LAWYER_NAMES))
  const isSignInProgress = useSelector(selectUiKey(UI.IS_SIGN_IN_PROGRESS))
  const checkboxRefs = useSelector(selectUiKey(UI.CHECKBOX_REFS))
  const ctaButtonClassNames = useSelector(selectUiKey(UI.MAIN_SUBMIT_BUTTON_CLASSNAMES))
  const buyer = useSelector(selectAuthKey(AUTH.BUYER))

  return (
    <Fragment>
      <InfoUserBoxWrapper>
        <InfoBoxUserSummary isBlack />
      </InfoUserBoxWrapper>
      <CheckboxesWrapper>
        <InputCheckbox
          value={userInfoForm[FORM.POWER_OF_ATTORNEY_AGREEMENT]}
          ref={checkboxRefs.powerOfAttorneyAgreementRef}
          name={FORM.POWER_OF_ATTORNEY_AGREEMENT}
          hasError={isUpdateStepFailed && !validationResult[FORM.POWER_OF_ATTORNEY_AGREEMENT]}
          onChange={onChange}
        >
          <Trans
            i18nKey={getValueBasedOnBuyer(
              buyer,
              'dataGather.powerOfAttorneyAgreementRuff',
              'dataGather.powerOfAttorneyAgreementRightmart',
              'dataGather.powerOfAttorneyAgreement',
            )}
          >
            A{{ caseGroupLawyerNames }}B
            <button data-tag="link-bold" tabIndex={0} onClick={openAuthoritiesModal} onKeyPress={openAuthoritiesModal}>
              L1
            </button>
            C
          </Trans>
        </InputCheckbox>
        <InputCheckbox
          value={userInfoForm[FORM.DROP_REVOCATION_AGREEMENT]}
          name={FORM.DROP_REVOCATION_AGREEMENT}
          label={getValueBasedOnBuyer(
            buyer,
            t('dataGather.dropRevocationAgreementRuff'),
            t('dataGather.dropRevocationAgreementRightmart'),
            t('dataGather.dropRevocationAgreement'),
          )}
          onChange={onChange}
        />
      </CheckboxesWrapper>
      <CostCoverageSelectorWrapper>
        <CostCoverageSelector />
      </CostCoverageSelectorWrapper>
      <SignaturePadWrapper>
        <SignaturePad
          generatedSignature={signature}
          title={t('dataGather.signature_title')}
          imageMode={{
            autogenerate: t('dataGather.signature_signature'),
            goToHandwriteMode: t('dataGather.signature_change_to_mouse'),
          }}
          handMode={{
            back: t('dataGather.signatureBackToMouse'),
            erase: t('dataGather.signatureEraseAll'),
          }}
          showSuccess={showSignatureSuccess}
          showError={showSignatureError}
          onUpdateSignature={(value) => dispatch(setFormKey(FORM.SIGNATURE, value))}
        />
      </SignaturePadWrapper>
      <SubmitButton>
        <Button
          type="button"
          label={t(ctaButtonText)}
          fullWidth
          color="orange"
          size="lg"
          className={ctaButtonClassNames}
          spreadClassNames={ctaButtonClassNames}
          loading={isSignInProgress}
          onClick={goToAttachment}
        />
      </SubmitButton>
      <UnderSubmitButtonText>
        <Trans i18nKey="dataGather.submitSubTitle">
          A
          <button data-tag="link" tabIndex={0} onClick={openClientConditionModal} onKeyPress={openClientConditionModal}>
            L1
          </button>
          B
          <button data-tag="link" tabIndex={0} onClick={openRevocationModal} onKeyPress={openRevocationModal}>
            L2
          </button>
          C
        </Trans>
      </UnderSubmitButtonText>
      <LogoItemsWrapper>
        <SecurityLogos />
      </LogoItemsWrapper>
    </Fragment>
  )
}

Step3v1.displayName = 'Step3v1'

export default Step3v1
