import styled, { css } from 'styled-components'
import { COLORS, MEDIA_QUERIES } from '@legal-one/leadfunnel-ui-kit'
import SvgEditPen from '../../svg/edit-pen'

export const InfoBoxUserSummaryWrapper = styled.div`
  margin: 16px 0 0 0;
  padding: 0;
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${COLORS.BLUE_4};
`

export const InfoBoxUserSummaryInfoLine = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: ${({ isBlack }) => (isBlack ? COLORS.BLACK_1 : COLORS.BLUE_4)};
`

export const InfoBoxUserSummaryTitle = styled.div`
  margin: ${({ isBlack }) => (isBlack ? '0 0 4px 0' : 0)};
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    justify-content: flex-start;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    justify-content: space-between;
  }
`

export const InfoBoxUserSummaryTitleText = styled.h5`
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.BLUE_4};
`

export const InfoBoxUserSummaryTitleEdit = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
  :focus {
    outline-color: ${COLORS.ORANGE_1};
    outline-style: auto;
    outline-offset: 2px;
    border-radius: 4px;
  }
`

export const InfoBoxUserSummaryTitleEditBracket = styled.span`
  display: none;
  font-weight: 300;
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    display: block;
    margin: 0 4px;
    :first-of-type {
      margin-left: 12px;
    }
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    display: none;
    margin: initial;
    :first-of-type {
      margin-left: initial;
    }
  }
`

export const InfoBoxUserSummaryTitleEditIcon = styled(SvgEditPen)`
  position: relative;
  top: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  width: 12px;
  height: 12px;
`

export const InfoBoxUserSummaryTitleEditText = styled.span`
  margin: 0 8px 0 0;
  padding: 0;
  display: block;
`
