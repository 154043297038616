import styled from 'styled-components'
import { COLORS, MEDIA_QUERIES } from '@legal-one/leadfunnel-ui-kit'

export const InfoBoxWrapper = styled.div`
  margin: 0;
  padding: 12px 16px;
  box-shadow: inset 0 9px 8px -10px rgba(0, 0, 0, 0.1), inset 0 -9px 8px -10px rgba(0, 0, 0, 0.1);
  background-color: ${COLORS.WHITE_6};
  @media screen and (min-width: ${MEDIA_QUERIES.PHONE_1}) {
    padding: 16px 24px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    padding: 16px 30px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    padding-left: 22px;
    padding-right: 22px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_2}) {
    padding-left: 26px;
    padding-right: 26px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.PC_1}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

export const InfoBoxContent = styled.div`
  margin: 0;
  padding: 0;
  display: block;
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    display: block;
  }
`

export const InfoBoxContentItem = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  color: ${COLORS.BLUE_4};
  *:first-of-type {
    margin-bottom: 0;
    margin-right: 4px;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.TABLET_1}) {
    flex-basis: 50%;
  }
  @media screen and (min-width: ${MEDIA_QUERIES.LAPTOP_1}) {
    flex-basis: initial;
  }
`

export const InfoBoxItemTitle = styled.h6`
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`

export const InfoBoxItemContent = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`
