export const SET_UI = 'SET_UI'
export const SET_UI_KEY = 'SET_UI_KEY'
export const SET_INIT_REQUEST_START = 'SET_INIT_REQUEST_START'
export const SET_INIT_REQUEST_END_SUCCESS = 'SET_INIT_REQUEST_END_SUCCESS'
export const SET_INIT_REQUEST_END_ERROR = 'SET_INIT_REQUEST_END_ERROR'
export const SET_AUTH_REQUEST_START = 'SET_AUTH_REQUEST_START'
export const SET_AUTH_REQUEST_END_SUCCESS = 'SET_AUTH_REQUEST_END_SUCCESS'
export const SET_AUTH_REQUEST_END_ERROR = 'SET_AUTH_REQUEST_END_ERROR'
export const SET_BUTTON_INSURED_CLASSNAME = 'SET_BUTTON_INSURED_CLASSNAME'
export const SET_BUTTON_NOT_INSURED_CLASSNAME = 'SET_BUTTON_NOT_INSURED_CLASSNAME'
export const RESET_FORM_VALIDATION = 'RESET_FORM_VALIDATION'
