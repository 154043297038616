import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectForm } from '../redux/form/form.selectors'
import { setFormKey } from '../redux/form/form.actions'
import { isDateCorrect, isNotEmptyString } from '../utils/utils'
import { FORM } from '../redux/form/form.keys'
import { UI } from '../redux/ui/ui.keys'
import { selectUiKey } from '../redux/ui/ui.selectors'
import { setUiKey } from '../redux/ui/ui.actions'

const useUserInfoForm = () => {
  const userInfoForm = useSelector(selectForm)
  const validationResult = useSelector(selectUiKey(UI.VALIDATION_RESULTS))
  const isUpdateStepFailed = useSelector(selectUiKey(UI.IS_UPDATE_STEP_FAILED))
  const areFieldsEditable = useSelector(selectUiKey(UI.ARE_FIELDS_EDITABLE))
  const touchedFields = useSelector(selectUiKey(UI.TOUCHED_FIELDS))
  const focusField = useSelector(selectUiKey(UI.FOCUSED_FIELD))
  const ctaButtonText = useSelector(selectUiKey(UI.CTA_BUTTON_TEXT))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setUiKey(UI.VALIDATION_RESULTS, {
        firstName: isNotEmptyString(userInfoForm[FORM.FIRST_NAME]),
        lastName: isNotEmptyString(userInfoForm[FORM.LAST_NAME]),
        birthName: true,
        birthDate: isDateCorrect(userInfoForm[FORM.BIRTH_DATE]),
        birthCity: isNotEmptyString(userInfoForm[FORM.BIRTH_CITY]),
        street: isNotEmptyString(userInfoForm[FORM.STREET]),
        houseNumber: isNotEmptyString(userInfoForm[FORM.HOUSE_NUMBER]),
        postCode: isNotEmptyString(userInfoForm[FORM.POST_CODE]) && userInfoForm[FORM.POST_CODE].length === 5,
        city: isNotEmptyString(userInfoForm[FORM.CITY]),
        signature: isNotEmptyString(userInfoForm[FORM.SIGNATURE]),
        powerOfAttorneyAgreement: userInfoForm[FORM.POWER_OF_ATTORNEY_AGREEMENT],
        agreedToLawInsuranceRequest: userInfoForm[FORM.AGREED_TO_LAW_INSURANCE_REQUEST],
        dropRevocationAgreement: userInfoForm[FORM.DROP_REVOCATION_AGREEMENT],
        remunerationAgreement: userInfoForm[FORM.REMUNERATION_AGREEMENT],
      }),
    )
  }, [userInfoForm])

  const onChange = ({ key, value }) => {
    dispatch(setFormKey(key, value))
  }

  const onFocus = ({ key }) => {
    dispatch(setUiKey(UI.FOCUSED_FIELD, key))
  }

  const onBlur = ({ key }) => {
    dispatch(setUiKey(UI.FOCUSED_FIELD, undefined))
    const uniqueFields = new Set(touchedFields.length ? touchedFields : [])
    uniqueFields.add(key)
    dispatch(setUiKey(UI.TOUCHED_FIELDS, Array.from(uniqueFields)))
  }

  const showSuccess = ({ key, required }) => {
    if (required) {
      return (
        (validationResult[key] && touchedFields.includes(key)) || (validationResult[key] && Boolean(userInfoForm[key]))
      )
    } else {
      return validationResult[key] && Boolean(userInfoForm[key])
    }
  }

  const showError = ({ key, required }) => {
    if (required) {
      return (
        (!validationResult[key] && isUpdateStepFailed) ||
        (touchedFields.includes(key) && !userInfoForm[key]) ||
        (touchedFields.includes(key) && !validationResult[key])
      )
    } else {
      return false
    }
  }

  const showPopup = ({ key, required }) => {
    return showError({ key, required }) && focusField === key
  }

  return {
    userInfoForm,
    validationResult,
    isUpdateStepFailed,
    areFieldsEditable,
    dispatch,
    onChange,
    onFocus,
    onBlur,
    showSuccess,
    showError,
    showPopup,
    ctaButtonText,
  }
}

export default useUserInfoForm
