import React from 'react'
import TinyCollapse from 'react-tiny-collapse'
import { useSelector } from 'react-redux'
import { selectAuthKey } from '../../redux/auth/auth.selectors'
import { AUTH } from '../../redux/auth/auth.keys'
import { selectActiveSection } from '../../redux/user-attachment-ui/user-attachment-ui.selectors'
import { ACTIVE_SECTION } from '../../redux/user-attachment-ui/user.attachment-ui.utils'
import SectionInsurance from './_parts/sections/section-insurance/section-insurance.component'
import SectionClientSurvey from './_parts/sections/section-client-survey/section-client-survey.component'
import { CheckCircleLight } from '../../svg'
import { MainWrapper } from '../../styles/common.styles'
import SectionFileUploadV2 from './_parts/sections/section-file-upload-v2/section-file-upload-v2.component'

const Attachment = () => {
  const attachmentSections = useSelector(selectAuthKey(AUTH.ATTACHMENT_SECTIONS)) || []
  const activeSection = useSelector(selectActiveSection)

  const Sections = () => {
    return attachmentSections.map((section) => {
      switch (section.name) {
        case ACTIVE_SECTION.INSURANCE:
          return (
            <SectionInsurance key={section.index} index={section.index} isActive={activeSection === section.name} />
          )
        case ACTIVE_SECTION.FILES:
          return (
            <SectionFileUploadV2 key={section.index} index={section.index} isActive={activeSection === section.name} />
          )
        case ACTIVE_SECTION.SURVEY:
          return (
            <SectionClientSurvey key={section.index} index={section.index} isActive={activeSection === section.name} />
          )
        default:
          return (
            <SectionInsurance key={section.index} index={section.index} isActive={activeSection === section.name} />
          )
      }
    })
  }

  const showSuccessMessage =
    (activeSection === '' && attachmentSections.length === 3) || // case with insurance form and survey
    (activeSection === 'survey' && attachmentSections.length === 2 && attachmentSections[0].name === 'insurance') || // case without survey
    (activeSection === '' && attachmentSections.length === 2 && attachmentSections[0].name === 'files') || // case with survey but without insurance
    (activeSection === 'survey' && attachmentSections.length === 1 && attachmentSections[0].name === 'files') // case without survey and without insurance

  return (
    <MainWrapper>
      <div className="title-container">
        <h1>Mandat für Bußgeld- und Strafsachen</h1>
        <div className="subtitle">
          <b>Danke für Ihr Vertrauen</b>
          Für eine schnelle Bearbeitung benötigen wir noch folgende Informationen.
        </div>
      </div>
      <Sections />
      <TinyCollapse isOpen={showSuccessMessage}>
        <div className="success-footer">
          <div className="success-footer-icon">
            <CheckCircleLight />
          </div>
          <div className="success-footer-title">Vielen Dank!</div>
          <div>Wir haben Ihre Informationen erhalten und werden diese umgehend bearbeiten.</div>
          <div>Sobald neue Erkentnisse vorliegen, setzen wir uns mit Ihnen in Verbindung.</div>
          <div style={{ marginTop: 12 }}>
            Sobald Sie neue Behördenschreiben erhalten, übermitteln Sie uns diese bitte umgehend.
          </div>
        </div>
      </TinyCollapse>
    </MainWrapper>
  )
}

export default React.memo(Attachment)
