import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { RadioChecked, RadioUnchecked, RadioHover } from '../../../../svg'

class RadioInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isValid: null,
      value: '',
      checkedIndex: -1,
    }
  }

  componentDidMount() {
    const { options } = this.props

    options.forEach((option, index) => {
      if (Object.prototype.hasOwnProperty.call(option, 'defaultValue') && option.defaultValue === true) {
        this.setState({
          checkedIndex: index,
        })
      }
    })
  }

  validate = (fireCallback) => {
    const { validator, onChange } = this.props

    const { value } = this.state

    let isValid = null
    let errorMessage = null

    if (validator) {
      const result = validator(value)
      isValid = result.valid
      errorMessage = result.errorMessage
    }

    this.setState({
      isValid,
      errorMessage,
    })

    const returnObject = {
      value,
      isValid,
    }

    if (fireCallback === true && onChange) {
      onChange(returnObject)
    }

    return returnObject
  }

  setCheckedIndexAndValue = (checkedIndex, value) => {
    this.setState({ checkedIndex, value }, () => {
      const { waitForCallback, nextCallback } = this.props

      const valid = this.validate(true).isValid

      if ((valid === null || valid === true) && waitForCallback === true && nextCallback) {
        nextCallback()
      }
    })
  }

  renderRadioInputItem = (radioItem, index) => (
    <label
      key={radioItem.value}
      className={cx('radio-input-group', {
        'is-checked': this.state.checkedIndex === index,
        'is-not-checked': this.state.checkedIndex !== index,
      })}>
      <span className="radio-input-checked">
        <RadioChecked />
      </span>
      <span className="radio-input-unchecked">
        <RadioUnchecked />
      </span>
      <span className="radio-input-hover">
        <RadioHover />
      </span>
      <input
        type="radio"
        id={radioItem.value.toString()}
        name={this.props.name}
        value={radioItem.value}
        onChange={() => this.setCheckedIndexAndValue(index, radioItem.value)}
        className="radio-input-item"
        checked={this.state.checkedIndex === index}
      />
      {radioItem.label}
    </label>
  )

  render() {
    const { options, label, subline } = this.props

    const { isValid, errorMessage } = this.state

    return (
      <div
        className={cx('radio-input-container', {
          'is-valid': isValid === true,
          'is-not-valid': isValid === false,
        })}>
        {errorMessage === false && <div className="radio-input-error">{errorMessage}</div>}
        <div className="radio-input-title">{label}</div>
        {subline && <span className="radio-input-subline">{subline}</span>}
        <fieldset>
          <div className="radio-input-wrapper">{options.map(this.renderRadioInputItem)}</div>
        </fieldset>
      </div>
    )
  }
}

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
      defaultValue: PropTypes.bool, // checks radio input value by default
    }),
  ).isRequired,
  validator: PropTypes.func,
  onChange: PropTypes.func,
  waitForCallback: PropTypes.bool,
  nextCallback: PropTypes.func,
  subline: PropTypes.string,
}

RadioInput.displayName = 'RadioInput'

export default RadioInput
