import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { InputCheckbox, SignaturePad, Button } from '@legal-one/leadfunnel-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthKey } from '../../redux/auth/auth.selectors'
import { AUTH } from '../../redux/auth/auth.keys'
import { selectFormKey } from '../../redux/form/form.selectors'
import { FORM } from '../../redux/form/form.keys'
import { setFormKey } from '../../redux/form/form.actions'
import { selectUiKey } from '../../redux/ui/ui.selectors'
import { UI } from '../../redux/ui/ui.keys'
import useGoToAttachment from '../../hooks/useGoToAttachment'
import useModal from '../../hooks/useModal'
import useSignature from '../../hooks/useSignature'
import useUserInfoForm from '../../hooks/useUserInfoForm'
import { getValueBasedOnBuyer, getPrice } from '../../utils/utils'
import {
  LogoItemsWrapper,
  PriceLineText,
  SignaturePadWrapper,
  SubmitButton,
  UnderSubmitButtonText,
} from '../../styles/common.styles'
import { RemunerationCheckbox, RemunerationDescription, RemunerationPrice } from './remuneration.styles'
import SecurityLogos from '../security-logos/security-logos.component'

const Remuneration = () => {
  const remunerationAgreementRef = React.useRef(null)

  const isSignInProgress = useSelector(selectUiKey(UI.IS_SIGN_IN_PROGRESS))
  const total = useSelector(selectAuthKey(AUTH.TOTAL))
  const remunerationAgreement = useSelector(selectFormKey(FORM.REMUNERATION_AGREEMENT))
  const buyer = useSelector(selectAuthKey(AUTH.BUYER))

  const dispatch = useDispatch()

  const { userInfoForm, validationResult, isUpdateStepFailed, onChange } = useUserInfoForm()

  const { signature, showSignatureSuccess, showSignatureError } = useSignature()

  const { t } = useTranslation()

  const goToAttachment = useGoToAttachment(() => {
    if (!remunerationAgreement && remunerationAgreementRef) {
      return remunerationAgreementRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  })

  React.useEffect(() => {
    dispatch(setFormKey(FORM.REMUNERATION_AGREEMENT, false))
  }, [])

  const {
    openCompensationAgreementModal,
    openClientConditionModal,
    openRevocationModal,
    openCompensationAgreementLongModal,
  } = useModal()

  return (
    <div>
      <RemunerationDescription>
        <Trans
          i18nKey={getValueBasedOnBuyer(
            buyer,
            'dataGather.costSectionOneRuff',
            'dataGather.costSectionOneRightmart',
            'dataGather.costSectionOne',
          )}
        >
          A
          <button
            data-tag="link"
            tabIndex={0}
            onClick={openCompensationAgreementModal}
            onKeyPress={openCompensationAgreementModal}
          >
            L1
          </button>
          B
        </Trans>
      </RemunerationDescription>
      <RemunerationPrice>
        <PriceLineText>{t('dataGather.constSectionOnePrice')}</PriceLineText>
        <PriceLineText>{getPrice(total)}</PriceLineText>
      </RemunerationPrice>
      <RemunerationCheckbox>
        <InputCheckbox
          value={userInfoForm[FORM.REMUNERATION_AGREEMENT]}
          ref={remunerationAgreementRef}
          name={FORM.REMUNERATION_AGREEMENT}
          onChange={onChange}
          hasError={isUpdateStepFailed && !validationResult[FORM.REMUNERATION_AGREEMENT]}
        >
          <Trans i18nKey="dataGather.constSectionOneCheckbox">
            A
            <button
              data-tag="link"
              tabIndex={0}
              onClick={openCompensationAgreementLongModal}
              onKeyPress={openCompensationAgreementLongModal}
            >
              L1
            </button>
            B
          </Trans>
        </InputCheckbox>
      </RemunerationCheckbox>
      <SignaturePadWrapper>
        <SignaturePad
          generatedSignature={signature}
          title={t('dataGather.signature_title')}
          imageMode={{
            autogenerate: t('dataGather.signature_signature'),
            goToHandwriteMode: t('dataGather.signature_change_to_mouse'),
          }}
          handMode={{
            back: t('dataGather.signatureBackToMouse'),
            erase: t('dataGather.signatureEraseAll'),
          }}
          showSuccess={showSignatureSuccess}
          showError={showSignatureError}
          onUpdateSignature={(value) => dispatch(setFormKey(FORM.SIGNATURE, value))}
        />
      </SignaturePadWrapper>
      <SubmitButton>
        <Button
          label={t('app.button.button_submit_give_us')}
          fullWidth
          color="orange"
          size="xl"
          loading={isSignInProgress}
          onClick={goToAttachment}
        />
      </SubmitButton>
      <UnderSubmitButtonText>
        <Trans i18nKey="dataGather.submitSubTitle">
          A
          <button data-tag="link" onClick={openClientConditionModal}>
            L1
          </button>
          B
          <button data-tag="link" onClick={openRevocationModal}>
            L2
          </button>
          C
        </Trans>
      </UnderSubmitButtonText>
      <LogoItemsWrapper>
        <SecurityLogos />
      </LogoItemsWrapper>
    </div>
  )
}

Remuneration.displayName = 'Remuneration'

export default Remuneration
