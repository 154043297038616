export const notBlank = (value, errorMessage = 'Dieses Feld darf nicht leer sein!') => {
  let valid = true

  if (value.length <= 0) {
    valid = false
  }

  return {
    valid,
    errorMessage: valid === false ? errorMessage : null,
  }
}

/**
 * Expecting date to be in the Format DD.MM.YYYY, otherwise will fail, recommended usage with mask localisedDateDE
 * Returns object with keys "valid" and "errorMessage", errorMessage is only set
 * if value is not valid. See examples below.
 *
 * Example invalid Return: {
 *   valid: false,
 *   errorMessage: 'Not valid!',
 * }
 *
 * Example valid Return: {
 *   valid: true,
 *   errorMessage: null,
 * }
 *
 * @throws Error
 * @param value
 * @param errorMessage
 * @returns Object
 */
export const dateAgeDE = (value, errorMessage = 'Das Datum darf nicht in der Zukunft oder mehr als 120 Jahre zurück liegen.') => {
  let valid = true

  const parts = value.split('.')
  const year = parts[2]
  const month = parts[1] - 1
  const day = parts[0]

  if (!value) {
    errorMessage = 'Dieses Feld darf nicht leer sein!'
    valid = false
  } else if (isNaN(year) || isNaN(month) || isNaN(day) || month < 0 || day < 0) {
    errorMessage = 'Ungültiges Datum! Bitte geben Sie ein gültiges Datum an.'
    valid = false
  } else if (day > new Date(year, month, 0).getDate() || month > 11) {
    errorMessage = 'Ungültiges Datum! Bitte geben Sie ein gültiges Datum an.'
    valid = false
  } else {
    const date = new Date(parts[2], parts[1] - 1, parts[0])

    const minDate = new Date()
    minDate.setFullYear(minDate.getFullYear() - 120)

    const maxDate = new Date()

    if (date > maxDate || date < minDate) {
      valid = false
    }
  }


  return {
    valid,
    errorMessage: valid === false ? errorMessage : null,
  }
}

/**
 * Checks if Value is number and between 1 and 9, otherwise returns invalid flag with error message
 *
 * @param value (int|string)
 * @param errorMessage string
 * @returns {{valid: boolean, errorMessage: (string|null)}}
 */
export const numberBetweenOneAndNine = (value, errorMessage = 'Diese Zahl muss zwischen 1-9 liegen.') => {
  let valid = true

  if (!value) {
    errorMessage = 'Dieses Feld darf nicht leer sein!'
    valid = false
  } else if (isNaN(value)) {
    valid = false
  } else if (!isNaN(value) && (parseInt(value) < 1 || parseInt(value) > 10)) {
    valid = false
  }

  return {
    valid,
    errorMessage: valid === false ? errorMessage : null,
  }
}
