import { createSelector } from 'reselect'

export const selectUserAttachmentUi = (state) => state.userAttachmentUi

export const selectActiveSection = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.activeSection,
)

export const selectIsInsuranceInformationPosted = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.isInsuranceInformationPosted,
)

export const selectIsFilePosted = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.isFilePosted,
)

export const selectIsClientSurveyPosted = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.isClientSurveyPosted,
)

export const selectInsuranceName = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.insuranceName,
)

export const selectInsuranceNumber = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.insuranceNumber,
)

export const selectFileUploadCount = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.fileUploadCount,
)

export const selectFileUploads = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.fileUploads,
)

export const selectFilesAreUploading = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.filesAreUploading,
)

export const selectFilesAreUploaded = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.filesAreUploaded,
)

export const selectWaiverOnDrivingBanImportant = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.waiverOnDrivingBanImportant,
)

export const selectCarOwner = createSelector([selectUserAttachmentUi], (userAttachmentUi) => userAttachmentUi.carOwner)

export const selectRelationshipOwnerDriver = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.relationshipOwnerDriver,
)

export const selectAdditionalLegalInsurance = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.additionalLegalInsurance,
)

export const selectAdditionalInformation = createSelector(
  [selectUserAttachmentUi],
  (userAttachmentUi) => userAttachmentUi.additionalInformation,
)
