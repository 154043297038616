import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Section from '../../../../../components/attachment/_parts/Section'
import TextInput from '../../../../../components/attachment/_parts/Form/TextInput'
import Select from '../../../../../components/attachment/_parts/Form/Select'
import RadioInput from '../../../../../components/attachment/_parts/Form/RadioInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActiveSection,
  setWaiverOnDrivingBanImportant,
  setCarOwner,
  setRelationshipOwnerDriver,
  setAdditionalLegalInsurance,
  setAdditionalInformation,
  setIsClientSurveyPosted,
} from '../../../../../redux/user-attachment-ui/user-attachment-ui.actions'
import { ACTIVE_SECTION } from '../../../../../redux/user-attachment-ui/user.attachment-ui.utils'
import { selectAuthKey, selectHasInsuranceInAttachment } from '../../../../../redux/auth/auth.selectors'
import { postClientSurvey } from '../../../../../axios/requests'
import { AUTH } from '../../../../../redux/auth/auth.keys'
import { UI } from '../../../../../redux/ui/ui.keys'
import {
  selectAdditionalInformation,
  selectAdditionalLegalInsurance,
  selectCarOwner,
  selectRelationshipOwnerDriver,
  selectWaiverOnDrivingBanImportant,
} from '../../../../../redux/user-attachment-ui/user-attachment-ui.selectors'
import { selectUiKey } from '../../../../../redux/ui/ui.selectors'
import { utils } from '@legal-one/leadfunnel-ui-kit'

const SectionClientSurvey = (props) => {
  const { index, isActive } = props
  const [postingClientSurvey, setPostingClientSurvey] = useState(false)
  const [isCarOwnedByUser, setIsCarOwnedByUser] = useState(null)
  const [showAdditionalInsuranceFreeText, setShowAdditionalInsuranceFreeText] = useState(false)
  const [showIsBanImportantSelect, setShowIsBanImportantSelect] = useState(false)
  const dispatch = useDispatch()
  const fullName = useSelector(selectAuthKey(AUTH.FULL_NAME))
  const hasInsuranceInAttachment = useSelector(selectHasInsuranceInAttachment)
  const secret = useSelector(selectUiKey(UI.SECRET))
  const waiverOnDrivingBanImportant = useSelector(selectWaiverOnDrivingBanImportant)
  const carOwner = useSelector(selectCarOwner)
  const relationshipOwnerDriver = useSelector(selectRelationshipOwnerDriver)
  const additionalLegalInsurance = useSelector(selectAdditionalLegalInsurance)
  const additionalInformation = useSelector(selectAdditionalInformation)

  const handleSubmit = async () => {
    utils.pushToDataLayer({ event: 'clientSurveySend' })
    setPostingClientSurvey(true)
    const { status } = await postClientSurvey({
      secret,
      waiverOnDrivingBanImportant,
      carOwner,
      relationshipOwnerDriver,
      additionalLegalInsurance,
      additionalInformation,
      chargeDate: '',
      carDriver: '',
    })
    if (status === 204) {
      setPostingClientSurvey(false)
      utils.pushToDataLayer({ event: 'clientSurveySent' })
      dispatch(setActiveSection(''))
      dispatch(setIsClientSurveyPosted(true))
    } else {
      setPostingClientSurvey(false)
      utils.pushToDataLayer({ event: 'clientSurveyError' })
    }
  }

  const handleRelationSelect = (input) => {
    dispatch(setRelationshipOwnerDriver(input.value))
  }

  const handleAdditionalInsuranceRadio = (input) => {
    setShowAdditionalInsuranceFreeText(input.value)
    dispatch(setAdditionalLegalInsurance(input.value === true ? 'Ja' : 'Nein'))
  }

  const handleBanRadio = (input) => {
    setShowIsBanImportantSelect(input.value)

    if (input.value === false) {
      dispatch(setWaiverOnDrivingBanImportant(false))
    }
  }

  const handleCarOwnerRadio = (input) => {
    setIsCarOwnedByUser(input.value)
    if (input.value === true) {
      dispatch(setCarOwner(fullName))
    } else {
      dispatch(setCarOwner('keine Angabe'))
    }
  }

  const handleTitleClick = () => {
    dispatch(setActiveSection(ACTIVE_SECTION.SURVEY))
  }

  return (
    <Section
      title="Weitere Informationen"
      index={index}
      subtitle="Bitte teilen Sie uns alle wichtigen Informationen zum Fahrer und Fahrzeughalter mit."
      isCollapsed={isActive === false}
      allowScroll={isActive === true}
      onSectionFinish={handleSubmit}
      onTitleClick={handleTitleClick}
      loading={postingClientSurvey}>
      <RadioInput
        label="Ist das Fahrzeug auf Sie zugelassen?"
        name="car-owner-radio"
        onChange={handleCarOwnerRadio}
        options={[
          { label: 'Ja', value: true },
          { label: 'Nein', value: false },
        ]}
        waitForCallback={true}
      />
      {isCarOwnedByUser === false && (
        <TextInput
          onChange={(input) => dispatch(setCarOwner(input.value))}
          label="Wer ist der Fahrzeughalter"
          placeholder="Vor- und Nachname / Firma"
          id="car-owner-input"
        />
      )}
      {isCarOwnedByUser === false && (
        <Select
          waitForCallback={true}
          onChange={handleRelationSelect}
          id="relation-select"
          placeholder="Beziehung Fahrzeughalter/-führer auswählen..."
          options={[
            {
              label: 'Elternteil - Tochter/Sohn',
              value: 'Elternteil - Tochter/Sohn',
            },
            { label: 'Eheleute', value: 'Eheleute' },
            { label: 'Lebenspartner', value: 'Lebenspartner' },
            {
              label: 'Arbeitsverhältnis (Firmenfahrzeug)',
              value: 'Arbeitsverhältnis (Firmenfahrzeug)',
            },
            { label: 'Sonstiges', value: 'Sonstiges', isFreeText: true },
          ]}
          label="Beziehung Fahrzeughalter und -führer"
          hasSearch={false}
        />
      )}
      {hasInsuranceInAttachment && (
        <RadioInput
          onChange={handleAdditionalInsuranceRadio}
          label="Weitere Rechtsschutzversicherungen?"
          name="additional-legal-insurance-select"
          subline="Im Rahmen der Kostendeckungsanfrage stellen Versicherungsunternehmen die Frage nach einer weiteren Rechtsschutzversicherung. Besteht für das betroffene Fahrzeug eine weitere (Firmen-) Rechtsschutzversicherung?"
          options={[
            { label: 'Ja', value: true },
            { label: 'Nein', value: false },
          ]}
          waitForCallback={true}
        />
      )}
      {showAdditionalInsuranceFreeText === true && (
        <TextInput
          onChange={(input) => dispatch(setAdditionalLegalInsurance(input.value))}
          label="Versicherungsinformationen (falls zur Hand)"
          placeholder="Weitere Versicherung"
          id="additional-legal-insurance"
        />
      )}
      <RadioInput
        onChange={handleBanRadio}
        label="Droht ein Fahrverbot?"
        name="is-ban"
        options={[
          { label: 'Ja', value: true },
          { label: 'Nein', value: false },
        ]}
        waitForCallback={true}
      />
      {showIsBanImportantSelect === true && (
        <Select
          onChange={(input) => dispatch(setWaiverOnDrivingBanImportant(input.value))}
          waitForCallback={true}
          id="is-ban-important"
          placeholder="Auswählen..."
          options={[
            {
              label: 'Nein, es ist mir nicht so wichtig, ich könnte z.B. meinen Urlaub zu der Zeit planen',
              value: false,
              defaultValue: true,
            },
            {
              label: 'Ja, es ist mir sehr wichtig - auch gegen Verdoppelung des Bußgeldes',
              value: true,
            },
          ]}
          label="Ist Ihnen der Verzicht auf das Fahrverbot sehr wichtig?"
          hasSearch={false}
        />
      )}
      <TextInput
        area={true}
        onChange={(input) => dispatch(setAdditionalInformation(input.value))}
        label="Haben Sie weitere Informationen, die Sie für wichtig halten/uns mitteilen wollen?"
        id="additional-information-input"
      />
    </Section>
  )
}

SectionClientSurvey.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default SectionClientSurvey
