import styled from 'styled-components'
import { COLORS } from '@legal-one/leadfunnel-ui-kit'
import SvgTuevLogo from '../../svg/tuev-logo'
import SvgTlsLogo from '../../svg/tls-logo'

export const SecurityLogosWrapper = styled.section`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
`

export const SecurityLogosTuevSvg = styled(SvgTuevLogo)`
  margin: 0;
  padding: 0;
  width: 105px;
  height: 55px;
`

export const SecurityLogosTls = styled.div`
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 1;
  color: ${COLORS.BLUE_4};
`

export const SecurityLogosTlsSvg = styled(SvgTlsLogo)`
  margin: 0 0 6px 0;
  padding: 0;
  width: 39px;
  height: 25px;
`
