export function pushUserDataToOptimizely(attributes) {
  window.optimizely = window.optimizely || []
  window['optimizely'].push({
    type: 'user',
    attributes: attributes,
  })
}

export function pushPageDataToOptimizely(attributes) {
  window.optimizely = window.optimizely || []
  window['optimizely'].push({
    type: 'page',
    pageName: attributes,
  })
}

export function pushEventToOptimizely(eventName) {
  window.optimizely = window.optimizely || []
  window['optimizely'].push({
    type: 'event',
    eventName,
  })
}
