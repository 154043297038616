const InformationModal = () => (
  <>
    <p>Stand: 08/2020</p>
    <p>
      Allgemeine Informationen zu Verträgen, die über www.sos-verkehrsrecht.de zwischen der rightmart Berlin 
      Rechtsanwaltsgesellschaft mbH, Sonnenallee 260/262, 12057 Berlin - im Folgenden „Kanzlei“ - und dem Mandanten - im
      Folgenden „Mandant“ - geschlossen werden.
    </p>
    <h3>§ 1 Vertragsgegenstand und -inhalt, Zustandekommen des Vertrages</h3>
    <p>
      (1) Gegenstand des Vertrags ist die Verteidigung des Mandanten im Verkehrsordnungswidrigkeitsrecht und -strafrecht
      durch Rechtsanwälte der Kanzlei im Ermittlungs-, Zwischen- und / oder Gerichtsver-fahren.
    </p>
    <p>(2) Der Vertrag kommt zustande mit:</p>
    <p>
      rightmart Berlin Rechtsanwaltsgesellschaft mbH <br />
      Sonnenallee 260/262 <br />
      12057 Berlin
    </p>
    <p>
      (3) Die Leistung der Kanzlei und der bevollmächtigten Rechtsanwälte besteht in der Verteidigung des Mandanten. Der
      Mandant bestätigt durch Anklicken des Buttons „Kostenpflichtig beauftragen“ seinen verbindlichen Auftrag an die
      Kanzlei. Mit Verbindlichkeit des Auftrags verpflichtet sich der Man-dant zur Begleichung der nach dem
      Rechtsanwaltsvergütungsgesetz (RVG) anfallenden Gebühren oder im Falle der abweichenden Einigung nach der
      geschlossenen Vergütungsvereinbarung. Sobald die Kanzlei den Auftrag des Mandanten erhalten hat, wird dem
      Mandanten eine Bestätigung über den Eingang seines Auftrages bei der Kanzlei zugesandt, in der Regel per E-Mail
      (Eingangsbestätigung).
    </p>
    <p>(4) Die Beauftragung über die Webseite der Kanzlei funktioniert wie folgt:</p>
    <p>
      <b>Angaben zur Person:</b> <br />
      Hier trägt der Mandant seine Angaben zur Person einschließlich Geburtsdatum und Geburtsort in die jeweils dafür
      vorgesehenen Maskenfelder ein. Anschließend klickt der Mandant auf den Button „Weiter“.
    </p>
    <p>
      <b>Kontaktdaten angeben:</b> <br />
      Nun trägt der Mandant seine Adressdaten ein und klickt wiederum auf „Weiter“.
    </p>
    <p>
      <b>Vollmacht und Auftrag erteilen:</b> <br />
      Im letzten Schritt erteilt der Mandant der Kanzlei das Mandat und zwei Rechtsanwälten der Kanzlei eine Vollmacht.
      Zugleich erhält der Mandant eine Übersicht seiner Angaben. Sämtliche Angaben in der Auftragsübersicht können
      jederzeit vor Anklicken des Buttons „Kostenpflichtig beauftragen“ auf der letzten Seite noch einmal überprüft und
      korrigiert werden. In dem Feld „Digitale Unterschrift“ zeichnet der Mandant seinen Namenszug. Durch Setzen eines
      Häkchens in die entsprechenden Käst-chen bestätigt der Mandant die Kenntnisnahme und das Einverständnis mit der
      Datenschutzerklärung sowie den Mandatsbedingungen. Durch Anklicken der Schaltfläche "Kostenpflichtig beauftragen"
      erteilt der Mandant seinen Auftrag an die Kanzlei und die Vollmacht.
    </p>
    <p>
      <b>Nach Absenden des Auftrags:</b> <br />
      Der Kanzlei schickt daraufhin dem Mandanten eine automatische Empfangsbestätigung per E-Mail zu, in welcher die
      Vollmacht mit dem dahingehenden Vertretungsauftrag des Mandanten, die Mandats-bedingungen, ggf. die abgeschlossene
      Vergütungsvereinbarung und die Widerrufsbelehrung noch-mals aufgeführt werden und die der Mandant über die
      Funktion „Drucken“ jeweils ausdrucken kann. Die Annahme des Auftrags erfolgt separat nach Prüfung durch die
      Kanzlei.
    </p>
    <h3>§ 2 Vergütung und Zahlungsbedingungen</h3>
    <p>
      (1) Die Vergütung der Kanzlei richtet sich nach dem RVG oder – im Falle eines gesonderten Vertragsschlusses – nach
      einer separaten Vergütungsvereinbarung.
    </p>
    <p>
      (2) Ist der Mandant rechtsschutzversichert, wird die Kanzlei die erbrachten Rechtsdienstleistungen nach Erteilung
      einer entsprechenden Deckungsschutzzusage gegenüber der Rechtsschutzversicherung des Mandanten abrechnen. Der
      Mandant bleibt jedoch der Kostenschuldner der Kanzlei.
    </p>
    <p>
      (3) Die Kanzlei hat nach § 9 RVG bzw. der Vergütungsvereinbarung das Recht auf Forderung eines angemessenen
      Vorschusses.
    </p>
    <h3>§ 3 Vertragstext</h3>
    <p>
      Der Vertragstext wird auf den internen Systemen der Kanzlei gespeichert. Die Auftragsdaten (Vollmacht, ggf.
      Vergütungsvereinbarung) und die Mandatsbedingungen werden dem Mandanten per E-Mail zugesendet. Nach Abschluss der
      Beauftragung sind die Auftragsdaten aus Sicherheitsgründen nicht mehr über das Internet zugänglich.
    </p>
    <h3>§ 4 Schlussbestimmungen</h3>
    <p>(1) Die Vertragssprache ist deutsch.</p>
    <p>
      (2) Auf Verträge zwischen der Kanzlei und den Mandanten findet das Recht der Bundesrepublik Deutschland Anwendung.
      Diese Rechtswahl gilt bei Verbrauchern nur, soweit der durch zwingende Bestimmungen des Rechts des Staates des
      gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz dem Mandanten nicht entzogen wird.
    </p>
    <p>
      (3) Sofern es sich beim Mandanten um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein
      öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen
      zwischen dem Mandanten und der Kanzlei der Sitz der Kanzlei. Dies gilt auch, sofern der Mandant keinen allgemeinen
      Gerichtsstand in Deutschland oder der EU hat, oder sein Wohnsitz oder sein gewöhnlicher Aufenthalt im Zeitpunkt
      der Klageerhebung nicht bekannt ist.
    </p>
  </>
)

export default InformationModal
