import styled from 'styled-components'

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #333;
  hyphens: manual;
  > a {
    text-decoration: none;
    color: #006699b3;
    cursor: pointer;
  }
`

export const NoInsuranceWrapper = styled.section`
  margin: 0;
  padding: 0;
`

export const NoInsuranceTitle = styled.h6`
  margin: 0 0 16px 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #069;
`

export const NoInsuranceContentText = styled(Text)`
  margin: 0 0 20px 0;
`

export const NoInsurancePriceWrapper = styled.div`
  margin: 0 0 20px 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`

export const NoInsurancePriceText = styled.span`
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #069;
`

export const NoInsuranceCheckboxWrapper = styled.div`
  margin: 0;
  padding: 0;
  > * {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const NoInsuranceSwitch = styled.div`
  margin: 20px 0;
`
