import React from 'react'
import { Route } from 'react-router-dom'
import NotFoundPage from '../../pages/not-found/not-found.page'
import { setAuth } from '../../redux/auth/auth.actions'
import { setForm } from '../../redux/form/form.actions'
import { setAuthRequestEndError, setAuthRequestEndSuccess, setUi, setUiKey } from '../../redux/ui/ui.actions'
import useAuthentication from '../../hooks/useAuthentication'
import { extractAuthInfoForVariation, extractUiInfoForVariation, extractUserInfo } from '../../utils/redux'
import { pushUserDataToOptimizely } from '../../utils/optimizely'
import { POA } from '../../constants/poa-types'
import { UI } from '../../redux/ui/ui.keys'
import { usePoaFromUrl } from '../../hooks/usePoaFromUrl'

const VariationAuthentication = ({ children, ...restProps }) => {
  const { isAuthInProgress, isAuthSuccess, secret, variationPersist, dispatch } = useAuthentication(async (data) => {
    dispatch(setAuth(extractAuthInfoForVariation(data)))
    dispatch(setUi(extractUiInfoForVariation(data)))
    dispatch(setForm(extractUserInfo(data)))
    await variationPersist(data, secret, (success) => {
      if (success) {
        if (
          window['useAlternateCta'] &&
          ((data.poaData && data.poaData.latestSentPoaType === POA.WITH_LCI) ||
            (data.poaData && data.poaData.latestSentPoaType === POA.WITH_LCI_A) ||
            (data.poaData && data.poaData.latestSentPoaType === POA.WITH_LCI_B))
        ) {
          dispatch(setUiKey(UI.CTA_BUTTON_TEXT, 'app.button.button_submit_assign_us'))
        } else {
          dispatch(setUiKey(UI.CTA_BUTTON_TEXT, 'app.button.button_submit_give_us'))
        }
        dispatch(setAuthRequestEndSuccess())
        pushUserDataToOptimizely({
          poa_type: data.poaData && data.poaData.latestSentPoaType,
          accusation: data.charge,
        })
      } else {
        dispatch(setAuthRequestEndError())
      }
    })
  })
  usePoaFromUrl()

  return <Route {...restProps} render={() => (isAuthInProgress || isAuthSuccess ? children : <NotFoundPage />)} />
}

VariationAuthentication.displayName = 'VariationAuthentication'

export default VariationAuthentication
