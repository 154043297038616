import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputRadio, InputDate } from '@legal-one/leadfunnel-ui-kit'
import { InputText } from '@legal-one/crm-vollmacht-v2-ui-kit'
import { Row } from '@legal-one/crm-vollmacht-v2-ui-kit'
import useUserInfoForm from '../../../hooks/useUserInfoForm'
import useRemuneration from '../../../hooks/useRemuneration'
import { FORM } from '../../../redux/form/form.keys'
import { GenderSelector } from '../../../styles/common.styles'
import { useSelector } from 'react-redux'
import { selectAuthKey } from '../../../redux/auth/auth.selectors'
import { AUTH } from '../../../redux/auth/auth.keys'
import { trackJSErrorLog } from '../../../utils/utils'
import { UI } from '../../../redux/ui/ui.keys'
import { selectUiKey } from '../../../redux/ui/ui.selectors'

const Step1 = () => {
  const { userInfoForm, areFieldsEditable, onChange, onFocus, onBlur, showSuccess, showError, showPopup } =
    useUserInfoForm()

  const caseSigned = useSelector(selectAuthKey(AUTH.CASE_SIGNED))
  const secret = useSelector(selectUiKey(UI.SECRET))

  const { isRemunerationPage } = useRemuneration()

  const { t } = useTranslation()

  React.useEffect(() => {
    if (caseSigned) {
      trackJSErrorLog(`First step renders with caseSigned. Secret: ${secret}`)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <Row forceWidth="50%">
        <GenderSelector data-anchor="gender-selector">
          <InputRadio
            label={t('dataGather.salutation_male')}
            name={FORM.SALUTATION}
            value="male"
            checked={userInfoForm[FORM.SALUTATION] === 'male'}
            size="sm"
            disabled={!areFieldsEditable}
            onChange={onChange}
          />
          <InputRadio
            label={t('dataGather.salutation_female')}
            name={FORM.SALUTATION}
            value="female"
            checked={userInfoForm[FORM.SALUTATION] === 'female'}
            size="sm"
            disabled={!areFieldsEditable}
            onChange={onChange}
          />
        </GenderSelector>
      </Row>
      <Row>
        <InputText
          label={t('dataGather.firstName_label')}
          value={userInfoForm[FORM.FIRST_NAME]}
          name={FORM.FIRST_NAME}
          popup={t('dataGather.firstName_popup')}
          placeholder={t('dataGather.firstName_placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.FIRST_NAME, required: true })}
          showSuccess={showSuccess({ key: FORM.FIRST_NAME, required: true })}
          showPopup={showPopup({ key: FORM.FIRST_NAME, required: true })}
          disabled={!areFieldsEditable}
        />
        <InputText
          label={t('dataGather.lastName_label')}
          value={userInfoForm[FORM.LAST_NAME]}
          name={FORM.LAST_NAME}
          popup={t('dataGather.lastName_popup')}
          placeholder={t('dataGather.lastName_placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.LAST_NAME, required: true })}
          showSuccess={showSuccess({ key: FORM.LAST_NAME, required: true })}
          showPopup={showPopup({ key: FORM.LAST_NAME, required: true })}
          disabled={!areFieldsEditable}
        />
      </Row>
      <Row forceWidth="50%">
        <InputText
          label={t('dataGather.birthName_label')}
          value={userInfoForm[FORM.BIRTH_NAME]}
          name={FORM.BIRTH_NAME}
          popup={t('dataGather.birthName_popup')}
          placeholder={t('dataGather.birthName_placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.BIRTH_NAME, required: false })}
          showSuccess={showSuccess({ key: FORM.BIRTH_NAME, required: false })}
          showPopup={showPopup({ key: FORM.BIRTH_NAME, required: false })}
          disabled={!areFieldsEditable}
        />
      </Row>
      <Row>
        <InputDate
          label={t('dataGather.birthDate_label')}
          value={userInfoForm[FORM.BIRTH_DATE]}
          name={FORM.BIRTH_DATE}
          popup={t('dataGather.birthDate_popup')}
          idlePlaceholder={t('dataGather.birthDate_placeholder')}
          hoverPlaceholder="TT/MM/YYYY"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.BIRTH_DATE, required: true })}
          showSuccess={showSuccess({ key: FORM.BIRTH_DATE, required: true })}
          showPopup={showPopup({ key: FORM.BIRTH_DATE, required: true })}
          autoComplete="off"
          required={isRemunerationPage}
        />
        <InputText
          label={t('dataGather.birthCity_label')}
          value={userInfoForm[FORM.BIRTH_CITY]}
          name={FORM.BIRTH_CITY}
          popup={t('dataGather.birthCity_popup')}
          placeholder={t('dataGather.birthCity_placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.BIRTH_CITY, required: true })}
          showSuccess={showSuccess({ key: FORM.BIRTH_CITY, required: true })}
          showPopup={showPopup({ key: FORM.BIRTH_CITY, required: true })}
        />
      </Row>
    </React.Fragment>
  )
}

Step1.displayName = 'Step1'

export default Step1
