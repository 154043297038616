import { createGlobalStyle } from 'styled-components'
import { COLORS } from '@legal-one/leadfunnel-ui-kit'

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    overflow-x: hidden;
    background-color: ${COLORS.WHITE_9};
  }
  ol, ul {
    list-style: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
	a[data-tag="link"] {
		color: ${COLORS.BLUE_4};
		text-decoration: none;
		text-align: left;
    font-weight: 300;
		:hover {
      cursor: pointer;
      color: ${COLORS.BLUE_4};
			text-decoration: none;
		}
    :focus {
      outline-color: ${COLORS.ORANGE_1};
      outline-style: auto;
      outline-offset: 2px;
      border-radius: 4px;
    }
	}
	button[data-tag="link"] {
		margin: 0;
		padding: 0;
		border: none;
		background-color: transparent;
    color: ${COLORS.BLUE_4};
    text-decoration: none;
		text-align: left;
    font-weight: 300;
    :hover {
      cursor: pointer;
      color: ${COLORS.BLUE_};
      text-decoration: none;
    }
		:focus {
      outline-color: ${COLORS.ORANGE_1};
      outline-style: auto;
      outline-offset: 2px;
      border-radius: 4px;
		}
	}
	a[data-tag="link-bold"] {
		color: ${COLORS.BLUE_4};
		text-decoration: none;
		text-align: left;
    font-weight: 400;
		:hover {
      cursor: pointer;
      color: ${COLORS.BLUE_4};
			text-decoration: none;
		}
    :focus {
      outline-color: ${COLORS.ORANGE_1};
      outline-style: auto;
      outline-offset: 2px;
      border-radius: 4px;
    }
	}
	button[data-tag="link-bold"] {
		margin: 0;
		padding: 0;
		border: none;
		background-color: transparent;
    color: ${COLORS.BLUE_4};
    text-decoration: none;
		text-align: left;
    font-weight: 400;
    :hover {
      cursor: pointer;
      color: ${COLORS.BLUE_};
      text-decoration: none;
    }
		:focus {
      outline-color: ${COLORS.ORANGE_1};
      outline-style: auto;
      outline-offset: 2px;
      border-radius: 4px;
		}
	}
`
