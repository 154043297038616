import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  InfoBoxUserSummaryWrapper,
  InfoBoxUserSummaryInfoLine,
  InfoBoxUserSummaryTitle,
  InfoBoxUserSummaryTitleEdit,
  InfoBoxUserSummaryTitleEditBracket,
  InfoBoxUserSummaryTitleEditIcon,
  InfoBoxUserSummaryTitleEditText,
  InfoBoxUserSummaryTitleText,
} from './info-box-user-summary.styles'
import useRemuneration from '../../hooks/useRemuneration'
import { utils } from '@legal-one/leadfunnel-ui-kit'
import { setUiKey } from '../../redux/ui/ui.actions'
import { UI } from '../../redux/ui/ui.keys'
import { selectUserData } from '../../redux/form/form.selectors'

const InfoBoxUserSummary = (props) => {
  const userData = useSelector(selectUserData)

  const { isRemunerationComplete } = useRemuneration()

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const getUserName = ({ firstName, lastName, birthName }) => `${firstName} ${lastName} ${birthName}`

  const getUserBirthInfo = ({ birthDate, birthCity }) => `Geb ${utils.parseCustomDate(birthDate)} in ${birthCity}`

  const getUserAddressInfo = ({ street, houseNumber, postCode, city }) =>
    `${street} ${houseNumber}, ${postCode} ${city}`

  const onEdit = () => dispatch(setUiKey(UI.CURRENT_STEP, 1))

  return (
    <InfoBoxUserSummaryWrapper>
      <InfoBoxUserSummaryTitle isBlack={props.isBlack}>
        <InfoBoxUserSummaryTitleText>{t('app.sidebar-user-info.data_title')}</InfoBoxUserSummaryTitleText>
        <InfoBoxUserSummaryTitleEdit hidden={isRemunerationComplete} tabIndex={0} onClick={onEdit} onKeyPress={onEdit}>
          <InfoBoxUserSummaryTitleEditBracket>(</InfoBoxUserSummaryTitleEditBracket>
          <InfoBoxUserSummaryTitleEditText>{t('app.sidebar-user-info.change_button')}</InfoBoxUserSummaryTitleEditText>
          <InfoBoxUserSummaryTitleEditIcon />
          <InfoBoxUserSummaryTitleEditBracket>)</InfoBoxUserSummaryTitleEditBracket>
        </InfoBoxUserSummaryTitleEdit>
      </InfoBoxUserSummaryTitle>
      <InfoBoxUserSummaryInfoLine isBlack={props.isBlack}>{getUserName(userData)}</InfoBoxUserSummaryInfoLine>
      <InfoBoxUserSummaryInfoLine isBlack={props.isBlack}>{getUserBirthInfo(userData)}</InfoBoxUserSummaryInfoLine>
      <InfoBoxUserSummaryInfoLine isBlack={props.isBlack}>{getUserAddressInfo(userData)}</InfoBoxUserSummaryInfoLine>
    </InfoBoxUserSummaryWrapper>
  )
}

InfoBoxUserSummary.displayName = 'InfoBoxUserSummary'

InfoBoxUserSummary.propTypes = {
  isBlack: PropTypes.bool,
}

export default InfoBoxUserSummary
