import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, InputText } from '@legal-one/crm-vollmacht-v2-ui-kit'
import useUserInfoForm from '../../../hooks/useUserInfoForm'
import { FORM } from '../../../redux/form/form.keys'

const Step2 = () => {
  const { userInfoForm, areFieldsEditable, onChange, onFocus, onBlur, showSuccess, showError, showPopup } =
    useUserInfoForm()

  const { t } = useTranslation()

  return (
    <Fragment>
      <Row>
        <InputText
          label={t('dataGather.street_label')}
          value={userInfoForm[FORM.STREET]}
          name={FORM.STREET}
          popup={t('dataGather.street_popup')}
          placeholder={t('dataGather.street_placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.STREET, required: true })}
          showSuccess={showSuccess({ key: FORM.STREET, required: true })}
          showPopup={showPopup({ key: FORM.STREET, required: true })}
          disabled={!areFieldsEditable}
        />
        <InputText
          label={t('dataGather.houseNumber_label')}
          value={userInfoForm[FORM.HOUSE_NUMBER]}
          name={FORM.HOUSE_NUMBER}
          popup={t('dataGather.houseNumber_popup')}
          placeholder={t('dataGather.houseNumber_placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.HOUSE_NUMBER, required: true })}
          showSuccess={showSuccess({ key: FORM.HOUSE_NUMBER, required: true })}
          showPopup={showPopup({ key: FORM.HOUSE_NUMBER, required: true })}
          disabled={!areFieldsEditable}
        />
      </Row>
      <Row>
        <InputText
          label={t('dataGather.postCode_label')}
          value={userInfoForm[FORM.POST_CODE]}
          type="number"
          maxLength={5}
          name={FORM.POST_CODE}
          popup={t('dataGather.postCode_popup')}
          placeholder={t('dataGather.postCode_placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.POST_CODE, required: true })}
          showSuccess={showSuccess({ key: FORM.POST_CODE, required: true })}
          showPopup={showPopup({ key: FORM.POST_CODE, required: true })}
          disabled={!areFieldsEditable}
        />
        <InputText
          label={t('dataGather.city_label')}
          value={userInfoForm[FORM.CITY]}
          name={FORM.CITY}
          popup={t('dataGather.city_popup')}
          placeholder={t('dataGather.city_placeholder')}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          showError={showError({ key: FORM.CITY, required: true })}
          showSuccess={showSuccess({ key: FORM.CITY, required: true })}
          showPopup={showPopup({ key: FORM.CITY, required: true })}
          disabled={!areFieldsEditable}
        />
      </Row>
    </Fragment>
  )
}

Step2.displayName = 'Step2'

export default Step2
