export const ACTIVE_SECTION = {
  INSURANCE: 'insurance',
  FILES: 'files',
  SURVEY: 'survey',
}

export const INSURANCE_CHOICES = [
  {
    value: '75c51594-fc5d-11e7-975e-735ac968834e',
    label: 'Aachen Muenchener Versicherung AG',
  },
  {
    value: '76499f44-fc5d-11e7-b044-c5b460fe3a4c',
    label: 'ACE',
  },
  {
    value: '7655cdaa-fc5d-11e7-b3ca-3920795a8d51',
    label: 'ADAC-Rechtsschutz Versicherungs-AG',
  },
  {
    value: '765c4a2c-fc5d-11e7-bdc1-f372c27e1b38',
    label: 'ADVOCARD Rechtsschutzversicherung AG',
  },
  {
    value: '76685c22-fc5d-11e7-9c7e-9d7056177279',
    label: 'Allianz RS-Service GmbH Rechtsschutzversicherung',
  },
  {
    value: '766ea154-fc5d-11e7-b534-07e92dc73772',
    label: 'ALLRECHT Rechtsschutzversicherung',
  },
  {
    value: '7674f4b4-fc5d-11e7-916d-53bce72c5b03',
    label: 'Alte Leipziger Versicherung AG',
  },
  { value: '767afa58-fc5d-11e7-8ec7-95991e229d9d', label: 'AON Versicherung' },
  {
    value: '7680e490-fc5d-11e7-a3b3-033ff0197fe0',
    label: 'ARAG SE ARAG Allgemeine Versicherungs-AG',
  },
  {
    value: '768750be-fc5d-11e7-a974-dfa9e95da821',
    label: 'ARAG SE Direktion für Österreich',
  },
  {
    value: '768e1a52-fc5d-11e7-a7f1-0d4b9711e9dc',
    label: 'ASE Automobil Service Europa',
  },
  { value: '76946268-fc5d-11e7-893c-9f4b8b3315e3', label: 'Askuma AG' },
  {
    value: '769ab424-fc5d-11e7-88fb-17ee8d7b9920',
    label: 'AT - Allianz Elementar Versicherungs-AG Wien (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76a139ca-fc5d-11e7-b5be-d90c0a9c72c1',
    label: 'AT - D.A.S. Rechtsschutz AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76a8d504-fc5d-11e7-a186-f18ad86e56a2',
    label: 'AT - Generali Gruppe (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76aff2ee-fc5d-11e7-ac0b-d9b835656d61',
    label: 'AT - HDI (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76b6d4ce-fc5d-11e7-8170-eb125fb0a96e',
    label: 'AT - Helvetia Versicherungen AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76bd4ed0-fc5d-11e7-9808-af234f0d0819',
    label: 'AT - Merkur Versicherung AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76c46a9e-fc5d-11e7-bdf2-5d521de3a382',
    label: 'AT - Raiffeisen Versicherung AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76cb4f80-fc5d-11e7-a22b-058c49d1ee5c',
    label: 'AT - Roland (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76d291fa-fc5d-11e7-8d0a-e9a27d2d4725',
    label: 'AT - UINIQA Versicherung AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76d97e70-fc5d-11e7-8e21-63d0df2a0401',
    label: 'AT - WIENER STÄDTISCHE Versicherung AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76e11ef0-fc5d-11e7-9fd0-292033d2c9b7',
    label: 'AT - Zürich Versicherungs-AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76e88eec-fc5d-11e7-bc33-37639a91da86',
    label: 'AT - Wuestenrot Versicherung (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76effa60-fc5d-11e7-8acc-f9c86f391b27',
    label: 'AT - Zürich Versicherungs-Aktiengesellschaft (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76f8a0c0-fc5d-11e7-8557-67b506919d5d',
    label: 'AUXILIA Rechtschutz-Versicherungs-AG',
  },
  {
    value: '77006102-fc5d-11e7-9e6a-d5b6581f17f6',
    label: 'AvD Automobilclub von Deutschland',
  },
  {
    value: '77081c94-fc5d-11e7-9b05-47c6118428a9',
    label: 'AXA Versicherung AG',
  },
  {
    value: '77103564-fc5d-11e7-bf8e-f7702526cfff',
    label: 'Badische Allgemeine Versicherung AG',
  },
  {
    value: '77184d30-fc5d-11e7-a03e-67dc3bee54b7',
    label: 'BavariaDirekt - OVAG',
  },
  {
    value: '77208c66-fc5d-11e7-9c31-89220e1b856e',
    label: 'Bayerische Beamten Versicherung AG',
  },
  {
    value: '7728a82e-fc5d-11e7-ad4b-f1b24f23cc52',
    label: 'BEL - ARAG SE - Branch Belgium',
  },
  {
    value: '7730b9ba-fc5d-11e7-a167-bd5e6f16bce1',
    label: 'Bruderhilfe Sachversicherung AG',
  },
  {
    value: '7739d2de-fc5d-11e7-b3b2-a5fbeae816e1',
    label: 'Bund der Versicherten e.V. (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '77421fb6-fc5d-11e7-8997-03a84427c228',
    label: 'CH - Assista Rechtsschutz AG (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '774a7f12-fc5d-11e7-99c2-fb0f26d33e55',
    label: 'CH - Coop Rechtsschutz AG  (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '775353a8-fc5d-11e7-b9a8-27c5bb2225c1',
    label: 'CH - D.A.S Leistungsabteilung (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '775bd352-fc5d-11e7-b1b4-9b5c404204ec',
    label: 'CH - Dextra Rechtsschutz AG (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '7764a3ce-fc5d-11e7-a68f-67d7147516c7',
    label: 'CH - Protekta Rechtsschutz-Versicherung AG (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '776d9772-fc5d-11e7-ba0a-eb9fcdabbd9b',
    label: 'CH AXA Versicherung GmbH (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '7775ffb6-fc5d-11e7-849b-93063e527c1a',
    label: 'ConceptIF Pro GmbH',
  },
  {
    value: '777fb6c8-fc5d-11e7-90ce-65395ec4bd9c',
    label: 'Concordia Rechtsschutz-Versicherung-AG',
  },
  { value: '77891cfe-fc5d-11e7-929a-1b78ceb3f204', label: 'Confident Limburg' },
  { value: '77923514-fc5d-11e7-91e8-1d4ba21085c7', label: 'CosmosDirekt' },
  {
    value: '779b1bc0-fc5d-11e7-85c9-e3f33a9076d7',
    label: 'D.A.S. Leistungsabteilung',
  },
  { value: '77a49f74-fc5d-11e7-9193-db09f73db3fc', label: 'DA Direkt' },
  {
    value: '77addbde-fc5d-11e7-89c0-e7152fc77311',
    label: 'DBwV - Deutscher Bundeswehr-Verband E.V.',
  },
  {
    value: '77b76e60-fc5d-11e7-b940-23152ca2f327',
    label: 'Debeka Allgemeine Versicherung AG',
  },
  {
    value: '77c0d1da-fc5d-11e7-a0e6-139be019fcd0',
    label: 'Degenia Versicherungsdienst AG',
  },
  {
    value: '77ca8f40-fc5d-11e7-b71c-7f461d80f210',
    label: 'DEMA Deutsche Versicherungsmakler',
  },
  { value: '77d3d456-fc5d-11e7-a649-512e85715b53', label: 'Dentassec' },
  {
    value: '77dfcd24-fc5d-11e7-b460-77c9f71e1de0',
    label: 'DEURAG Deutsche Rechtsschutz-Versicherung AG',
  },
  {
    value: '8d9138fc-2f83-11ed-9d1b-966273a2ce72',
    label: 'Deutsche Direkt Versicherung',
  },
  {
    value: '77ea7846-fc5d-11e7-b868-ff9b3d07457d',
    label: 'DEVK Rechtsschutz-Versicherungs-AG',
  },
  {
    value: '77f46c5c-fc5d-11e7-9b7d-fff5c952e864',
    label: 'DFV Deutsche Familienversicherung AG',
  },
  { value: '77fe55d2-fc5d-11e7-9217-99768e107b80', label: 'Die Bayerische' },
  { value: '78086748-fc5d-11e7-b3d3-b79aa8a7c0b5', label: 'Die Continentale' },
  { value: '7812b20c-fc5d-11e7-b943-1bbd28f9cfa4', label: 'die Mobiliar' },
  { value: '781fa0c0-fc5d-11e7-a78d-abdc7140eb46', label: 'Die Stuttgarter' },
  { value: '7829818a-fc5d-11e7-8dc3-31043c54d000', label: 'Direct Line' },
  {
    value: '78339fc6-fc5d-11e7-aa81-eb5d61945ba2',
    label: 'DMB Rechtsschutz-Versicherung AG',
  },
  {
    value: '783dd752-fc5d-11e7-9177-9519912711bb',
    label: 'Domcura Rechtsschutz AG',
  },
  {
    value: '78483df0-fc5d-11e7-889f-8b490ba07b0c',
    label: 'Donau vienna insurance group',
  },
  { value: '7852e674-fc5d-11e7-aa98-651999bd9dd2', label: 'ERGO Versicherung' },
  { value: '785d262a-fc5d-11e7-8802-c394311d88c8', label: 'Europa Service' },
  { value: '78682b1a-fc5d-11e7-b46b-1b3f45c91d93', label: 'EVG' },
  {
    value: '78731f98-fc5d-11e7-8801-353e80293743',
    label: 'Expert Versicherungs-Service GmbH',
  },
  {
    value: '787d4c16-fc5d-11e7-9506-4b16b9f07486',
    label: 'Ford Versicherungs-Vermittlungs-GmbH',
  },
  {
    value: '78876a5c-fc5d-11e7-8523-f7bcd7d67682',
    label: 'Garanta Versicherungs AG',
  },
  {
    value: '7892468e-fc5d-11e7-a05f-5bbe8011b757',
    label: 'Generali Versicherung AG',
  },
  {
    value: '789ce2b0-fc5d-11e7-82dc-91fa4baae5b6',
    label: 'Gothaer Allgemeine Versicherung AG',
  },
  { value: '78a778f6-fc5d-11e7-8d25-cd0aa740dfd2', label: 'GUV Fakulta' },
  { value: '78b265d6-fc5d-11e7-8a99-a312d5915bb9', label: 'GVO' },
  {
    value: '78bd5f40-fc5d-11e7-bf99-93005ff46e2a',
    label: 'Haftpflichtkasse Darmstadt',
  },
  {
    value: '78c863e0-fc5d-11e7-bf7f-b33ef85affc7',
    label: 'Hamburg-Mannheimer',
  },
  {
    value: '78d37cd0-fc5d-11e7-800e-ebbbbfd1ac25',
    label: 'Hannoversche Direktversicherung AG',
  },
  {
    value: '78de94ee-fc5d-11e7-b02f-779ceaeb9c06',
    label: 'HDI Schadenregulierung Global GmbH',
  },
  {
    value: '78ea4596-fc5d-11e7-96b1-078d17531258',
    label: 'Helvetia Schweizerische Versicherungsgesellschaft AG',
  },
  { value: '78f5f968-fc5d-11e7-ad42-611e60370a53', label: 'Henkel & Lares' },
  {
    value: '7902ab5e-fc5d-11e7-bc61-c16bea520fef',
    label: 'HUK-COBURG-Rechtsschutzversicherung AG',
  },
  {
    value: '790f363a-fc5d-11e7-8d0e-73ada7f02cfc',
    label: 'Ideal Versicherung',
  },
  {
    value: '791b3d54-fc5d-11e7-a111-f78fe457cb3e',
    label: 'Itzehoer Rechtsschutz Union Schadenservice GmbH',
  },
  {
    value: '7927a328-fc5d-11e7-be90-f7acea9cb479',
    label: 'Janitos Versicherung AG',
  },
  {
    value: '7933790a-fc5d-11e7-9a06-17ee75904cea',
    label: 'Jurpartner Rechtsschutz-Versicherung AG',
  },
  {
    value: '7940457c-fc5d-11e7-9af0-eba6ea4affd5',
    label: 'Karlsruher Rechtsschutzversicherung AG',
  },
  {
    value: '794c3d32-fc5d-11e7-aa40-09a2ba903d69',
    label: 'KVV Konzis Versicherungs-Vermittlung GmbH',
  },
  {
    value: '7963d096-fc5d-11e7-bd4e-cfcf886d499c',
    label: 'Lippische Landes-Brandversicherung',
  },
  {
    value: '7970604a-fc5d-11e7-8b31-3bd27ceb925f',
    label: 'LSH Landesschadenhilfe Versicherung VaG',
  },
  {
    value: '797ce784-fc5d-11e7-a480-4bc25e0946f2',
    label: 'LVM-Rechtsschutzversicherungs-AG',
  },
  {
    value: '79895122-fc5d-11e7-bd93-d15c45c30140',
    label: 'Malburg Versicherungen GmbH',
  },
  {
    value: '799589d8-fc5d-11e7-9907-b731d3398673',
    label: 'Mannheimer Versicherungs AG',
  },
  {
    value: '79a3bf6c-fc5d-11e7-ba8e-153729b8ed83',
    label: 'Mecklenburgische Versicherung',
  },
  {
    value: '79b1198c-fc5d-11e7-8c45-ab0b0c5a2f9c',
    label: 'Medien-Versicherung a.G.',
  },
  {
    value: '79be3a9a-fc5d-11e7-a78d-8b2af66a99cd',
    label: 'Mercedes-Benz Bank AG',
  },
  {
    value: '79ca8bba-fc5d-11e7-b30a-a7c46b002015',
    label: 'Neue Rechtsschutz-Versicherungsgesellschaft AG',
  },
  {
    value: '79e54a9a-fc5d-11e7-a395-fdd89f3623ba',
    label: 'NL - DAS Rechtsbijstand (Achtung: ausschließlich Niederlande)',
  },
  {
    value: '79f4eb8a-fc5d-11e7-b1d3-4dbdef0453e7',
    label: 'NL - SRK Rechtsbijstand (Achtung: ausschließlich Niederlande)',
  },
  { value: '7a0258d8-fc5d-11e7-8935-3136a014a3dd', label: 'NORDVERS GmbH' },
  {
    value: '7a10d5ca-fc5d-11e7-81e4-c92d85bd763f',
    label: 'Nordvers-Versicherungsgesellschaft AG',
  },
  {
    value: '7a1f8e26-fc5d-11e7-a0d1-dd875f2bf40b',
    label: 'Nürnberger Allgemeine Versicherungs-AG',
  },
  { value: '7a2d4534-fc5d-11e7-8465-fb6de743575c', label: 'Onesyty Direct' },
  {
    value: '7a3b62c2-fc5d-11e7-a117-3777fb76dbf0',
    label: 'ÖRAG Rechtschutzversicherungs-AG',
  },
  {
    value: '7a491bba-fc5d-11e7-8865-574211b277a9',
    label: 'Orion Rechtsschutz-Versicherung AG',
  },
  {
    value: '7a56f046-fc5d-11e7-b758-3b785a3be22d',
    label: 'ÖSA Versicherungen',
  },
  {
    value: '7a647b80-fc5d-11e7-bb78-2b8e607a363e',
    label: 'ÖVB Versicherungen',
  },
  { value: '7a750838-fc5d-11e7-b8bc-b3d79ae3919f', label: 'PharmAssec GmbH' },
  { value: '7a82e002-fc5d-11e7-9837-31bee38ec613', label: 'Prokundo' },
  {
    value: '7a9232f0-fc5d-11e7-9c04-19e1b0f7603a',
    label: 'Provinzial Rheinland Versicherung AG',
  },
  {
    value: '7aa0199c-fc5d-11e7-957a-bf8fcc17aa29',
    label: 'R+V Versicherung AG',
  },
  {
    value: '7aaddcd0-fc5d-11e7-8baa-47256792e93b',
    label: 'Rechtsschutz Union',
  },
  {
    value: '7abddd74-fc5d-11e7-bcd3-75f38a68d8b9',
    label: 'ROLAND Rechtsschutz-Versicherungs-AG',
  },
  {
    value: '7acddc6a-fc5d-11e7-a7f2-83757e2242a1',
    label: 'Schleswiger Versicherungsservice AG',
  },
  {
    value: '7adc853a-fc5d-11e7-94e1-31a044bf5d85',
    label: 'SIGNAL IDUNA Gruppe',
  },
  {
    value: '7aeb236a-fc5d-11e7-9f26-d19257ed4bd6',
    label: 'Sparkassen Versicherung Stuttgart',
  },
  {
    value: '7af9c712-fc5d-11e7-a8fe-f9d92b834a1f',
    label: 'Standard Life Assurance Limited',
  },
  {
    value: '7b082c6c-fc5d-11e7-8662-bb60d47f0bf1',
    label: 'TCS Assista Rechtsschutz AG',
  },
  {
    value: '7b17014c-fc5d-11e7-8140-a3b4beb7bb66',
    label: 'Techno Versicherungsdienst GmbH',
  },
  {
    value: '7b251822-fc5d-11e7-b7ad-fb7a56cb3308',
    label: 'Uelzener Versicherung',
  },
  {
    value: '7b334dde-fc5d-11e7-957b-9f9063242092',
    label: 'Union Versicherungsdienst GmbH',
  },
  {
    value: '7b416e00-fc5d-11e7-b0fb-f98b4097686f',
    label: 'Versicherungskammer Bayern',
  },
  {
    value: '7b50a19a-fc5d-11e7-9b0e-9d178877f05a',
    label: 'Verti Versicherung AG',
  },
  {
    value: '7b5fbf4a-fc5d-11e7-9f08-87af88ee20a2',
    label: 'VGH Rechtsschutzschadensservice GmbH',
  },
  {
    value: '7b6ec9d6-fc5d-11e7-a3cc-f340c7414860',
    label: 'VHV Allgemeine Versicherung AG',
  },
  { value: '7b7f6476-fc5d-11e7-bc1b-b560defba1da', label: 'Volksfürsorge' },
  {
    value: '7b8e972a-fc5d-11e7-8661-ef9186139aa7',
    label: 'Westfälische Provinzial',
  },
  {
    value: '7b9e507a-fc5d-11e7-8ec0-39891754f8e0',
    label: 'WGV-Versicherung AG',
  },
  {
    value: '7bad9008-fc5d-11e7-a636-d3c410cc0b4d',
    label: 'Württembergische Rechtsschutz Schaden-Service-GmbH',
  },
  {
    value: '7bc3b81a-fc5d-11e7-bff7-b55099c3e4f4',
    label: 'Zurich Rechtsschutz-Schadenservice GmbH',
  },
]

export const INSURANCE_CHOICES_RUFF = [
  {
    value: '75c51594-fc5d-11e7-975e-735ac968834e',
    label: 'Aachen Muenchener Versicherung AG',
  },
  {
    value: '76499f44-fc5d-11e7-b044-c5b460fe3a4c',
    label: 'ACE',
  },
  {
    value: '7655cdaa-fc5d-11e7-b3ca-3920795a8d51',
    label: 'ADAC-Rechtsschutz Versicherungs-AG',
  },
  {
    value: '765c4a2c-fc5d-11e7-bdc1-f372c27e1b38',
    label: 'ADVOCARD Rechtsschutzversicherung AG',
  },
  {
    value: '76685c22-fc5d-11e7-9c7e-9d7056177279',
    label: 'Allianz RS-Service GmbH Rechtsschutzversicherung',
  },
  {
    value: '766ea154-fc5d-11e7-b534-07e92dc73772',
    label: 'ALLRECHT Rechtsschutzversicherung',
  },
  {
    value: '7674f4b4-fc5d-11e7-916d-53bce72c5b03',
    label: 'Alte Leipziger Versicherung AG',
  },
  { value: '767afa58-fc5d-11e7-8ec7-95991e229d9d', label: 'AON Versicherung' },
  {
    value: '7680e490-fc5d-11e7-a3b3-033ff0197fe0',
    label: 'ARAG SE ARAG Allgemeine Versicherungs-AG',
  },
  {
    value: '768750be-fc5d-11e7-a974-dfa9e95da821',
    label: 'ARAG SE Direktion für Österreich',
  },
  {
    value: '768e1a52-fc5d-11e7-a7f1-0d4b9711e9dc',
    label: 'ASE Automobil Service Europa',
  },
  { value: '76946268-fc5d-11e7-893c-9f4b8b3315e3', label: 'Askuma AG' },
  {
    value: '769ab424-fc5d-11e7-88fb-17ee8d7b9920',
    label: 'AT - Allianz Elementar Versicherungs-AG Wien (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76a139ca-fc5d-11e7-b5be-d90c0a9c72c1',
    label: 'AT - D.A.S. Rechtsschutz AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76a8d504-fc5d-11e7-a186-f18ad86e56a2',
    label: 'AT - Generali Gruppe (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76aff2ee-fc5d-11e7-ac0b-d9b835656d61',
    label: 'AT - HDI (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76b6d4ce-fc5d-11e7-8170-eb125fb0a96e',
    label: 'AT - Helvetia Versicherungen AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76bd4ed0-fc5d-11e7-9808-af234f0d0819',
    label: 'AT - Merkur Versicherung AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76c46a9e-fc5d-11e7-bdf2-5d521de3a382',
    label: 'AT - Raiffeisen Versicherung AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76cb4f80-fc5d-11e7-a22b-058c49d1ee5c',
    label: 'AT - Roland (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76d291fa-fc5d-11e7-8d0a-e9a27d2d4725',
    label: 'AT - UINIQA Versicherung AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76d97e70-fc5d-11e7-8e21-63d0df2a0401',
    label: 'AT - WIENER STÄDTISCHE Versicherung AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76e11ef0-fc5d-11e7-9fd0-292033d2c9b7',
    label: 'AT - Zürich Versicherungs-AG (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76e88eec-fc5d-11e7-bc33-37639a91da86',
    label: 'AT - Wuestenrot Versicherung (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76effa60-fc5d-11e7-8acc-f9c86f391b27',
    label: 'AT - Zürich Versicherungs-Aktiengesellschaft (Achtung: ausschließlich Österreich)',
  },
  {
    value: '76f8a0c0-fc5d-11e7-8557-67b506919d5d',
    label: 'AUXILIA Rechtschutz-Versicherungs-AG',
  },
  {
    value: '77006102-fc5d-11e7-9e6a-d5b6581f17f6',
    label: 'AvD Automobilclub von Deutschland',
  },
  {
    value: '77081c94-fc5d-11e7-9b05-47c6118428a9',
    label: 'AXA Versicherung AG',
  },
  {
    value: '77103564-fc5d-11e7-bf8e-f7702526cfff',
    label: 'Badische Allgemeine Versicherung AG',
  },
  {
    value: '77184d30-fc5d-11e7-a03e-67dc3bee54b7',
    label: 'BavariaDirekt - OVAG',
  },
  {
    value: '77208c66-fc5d-11e7-9c31-89220e1b856e',
    label: 'Bayerische Beamten Versicherung AG',
  },
  {
    value: '7728a82e-fc5d-11e7-ad4b-f1b24f23cc52',
    label: 'BEL - ARAG SE - Branch Belgium',
  },
  {
    value: '7730b9ba-fc5d-11e7-a167-bd5e6f16bce1',
    label: 'Bruderhilfe Sachversicherung AG',
  },
  {
    value: '7739d2de-fc5d-11e7-b3b2-a5fbeae816e1',
    label: 'Bund der Versicherten e.V. (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '77421fb6-fc5d-11e7-8997-03a84427c228',
    label: 'CH - Assista Rechtsschutz AG (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '774a7f12-fc5d-11e7-99c2-fb0f26d33e55',
    label: 'CH - Coop Rechtsschutz AG  (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '775353a8-fc5d-11e7-b9a8-27c5bb2225c1',
    label: 'CH - D.A.S Leistungsabteilung (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '775bd352-fc5d-11e7-b1b4-9b5c404204ec',
    label: 'CH - Dextra Rechtsschutz AG (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '7764a3ce-fc5d-11e7-a68f-67d7147516c7',
    label: 'CH - Protekta Rechtsschutz-Versicherung AG (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '776d9772-fc5d-11e7-ba0a-eb9fcdabbd9b',
    label: 'CH AXA Versicherung GmbH (Achtung: ausschließlich Schweiz)',
  },
  {
    value: '7775ffb6-fc5d-11e7-849b-93063e527c1a',
    label: 'ConceptIF Pro GmbH',
  },
  {
    value: '777fb6c8-fc5d-11e7-90ce-65395ec4bd9c',
    label: 'Concordia Rechtsschutz-Versicherung-AG',
  },
  { value: '77891cfe-fc5d-11e7-929a-1b78ceb3f204', label: 'Confident Limburg' },
  { value: '77923514-fc5d-11e7-91e8-1d4ba21085c7', label: 'CosmosDirekt' },
  {
    value: '779b1bc0-fc5d-11e7-85c9-e3f33a9076d7',
    label: 'D.A.S. Leistungsabteilung',
  },
  { value: '77a49f74-fc5d-11e7-9193-db09f73db3fc', label: 'DA Direkt' },
  {
    value: '77addbde-fc5d-11e7-89c0-e7152fc77311',
    label: 'DBwV - Deutscher Bundeswehr-Verband E.V.',
  },
  {
    value: '77b76e60-fc5d-11e7-b940-23152ca2f327',
    label: 'Debeka Allgemeine Versicherung AG',
  },
  {
    value: '77c0d1da-fc5d-11e7-a0e6-139be019fcd0',
    label: 'Degenia Versicherungsdienst AG',
  },
  {
    value: '77ca8f40-fc5d-11e7-b71c-7f461d80f210',
    label: 'DEMA Deutsche Versicherungsmakler',
  },
  { value: '77d3d456-fc5d-11e7-a649-512e85715b53', label: 'Dentassec' },
  {
    value: '77dfcd24-fc5d-11e7-b460-77c9f71e1de0',
    label: 'DEURAG Deutsche Rechtsschutz-Versicherung AG',
  },
  {
    value: '8d9138fc-2f83-11ed-9d1b-966273a2ce72',
    label: 'Deutsche Direkt Versicherung',
  },
  {
    value: '77ea7846-fc5d-11e7-b868-ff9b3d07457d',
    label: 'DEVK Rechtsschutz-Versicherungs-AG',
  },
  {
    value: '77f46c5c-fc5d-11e7-9b7d-fff5c952e864',
    label: 'DFV Deutsche Familienversicherung AG',
  },
  { value: '77fe55d2-fc5d-11e7-9217-99768e107b80', label: 'Die Bayerische' },
  { value: '78086748-fc5d-11e7-b3d3-b79aa8a7c0b5', label: 'Die Continentale' },
  { value: '7812b20c-fc5d-11e7-b943-1bbd28f9cfa4', label: 'die Mobiliar' },
  { value: '781fa0c0-fc5d-11e7-a78d-abdc7140eb46', label: 'Die Stuttgarter' },
  { value: '7829818a-fc5d-11e7-8dc3-31043c54d000', label: 'Direct Line' },
  {
    value: '78339fc6-fc5d-11e7-aa81-eb5d61945ba2',
    label: 'DMB Rechtsschutz-Versicherung AG',
  },
  {
    value: '783dd752-fc5d-11e7-9177-9519912711bb',
    label: 'Domcura Rechtsschutz AG',
  },
  {
    value: '78483df0-fc5d-11e7-889f-8b490ba07b0c',
    label: 'Donau vienna insurance group',
  },
  { value: '7852e674-fc5d-11e7-aa98-651999bd9dd2', label: 'ERGO Versicherung' },
  { value: '785d262a-fc5d-11e7-8802-c394311d88c8', label: 'Europa Service' },
  { value: '78682b1a-fc5d-11e7-b46b-1b3f45c91d93', label: 'EVG' },
  {
    value: '78731f98-fc5d-11e7-8801-353e80293743',
    label: 'Expert Versicherungs-Service GmbH',
  },
  {
    value: '787d4c16-fc5d-11e7-9506-4b16b9f07486',
    label: 'Ford Versicherungs-Vermittlungs-GmbH',
  },
  {
    value: '78876a5c-fc5d-11e7-8523-f7bcd7d67682',
    label: 'Garanta Versicherungs AG',
  },
  {
    value: '7892468e-fc5d-11e7-a05f-5bbe8011b757',
    label: 'Generali Versicherung AG',
  },
  {
    value: '789ce2b0-fc5d-11e7-82dc-91fa4baae5b6',
    label: 'Gothaer Allgemeine Versicherung AG',
  },
  { value: '78a778f6-fc5d-11e7-8d25-cd0aa740dfd2', label: 'GUV Fakulta' },
  { value: '78b265d6-fc5d-11e7-8a99-a312d5915bb9', label: 'GVO' },
  {
    value: '78bd5f40-fc5d-11e7-bf99-93005ff46e2a',
    label: 'Haftpflichtkasse Darmstadt',
  },
  {
    value: '78c863e0-fc5d-11e7-bf7f-b33ef85affc7',
    label: 'Hamburg-Mannheimer',
  },
  {
    value: '78d37cd0-fc5d-11e7-800e-ebbbbfd1ac25',
    label: 'Hannoversche Direktversicherung AG',
  },
  {
    value: '78de94ee-fc5d-11e7-b02f-779ceaeb9c06',
    label: 'HDI Schadenregulierung Global GmbH',
  },
  {
    value: '78ea4596-fc5d-11e7-96b1-078d17531258',
    label: 'Helvetia Schweizerische Versicherungsgesellschaft AG',
  },
  { value: '78f5f968-fc5d-11e7-ad42-611e60370a53', label: 'Henkel & Lares' },
  {
    value: '7902ab5e-fc5d-11e7-bc61-c16bea520fef',
    label: 'HUK-COBURG-Rechtsschutzversicherung AG',
  },
  {
    value: '790f363a-fc5d-11e7-8d0e-73ada7f02cfc',
    label: 'Ideal Versicherung',
  },
  {
    value: '791b3d54-fc5d-11e7-a111-f78fe457cb3e',
    label: 'Itzehoer Rechtsschutz Union Schadenservice GmbH',
  },
  {
    value: '7927a328-fc5d-11e7-be90-f7acea9cb479',
    label: 'Janitos Versicherung AG',
  },
  {
    value: '7933790a-fc5d-11e7-9a06-17ee75904cea',
    label: 'Jurpartner Rechtsschutz-Versicherung AG',
  },
  {
    value: '7940457c-fc5d-11e7-9af0-eba6ea4affd5',
    label: 'Karlsruher Rechtsschutzversicherung AG',
  },
  {
    value: '794c3d32-fc5d-11e7-aa40-09a2ba903d69',
    label: 'KVV Konzis Versicherungs-Vermittlung GmbH',
  },
  {
    value: '7963d096-fc5d-11e7-bd4e-cfcf886d499c',
    label: 'Lippische Landes-Brandversicherung',
  },
  {
    value: '7970604a-fc5d-11e7-8b31-3bd27ceb925f',
    label: 'LSH Landesschadenhilfe Versicherung VaG',
  },
  {
    value: '797ce784-fc5d-11e7-a480-4bc25e0946f2',
    label: 'LVM-Rechtsschutzversicherungs-AG',
  },
  {
    value: '79895122-fc5d-11e7-bd93-d15c45c30140',
    label: 'Malburg Versicherungen GmbH',
  },
  {
    value: '799589d8-fc5d-11e7-9907-b731d3398673',
    label: 'Mannheimer Versicherungs AG',
  },
  {
    value: '79a3bf6c-fc5d-11e7-ba8e-153729b8ed83',
    label: 'Mecklenburgische Versicherung',
  },
  {
    value: '79b1198c-fc5d-11e7-8c45-ab0b0c5a2f9c',
    label: 'Medien-Versicherung a.G.',
  },
  {
    value: '79be3a9a-fc5d-11e7-a78d-8b2af66a99cd',
    label: 'Mercedes-Benz Bank AG',
  },
  {
    value: '79ca8bba-fc5d-11e7-b30a-a7c46b002015',
    label: 'Neue Rechtsschutz-Versicherungsgesellschaft AG',
  },
  {
    value: '79e54a9a-fc5d-11e7-a395-fdd89f3623ba',
    label: 'NL - DAS Rechtsbijstand (Achtung: ausschließlich Niederlande)',
  },
  {
    value: '79f4eb8a-fc5d-11e7-b1d3-4dbdef0453e7',
    label: 'NL - SRK Rechtsbijstand (Achtung: ausschließlich Niederlande)',
  },
  { value: '7a0258d8-fc5d-11e7-8935-3136a014a3dd', label: 'NORDVERS GmbH' },
  {
    value: '7a10d5ca-fc5d-11e7-81e4-c92d85bd763f',
    label: 'Nordvers-Versicherungsgesellschaft AG',
  },
  {
    value: '7a1f8e26-fc5d-11e7-a0d1-dd875f2bf40b',
    label: 'Nürnberger Allgemeine Versicherungs-AG',
  },
  { value: '7a2d4534-fc5d-11e7-8465-fb6de743575c', label: 'Onesyty Direct' },
  {
    value: '7a3b62c2-fc5d-11e7-a117-3777fb76dbf0',
    label: 'ÖRAG Rechtschutzversicherungs-AG',
  },
  {
    value: '7a491bba-fc5d-11e7-8865-574211b277a9',
    label: 'Orion Rechtsschutz-Versicherung AG',
  },
  {
    value: '7a56f046-fc5d-11e7-b758-3b785a3be22d',
    label: 'ÖSA Versicherungen',
  },
  {
    value: '7a647b80-fc5d-11e7-bb78-2b8e607a363e',
    label: 'ÖVB Versicherungen',
  },
  { value: '7a750838-fc5d-11e7-b8bc-b3d79ae3919f', label: 'PharmAssec GmbH' },
  { value: '7a82e002-fc5d-11e7-9837-31bee38ec613', label: 'Prokundo' },
  {
    value: '7a9232f0-fc5d-11e7-9c04-19e1b0f7603a',
    label: 'Provinzial Rheinland Versicherung AG',
  },
  {
    value: '7aa0199c-fc5d-11e7-957a-bf8fcc17aa29',
    label: 'R+V Versicherung AG',
  },
  {
    value: '7aaddcd0-fc5d-11e7-8baa-47256792e93b',
    label: 'Rechtsschutz Union',
  },
  {
    value: '7abddd74-fc5d-11e7-bcd3-75f38a68d8b9',
    label: 'ROLAND Rechtsschutz-Versicherungs-AG',
  },
  {
    value: '7acddc6a-fc5d-11e7-a7f2-83757e2242a1',
    label: 'Schleswiger Versicherungsservice AG',
  },
  {
    value: '7adc853a-fc5d-11e7-94e1-31a044bf5d85',
    label: 'SIGNAL IDUNA Gruppe',
  },
  {
    value: '7aeb236a-fc5d-11e7-9f26-d19257ed4bd6',
    label: 'Sparkassen Versicherung Stuttgart',
  },
  {
    value: '7af9c712-fc5d-11e7-a8fe-f9d92b834a1f',
    label: 'Standard Life Assurance Limited',
  },
  {
    value: '7b082c6c-fc5d-11e7-8662-bb60d47f0bf1',
    label: 'TCS Assista Rechtsschutz AG',
  },
  {
    value: '7b17014c-fc5d-11e7-8140-a3b4beb7bb66',
    label: 'Techno Versicherungsdienst GmbH',
  },
  {
    value: '7b251822-fc5d-11e7-b7ad-fb7a56cb3308',
    label: 'Uelzener Versicherung',
  },
  {
    value: '7b334dde-fc5d-11e7-957b-9f9063242092',
    label: 'Union Versicherungsdienst GmbH',
  },
  {
    value: '7b416e00-fc5d-11e7-b0fb-f98b4097686f',
    label: 'Versicherungskammer Bayern',
  },
  {
    value: '7b50a19a-fc5d-11e7-9b0e-9d178877f05a',
    label: 'Verti Versicherung AG',
  },
  {
    value: '7b5fbf4a-fc5d-11e7-9f08-87af88ee20a2',
    label: 'VGH Rechtsschutzschadensservice GmbH',
  },
  {
    value: '7b6ec9d6-fc5d-11e7-a3cc-f340c7414860',
    label: 'VHV Allgemeine Versicherung AG',
  },
  { value: '7b7f6476-fc5d-11e7-bc1b-b560defba1da', label: 'Volksfürsorge' },
  {
    value: '7b8e972a-fc5d-11e7-8661-ef9186139aa7',
    label: 'Westfälische Provinzial',
  },
  {
    value: '7b9e507a-fc5d-11e7-8ec0-39891754f8e0',
    label: 'WGV-Versicherung AG',
  },
  {
    value: '7bad9008-fc5d-11e7-a636-d3c410cc0b4d',
    label: 'Württembergische Rechtsschutz Schaden-Service-GmbH',
  },
  {
    value: '7bc3b81a-fc5d-11e7-bff7-b55099c3e4f4',
    label: 'Zurich Rechtsschutz-Schadenservice GmbH',
  },
]

export const INSURANCE_CHOICES_RIGHTMART = [
  {
    value: '334bd008-7322-11ee-a005-0242ac1e0003',
    label: 'Aachen Muenchener Versicherung AG',
  },
  {
    value: '33772686-7322-11ee-888f-0242ac1e0003',
    label: 'ACE (Advocard)',
  },
  {
    value: '339eba0c-7322-11ee-adcb-0242ac1e0003',
    label: 'ADAC-Rechtsschutz Versicherungs-AG',
  },
  {
    value: 'c5cde216-7ade-437e-a1c9-c5f959f3fc70',
    label: 'Adam Riese GmbH',
  },
  {
    value: '33b3b68c-7322-11ee-af40-0242ac1e0003',
    label: 'ADVOCARD Rechtsschutzversicherung AG',
  },
  {
    value: '33c882e2-7322-11ee-8681-0242ac1e0003',
    label: 'Aktiv Assekuranz',
  },
  {
    value: '33dceca0-7322-11ee-974b-0242ac1e0003',
    label: 'Allianz RS-Service GmbH Rechtsschutzversicherung',
  },
  {
    value: '33f146be-7322-11ee-bfe9-0242ac1e0003',
    label: 'ALLRECHT Rechtsschutzversicherung',
  },
  {
    value: '3405df8e-7322-11ee-ac23-0242ac1e0003',
    label: 'Alte Leipziger Versicherung AG',
  },
  {
    value: '341a9d3e-7322-11ee-8160-0242ac1e0003',
    label: 'AON Versicherung',
  },
  {
    value: '342f0e5e-7322-11ee-814c-0242ac1e0003',
    label: 'ARAG SE ARAG Allgemeine Versicherungs-AG',
  },
  {
    value: '34441c0e-7322-11ee-9c95-0242ac1e0003',
    label: 'ARAG SE Direktion Österreich',
  },
  {
    value: '3459443a-7322-11ee-a303-0242ac1e0003',
    label: 'ASE Automobil Service Europa',
  },
  {
    value: '346e490c-7322-11ee-b7f8-0242ac1e0003',
    label: 'Askuma AG',
  },
  {
    value: '3483d65a-7322-11ee-8a34-0242ac1e0003',
    label: 'AT - Allianz Elementar Versicherungs-AG Wien',
  },
  {
    value: '34996f10-7322-11ee-92a4-0242ac1e0003',
    label: 'AT - D.A.S. Rechtsschutz AG',
  },
  {
    value: '34af31a6-7322-11ee-b359-0242ac1e0003',
    label: 'AT - Generali Gruppe',
  },
  {
    value: '34c4ec58-7322-11ee-adf8-0242ac1e0003',
    label: 'AT - HDI',
  },
  {
    value: '34db06fa-7322-11ee-aea3-0242ac1e0003',
    label: 'AT - Helvetia Versicherungen AG',
  },
  {
    value: '34f139ac-7322-11ee-bc77-0242ac1e0003',
    label: 'AT - Merkur Versicherung AG',
  },
  {
    value: '3507aad4-7322-11ee-a01d-0242ac1e0003',
    label: 'AT - Raiffeisen Versicherung AG',
  },
  {
    value: '351e2d22-7322-11ee-b954-0242ac1e0003',
    label: 'AT - Roland',
  },
  {
    value: '3535a3da-7322-11ee-bf76-0242ac1e0003',
    label: 'AT - UINIQA Versicherung AG',
  },
  {
    value: '354da0a2-7322-11ee-9716-0242ac1e0003',
    label: 'AT - WIENER STÄDTISCHE Versicherung AG',
  },
  {
    value: '356466b6-7322-11ee-8e0a-0242ac1e0003',
    label: 'AT - Zürich Versicherungs-AG',
  },
  {
    value: '357b9a84-7322-11ee-ab1e-0242ac1e0003',
    label: 'AT- Wuestenrot Versicherung',
  },
  {
    value: '359341b6-7322-11ee-bdcf-0242ac1e0003',
    label: 'AT-Zürich Versicherungs-Aktiengesellschaft',
  },
  {
    value: '35aa93de-7322-11ee-9df8-0242ac1e0003',
    label: 'AUXILIA Rechtschutz-Versicherungs-AG',
  },
  {
    value: '35c2aa32-7322-11ee-bcc8-0242ac1e0003',
    label: 'AvD Automobilclub von Deutschland',
  },
  {
    value: '35da79aa-7322-11ee-bc2f-0242ac1e0003',
    label: 'AXA Versicherung AG',
  },
  {
    value: '35f25ef8-7322-11ee-8eb3-0242ac1e0003',
    label: 'Badische Allgemeine Versicherung AG',
  },
  {
    value: '360a8582-7322-11ee-9dbd-0242ac1e0003',
    label: 'BavariaDirekt - OVAG',
  },
  {
    value: '3622f5b8-7322-11ee-b278-0242ac1e0003',
    label: 'Bayerische Beamten Versicherung AG',
  },
  {
    value: '363b7610-7322-11ee-8b48-0242ac1e0003',
    label: 'BEL - ARAG SE - Branch Belgium',
  },
  {
    value: '36540db0-7322-11ee-a256-0242ac1e0003',
    label: 'Bruderhilfe Sachversicherung AG',
  },
  {
    value: '366d29c6-7322-11ee-995f-0242ac1e0003',
    label: 'Bund der Versicherten e.V.',
  },
  {
    value: '3685f32a-7322-11ee-8718-0242ac1e0003',
    label: 'CH - Assista Rechtsschutz AG',
  },
  {
    value: '369ef3f2-7322-11ee-b6a9-0242ac1e0003',
    label: 'CH - Coop Rechtsschutz AG',
  },
  {
    value: '36b88966-7322-11ee-877e-0242ac1e0003',
    label: 'CH - D.A.S Leistungsabteilung',
  },
  {
    value: '36d1d484-7322-11ee-a04c-0242ac1e0003',
    label: 'CH - Dextra Rechtsschutz AG',
  },
  {
    value: '36eb9a5e-7322-11ee-bf33-0242ac1e0003',
    label: 'CH - Protekta Rechtsschutz-Versicherung AG',
  },
  {
    value: '370564ca-7322-11ee-a14e-0242ac1e0003',
    label: 'CH AXA Versicherung GmbH',
  },
  {
    value: '371ef3ea-7322-11ee-9323-0242ac1e0003',
    label: 'ConceptIF Pro GmbH',
  },
  {
    value: '373a06bc-7322-11ee-8688-0242ac1e0003',
    label: 'Concordia Rechtsschutz-Versicherung-AG',
  },
  {
    value: '430ab630-7322-11ee-aac1-0242ac1e0003',
    label: 'Condor Allgemeine Versicherungs-Aktiengesellschaft',
  },
  {
    value: '375472a4-7322-11ee-a702-0242ac1e0003',
    label: 'Confident Limburg',
  },
  {
    value: '376ea82c-7322-11ee-a23b-0242ac1e0003',
    label: 'CosmosDirekt',
  },
  {
    value: '3788f830-7322-11ee-90fd-0242ac1e0003',
    label: 'D.A.S. Leistungsabteilung',
  },
  {
    value: '37a37af2-7322-11ee-982a-0242ac1e0003',
    label: 'DA Direkt',
  },
  {
    value: '37bdf260-7322-11ee-bda4-0242ac1e0003',
    label: 'DBwV - Deutscher Bundeswehr-Verband E.V.',
  },
  {
    value: '37d90f14-7322-11ee-8c68-0242ac1e0003',
    label: 'Debeka Allgemeine Versicherung AG',
  },
  {
    value: '37f45382-7322-11ee-946e-0242ac1e0003',
    label: 'Degenia Versicherungsdienst AG',
  },
  {
    value: '380fc892-7322-11ee-b091-0242ac1e0003',
    label: 'DEMA Deutsche Versicherungsmakler',
  },
  {
    value: '382af518-7322-11ee-895c-0242ac1e0003',
    label: 'DentAssec',
  },
  {
    value: '384662e4-7322-11ee-a1b2-0242ac1e0003',
    label: 'DEURAG Deutsche Rechtsschutz-Versicherung AG',
  },
  {
    value: '38626db8-7322-11ee-9f28-0242ac1e0003',
    label: 'DEVK Rechtsschutz-Versicherungs-AG',
  },
  {
    value: '387ef596-7322-11ee-9351-0242ac1e0003',
    label: 'DFV Deutsche Familienversicherung AG',
  },
  {
    value: '389ae15c-7322-11ee-8907-0242ac1e0003',
    label: 'Die Bayerische',
  },
  {
    value: '38b6a3ec-7322-11ee-af4c-0242ac1e0003',
    label: 'Die Continentale',
  },
  {
    value: '38d2f506-7322-11ee-acf1-0242ac1e0003',
    label: 'die Mobiliar',
  },
  {
    value: '38ef1880-7322-11ee-9304-0242ac1e0003',
    label: 'Die Stuttgarter',
  },
  {
    value: '390b86aa-7322-11ee-9860-0242ac1e0003',
    label: 'Direct Line',
  },
  {
    value: '3928175c-7322-11ee-a5d4-0242ac1e0003',
    label: 'DMB Rechtsschutz-Versicherung AG',
  },
  {
    value: '3944e6ac-7322-11ee-b338-0242ac1e0003',
    label: 'Domcura Rechtsschutz AG',
  },
  {
    value: '396204a8-7322-11ee-90fc-0242ac1e0003',
    label: 'Donau vienna insurance group',
  },
  {
    value: '397f3bd6-7322-11ee-9f8b-0242ac1e0003',
    label: 'ERGO Versicherung',
  },
  {
    value: '399d012a-7322-11ee-a492-0242ac1e0003',
    label: 'Europa Service',
  },
  {
    value: '39ba9956-7322-11ee-a0ca-0242ac1e0003',
    label: 'EVG',
  },
  {
    value: '39d8375e-7322-11ee-9a1a-0242ac1e0003',
    label: 'Expert Versicherungs-Service GmbH',
  },
  {
    value: '39f5d688-7322-11ee-a34b-0242ac1e0003',
    label: 'Ford Versicherungs-Vermittlungs-GmbH',
  },
  {
    value: '3a1397f4-7322-11ee-8a6b-0242ac1e0003',
    label: 'Garanta Versicherungs AG',
  },
  {
    value: '3a31cd96-7322-11ee-bf0a-0242ac1e0003',
    label: 'Generali Versicherung AG',
  },
  {
    value: '3a509c9e-7322-11ee-b14a-0242ac1e0003',
    label: 'Gothaer Allgemeine Versicherung AG',
  },
  {
    value: '3a6f10ac-7322-11ee-9357-0242ac1e0003',
    label: 'GUV Fakulta',
  },
  {
    value: '3a8de7f2-7322-11ee-9c09-0242ac1e0003',
    label: 'GVO',
  },
  {
    value: '3aaca494-7322-11ee-87d2-0242ac1e0003',
    label: 'Haftpflichtkasse Darmstadt',
  },
  {
    value: '3acbf6f0-7322-11ee-8177-0242ac1e0003',
    label: 'Hamburg-Mannheimer',
  },
  {
    value: '3aeb2f70-7322-11ee-bd61-0242ac1e0003',
    label: 'Hannoversche Direktversicherung AG',
  },
  {
    value: '3b0a9252-7322-11ee-9632-0242ac1e0003',
    label: 'HDI Schadenregulierung Global GmbH',
  },
  {
    value: '3b2bc828-7322-11ee-a24d-0242ac1e0003',
    label: 'Helvetia Schweizerische Versicherungsgesellschaft AG',
  },
  {
    value: '3b4bbf70-7322-11ee-bc51-0242ac1e0003',
    label: 'Henkel & Lares',
  },
  {
    value: '3b6b61cc-7322-11ee-b3f1-0242ac1e0003',
    label: 'HUK-COBURG-Rechtsschutzversicherung AG',
  },
  {
    value: '3b8b8b82-7322-11ee-9ecc-0242ac1e0003',
    label: 'Ideal Versicherung',
  },
  {
    value: '3baba35e-7322-11ee-a6c7-0242ac1e0003',
    label: 'Itzehoer Rechtsschutz Union Schadenservice GmbH',
  },
  {
    value: '3bcc811e-7322-11ee-82e9-0242ac1e0003',
    label: 'Janitos Versicherung AG',
  },
  {
    value: '3bed45fc-7322-11ee-b507-0242ac1e0003',
    label: 'Jurpartner Rechtsschutz-Versicherung AG',
  },
  {
    value: '3c0e2cf4-7322-11ee-a852-0242ac1e0003',
    label: 'Karlsruher Rechtsschutzversicherung AG',
  },
  {
    value: '3c2f2ac6-7322-11ee-ae5d-0242ac1e0003',
    label: 'KVV Konzis Versicherungs-Vermittlung GmbH',
  },
  {
    value: '3c74c8ba-7322-11ee-9bc9-0242ac1e0003',
    label: 'Lippische Landes-Brandversicherung',
  },
  {
    value: '3c98580c-7322-11ee-8db0-0242ac1e0003',
    label: 'LSH Landesschadenhilfe Versicherung VaG',
  },
  {
    value: '3cbbd49e-7322-11ee-a0e0-0242ac1e0003',
    label: 'LVM-Rechtsschutzversicherungs-AG',
  },
  {
    value: '3cdf3934-7322-11ee-954c-0242ac1e0003',
    label: 'Malburg Versicherungen GmbH',
  },
  {
    value: '3d02518a-7322-11ee-a407-0242ac1e0003',
    label: 'Mannheimer Versicherungs AG',
  },
  {
    value: '3d24e5b0-7322-11ee-a2de-0242ac1e0003',
    label: 'Mecklenburgische Versicherung',
  },
  {
    value: '3d472bc0-7322-11ee-b3fa-0242ac1e0003',
    label: 'Medien-Versicherung a.G.',
  },
  {
    value: '3d6a2fc6-7322-11ee-861c-0242ac1e0003',
    label: 'Mercedes-Benz Bank AG',
  },
  {
    value: '3d8cc748-7322-11ee-adcc-0242ac1e0003',
    label: 'Neue Rechtsschutz-Versicherungsgesellschaft AG',
  },
  {
    value: '3daf8e2c-7322-11ee-b022-0242ac1e0003',
    label: 'NL - DAS Rechtsbijstand',
  },
  {
    value: '3dd2db84-7322-11ee-b128-0242ac1e0003',
    label: 'NL - SRK Rechtsbijstand',
  },
  {
    value: '3df612e8-7322-11ee-ad43-0242ac1e0003',
    label: 'NORDVERS GmbH',
  },
  {
    value: '3e1a9e7e-7322-11ee-a1f9-0242ac1e0003',
    label: 'Nordvers-Versicherungsgesellschaft AG',
  },
  {
    value: '3e3e4554-7322-11ee-85bf-0242ac1e0003',
    label: 'Nürnberger Allgemeine Versicherungs-AG',
  },
  {
    value: '3e671682-7322-11ee-a96e-0242ac1e0003',
    label: 'Onesyty Direct',
  },
  {
    value: '3e8c1cde-7322-11ee-bb99-0242ac1e0003',
    label: 'ÖRAG Rechtschutzversicherungs-AG',
  },
  {
    value: '3eb0f220-7322-11ee-90a5-0242ac1e0003',
    label: 'Orion Rechtsschutz-Versicherung AG',
  },
  {
    value: '3ed591ac-7322-11ee-b498-0242ac1e0003',
    label: 'ÖSA Versicherungen',
  },
  {
    value: '3ef9e5b6-7322-11ee-838e-0242ac1e0003',
    label: 'ÖVB Versicherungen',
  },
  {
    value: '3f1e8880-7322-11ee-bc10-0242ac1e0003',
    label: 'PharmAssec GmbH',
  },
  {
    value: '3f4ee8ae-7322-11ee-9141-0242ac1e0003',
    label: 'Prokundo',
  },
  {
    value: '3f7e28d0-7322-11ee-bf7d-0242ac1e0003',
    label: 'Provinzial Rheinland Versicherung AG',
  },
  {
    value: '3fb6cf6e-7322-11ee-9137-0242ac1e0003',
    label: 'R+V Versicherung AG',
  },
  {
    value: '3fe51c20-7322-11ee-83cd-0242ac1e0003',
    label: 'Rechtsschutz Union',
  },
  {
    value: '401499f0-7322-11ee-955a-0242ac1e0003',
    label: 'ROLAND Rechtsschutz-Versicherungs-AG',
  },
  {
    value: '4041d262-7322-11ee-b3c4-0242ac1e0003',
    label: 'Schleswiger Versicherungsservice AG',
  },
  {
    value: '407cd600-7322-11ee-98db-0242ac1e0003',
    label: 'SIGNAL IDUNA Gruppe',
  },
  {
    value: '40aac8a8-7322-11ee-aac8-0242ac1e0003',
    label: 'Sparkassen Versicherung Stuttgart',
  },
  {
    value: '40d2931a-7322-11ee-a23b-0242ac1e0003',
    label: 'Standard Life Assurance Limited',
  },
  {
    value: '40fc353a-7322-11ee-a696-0242ac1e0003',
    label: 'TCS Assista Rechtsschutz AG',
  },
  {
    value: '41273136-7322-11ee-945d-0242ac1e0003',
    label: 'Techno Versicherungsdienst GmbH',
  },
  {
    value: '4150477e-7322-11ee-85cc-0242ac1e0003',
    label: 'Uelzener Versicherung',
  },
  {
    value: '4178b4ac-7322-11ee-a323-0242ac1e0003',
    label: 'Union Versicherungsdienst GmbH',
  },
  {
    value: '41a0a6ec-7322-11ee-8100-0242ac1e0003',
    label: 'Versicherungskammer Bayern',
  },
  {
    value: '41ca47cc-7322-11ee-adb4-0242ac1e0003',
    label: 'Verti Versicherung AG',
  },
  {
    value: '41f1d602-7322-11ee-add6-0242ac1e0003',
    label: 'VGH Rechtsschutzschadensservice GmbH',
  },
  {
    value: '42198b3e-7322-11ee-ad05-0242ac1e0003',
    label: 'VHV Allgemeine Versicherung AG',
  },
  {
    value: '4241894a-7322-11ee-827d-0242ac1e0003',
    label: 'Volksfürsorge',
  },
  {
    value: 'ed94d47d-5540-4c1e-993c-782daf48fe6b',
    label: 'VPV Allgemeine Versicherungs-AG',
  },
  {
    value: '42699746-7322-11ee-8141-0242ac1e0003',
    label: 'Westfälische Provinzial',
  },
  {
    value: '42913a30-7322-11ee-9284-0242ac1e0003',
    label: 'WGV-Versicherung AG',
  },
  {
    value: '42ba758a-7322-11ee-ba07-0242ac1e0003',
    label: 'Württembergische Rechtsschutz Schaden-Service-GmbH',
  },
  {
    value: '42e29df8-7322-11ee-8600-0242ac1e0003',
    label: 'Zurich Rechtsschutz-Schadenservice GmbH',
  },
]

export const COMPANIES = [
  {
    label: 'Keine Auswahl / Unsicher',
    value: '46',
  },
  {
    label: 'Allianz Private Krankenversicherung',
    value: '1',
  },
  {
    label: 'Alte Oldenburger Krankenversicherung',
    value: '2',
  },
  {
    label: 'ARAG',
    value: '3',
  },
  {
    label: 'Augenoptiker Ausgleichskasse',
    value: '4',
  },
  {
    label: 'Axa (Colonia, DBV-Winterthur)',
    value: '5',
  },
  {
    label: 'Barmenia',
    value: '6',
  },
  {
    label: 'Central Krankenversicherung',
    value: '7',
  },
  {
    label: 'Concordia',
    value: '8',
  },
  {
    label: 'Continentale',
    value: '9',
  },
  {
    label: 'Debeka',
    value: '10',
  },
  {
    label: 'Deutscher Ring',
    value: '11',
  },
  {
    label: 'DEVK',
    value: '12',
  },
  {
    label: 'DKV (Victoria)',
    value: '13',
  },
  {
    label: 'Düsseldorfer Versicherung',
    value: '14',
  },
  {
    label: 'Envivas Krankenversicherung',
    value: '15',
  },
  {
    label: 'Ergo Direkt',
    value: '16',
  },
  {
    label: 'Freie Arztkasse',
    value: '17',
  },
  {
    label: 'Gothaer',
    value: '18',
  },
  {
    label: 'Hallesche',
    value: '19',
  },
  {
    label: 'HanseMerkur',
    value: '20',
  },
  {
    label: 'HUK-Coburg',
    value: '21',
  },
  {
    label: 'Inter',
    value: '22',
  },
  {
    label: 'Krankenunterstützungskasse der Berufsfeuerwehr Hannover',
    value: '23',
  },
  {
    label: 'Landeskrankenhilfe LKH',
    value: '24',
  },
  {
    label: 'LIGA Krankenversicherung katholischer Priester',
    value: '25',
  },
  {
    label: 'LVM',
    value: '26',
  },
  {
    label: 'Mannheimer Versicherungen',
    value: '27',
  },
  {
    label: 'Mecklenburgische',
    value: '28',
  },
  {
    label: 'Münchener Verein',
    value: '29',
  },
  {
    label: 'Nürnberger',
    value: '30',
  },
  {
    label: 'PAX-Familienfürsorge Krankenversicherung',
    value: '31',
  },
  {
    label: 'Praenatura',
    value: '32',
  },
  {
    label: 'Provinzial',
    value: '33',
  },
  {
    label: 'R+V',
    value: '34',
  },
  {
    label: 'Signal Iduna',
    value: '35',
  },
  {
    label: 'St. Martinus Kranken- und Sterbekasse',
    value: '36',
  },
  {
    label: 'Süddeutsche Krankenversicherung',
    value: '37',
  },
  {
    label: 'Union Krankenversicherung',
    value: '38',
  },
  {
    label: 'Universa',
    value: '39',
  },
  {
    label: 'Versicherungskammer Bayern',
    value: '40',
  },
  {
    label: 'Vigo Krankenversicherung',
    value: '41',
  },
  {
    label: 'Württembergische Krankenversicherung',
    value: '42',
  },
]

export const getInitialFileUploadObject = (index, success = false) => {
  return {
    index,
    acceptedFiles: [],
    rejectedFiles: [],
    success,
  }
}
