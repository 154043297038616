import styled, { css } from 'styled-components'
import { PriceLine, RegularText } from '../../styles/common.styles'

const RemunerationRow = css`
  margin: 20px 0;
  padding: 0;
`

export const RemunerationPrice = styled(PriceLine)`
  ${RemunerationRow}
`

export const RemunerationDescription = styled(RegularText)`
  ${RemunerationRow}
`

export const RemunerationCheckbox = styled.div`
  ${RemunerationRow}
`
